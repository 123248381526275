
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BackEndUrl from "../api";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "../images/syc-logo.jpg"


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <a
        style={{ color: "inherit" }}
        target="_blank"
        rel="noreferrer"
        href="https://shiftyourcargo.com/"
      >
        ShiftYourCargo
      </a>{" "}
      {""}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const logo1 = (
  <img src={logo} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover'}} />
);

function SignIn() {
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState({
    email: false,
    password: false,
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const data = new FormData(event.currentTarget);

    if (!data.get("email") || !data.get("password")) {
      setFormErrors({
        email: !data.get("email"),
        password: !data.get("password"),
      });
      setLoading(false);
      return;
    }

    const response = await fetch(`${BackEndUrl}/auth/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.get("email"),
        password: data.get("password"),
      }),
    });

    if (response.ok) {
      const result = await response.json();
     // console.log("Response from server:", result); 
      localStorage.setItem("token", result.token);
      localStorage.setItem("email", data.get("email"));
      localStorage.setItem("role", result.role);

        // Log the user role to the console for confirmation
     // console.log("User role:", result.role);

      // Trigger storage event to update Appbar
      window.dispatchEvent(new Event('storage'));

      toast.success("Login successful", {
        onClose: () => {
          navigate("/");
        },
      });
    } else {
      toast.error("Invalid email or password");
      setLoading(false)
      
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, border:"1px solid black"  }}>
            {logo1}
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={formErrors.email}
              helperText={formErrors.email ? "Email is required" : ""}
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              error={formErrors.password}
              helperText={formErrors.password ? "Password is required" : ""}
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Box sx={{position:"relative"}} >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: "#000",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#333",
                },
              }}
            >
              {loading ? "Signing in..." : "Sign In"}
            </Button>
            {loading && (
              <CircularProgress
               size={24}
               sx={{
                // animation:`${changeColor} s infinite`,
                color:"white",
                position:"absolute",
                top:"50%",
                left:"50%",
                marginTop:"-8px",
                marginLeft:"-70px"
               }}
               />
            )}
             </Box>
            <Grid container>
              <Grid item>
              <Button
                  component={Link}
                  to="/SignUp"
                  variant="body2"
                  fullWidth
                  sx={{
                    mt: 1,
                    mb: 2,
                    textTransform: "none",
                    "&:hover": {
                      textDecoration: "underline",
                      bgcolor: "#000",
                      color: "#fff",
                    },
                  }}
                >
                  {"Don't have an account? Sign Up"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

export default SignIn;

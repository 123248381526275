// import React, { useEffect, useState } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
//   TextField,
//   Paper,
// } from "@mui/material";
// import axios from "axios";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import "./track.css";
// import { toast } from "react-toastify";
// import BackEndUrl from "../../api.js";

// const TrackPage = () => {
//   const { trackingNumber: trackingNumberFromParams } = useParams();
//   const navigate = useNavigate();
//   const [trackingNumber, setTrackingNumber] = useState(
//     trackingNumberFromParams || ""
//   );
//   const [orderDetails, setOrderDetails] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     Aos.init({ duration: 1000 });

//     if (trackingNumberFromParams) {
//       fetchOrderDetails(trackingNumberFromParams).then((success) => {
//         if (success) {
//           toast.success("Order details fetched successfully.");
//         } else {
//           toast.error("Order details not found.");
//         }
//       });
//     }
//   }, [trackingNumberFromParams]);

//   const fetchOrderDetails = async (trackingNumber) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.get(
//         `${BackEndUrl}/api/order/${trackingNumber}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         }
//       );
//       setOrderDetails(response.data);
//       setError(null);
//       return true;
//     } catch (error) {
//       setOrderDetails(null);
//       setError("");
//       return false;
//     }
//   };

//   const handleInputChange = (e) => {
//     setTrackingNumber(e.target.value);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (trackingNumber) {
//       navigate(`/Track/${trackingNumber}`);
//     }
//   };

//   return (
//     <div style={{ height: "1400px" }}>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//         }}
//       >
//         <form
//           onSubmit={handleSubmit}
//           data-aos="fade-up"
//           style={{ marginBottom: "20px" }}
//         >
//           <TextField
//             size="small"
//             variant="outlined"
//            placeholder="Enter Your Tracking ID"
//             name="trackingNumber"
//             value={trackingNumber}
//             onChange={handleInputChange}
//             sx={{
//               width:"205px"
//             }}
//           />
//           <Button
//             type="submit"
//             variant="contained"
//             sx={{
//               p: "7px 12px",
//               marginLeft: "2px",
//               bgcolor: "#6A43C7",
//               "&:hover": { bgcolor: "#4818bf" },
//             }}
//           >
//             Track
//           </Button>
//         </form>

//         {error && <p style={{ color: "red" }}>{error}</p>}
//         {orderDetails && (
//           <Box className="track-order-details" margin="auto" textAlign="center">
//             <h1 style={{ color: "black", marginTop: "20px" }}>
//               Your Order Details
//             </h1>

//             <TableContainer component={Paper}>
//               <Table>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell>
//                       <b>Tracking ID</b>
//                     </TableCell>
//                     <TableCell>{orderDetails.trackingNumber}</TableCell>
//                   </TableRow>
//                 </TableBody>
//               </Table>
//             </TableContainer>

//             <TableContainer component={Paper} style={{ marginTop: 20 }}>
//               <Table>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell>
//                       <b>Tracking Summary</b>
//                     </TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell>
//                       <b>From</b>
//                     </TableCell>
//                     <TableCell></TableCell>
//                     <TableCell>
//                       <b>To</b>
//                     </TableCell>
//                     <TableCell></TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell><b>Company Name:</b></TableCell>
//                     <TableCell>{orderDetails.origin}</TableCell>
//                     <TableCell><b>Company Name:</b></TableCell>
//                     <TableCell>{orderDetails.destination}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell><b>Address:</b></TableCell>
//                     <TableCell>{orderDetails.consignorAddress}</TableCell>
//                     <TableCell><b>Address:</b></TableCell>
//                     <TableCell>{orderDetails.consigneeAddress}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell>
//                       <b>No of Items</b>
//                     </TableCell>
//                     <TableCell>{orderDetails.totalQuantity}</TableCell>
//                     <TableCell>
//                       <b>Charged Weight</b>
//                     </TableCell>
//                     <TableCell>{orderDetails.totalWeight}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell>
//                       <b>Pickup Date</b>
//                     </TableCell>
//                     <TableCell>
//                       {orderDetails.trackingDetails[0].dateTime}
//                     </TableCell>
//                     <TableCell>
//                       <b>Payment Status</b>
//                     </TableCell>
//                     <TableCell>{orderDetails.paymentMethod}</TableCell>
//                   </TableRow>
//                 </TableBody>
//               </Table>
//             </TableContainer>

//             <TableContainer component={Paper} style={{ marginTop: 20 }}>
//               <Table>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell>
//                       <b>Service Type</b>
//                     </TableCell>
//                     <TableCell>{orderDetails.shippingMethod}</TableCell>
//                   </TableRow>
//                 </TableBody>
//               </Table>
//             </TableContainer>

//             <TableContainer component={Paper} style={{ marginTop: 20 }}>
//               <Table>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell>
//                       <b>MOde Of Payment</b>
//                     </TableCell>
//                     <TableCell>{orderDetails.modeOfPayment}</TableCell>
//                   </TableRow>
//                 </TableBody>
//               </Table>
//             </TableContainer>

//             <TableContainer component={Paper} style={{ marginTop: 20 }}>
//               <Table>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell>
//                       <b>Tracking Details</b>
//                     </TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell>
//                       <b>Status</b>
//                     </TableCell>
//                     <TableCell>
//                       <b>Time</b>
//                     </TableCell>
//                     <TableCell>
//                       <b>Remarks</b>
//                     </TableCell>
//                   </TableRow>
//                   {orderDetails.trackingDetails.map((detail, index) => (
//                     <TableRow key={index}>
//                       <TableCell>{detail.status}</TableCell>
//                       <TableCell>{detail.dateTime}</TableCell>
//                       <TableCell>{detail.remarks}</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Box>
//         )}
//       </div>
//     </div>
//   );
// };

// export default TrackPage;


import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";
import "./track.css";
import { toast } from "react-toastify";
import BackEndUrl from "../../api.js";

const TrackPage = () => {
  const { trackingNumber: trackingNumberFromParams } = useParams();
  const navigate = useNavigate();
  const [trackingNumber, setTrackingNumber] = useState(
    trackingNumberFromParams || ""
  );
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    Aos.init({ duration: 1000 });

    if (trackingNumberFromParams) {
      fetchOrderDetails(trackingNumberFromParams).then((success) => {
        if (success) {
          toast.success("Order details fetched successfully.");
        } else {
          toast.error("Order details not found.");
        }
      });
    }
  }, [trackingNumberFromParams]);

  const fetchOrderDetails = async (trackingNumber) => {
    try {
      const token = localStorage.getItem("token");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await axios.get(
        `${BackEndUrl}/api/order/${trackingNumber}`,
        { headers }
      );
      setOrderDetails(response.data);
      setError(null);
      return true;
    } catch (error) {
      setOrderDetails(null);
      setError("");
      return false;
    }
  };

  const handleInputChange = (e) => {
    setTrackingNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (trackingNumber) {
      navigate(`/Track/${trackingNumber}`);
    }
  };

  return (
    <div style={{ height: "1400px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <form
          onSubmit={handleSubmit}
          data-aos="fade-up"
          style={{ marginBottom: "20px" }}
        >
          <TextField
            size="small"
            variant="outlined"
            placeholder="Enter Your Tracking ID"
            name="trackingNumber"
            value={trackingNumber}
            onChange={handleInputChange}
            sx={{
              width:"205px"
            }}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              p: "7px 12px",
              marginLeft: "2px",
              bgcolor: "#6A43C7",
              "&:hover": { bgcolor: "#4818bf" },
            }}
          >
            Track
          </Button>
        </form>

        {error && <p style={{ color: "red" }}>{error}</p>}
        {orderDetails && (
          <Box className="track-order-details" margin="auto" textAlign="center">
            <Typography variant="h5" sx={{fontWeight:"bold"}}>
              Your Order Details
            </Typography>

            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Tracking ID</b>
                    </TableCell>
                    <TableCell>{orderDetails.trackingNumber}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Tracking Summary</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>From</b>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <b>To</b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Company Name:</b></TableCell>
                    <TableCell>{orderDetails.origin}</TableCell>
                    <TableCell><b>Company Name:</b></TableCell>
                    <TableCell>{orderDetails.destination}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Address:</b></TableCell>
                    <TableCell>{orderDetails.consignorAddress}</TableCell>
                    <TableCell><b>Address:</b></TableCell>
                    <TableCell>{orderDetails.consigneeAddress}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>No of Items</b>
                    </TableCell>
                    <TableCell>{orderDetails.totalQuantity}</TableCell>
                    <TableCell>
                      <b>Charged Weight</b>
                    </TableCell>
                    <TableCell>{orderDetails.totalWeight}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Pickup Date</b>
                    </TableCell>
                    <TableCell>
                      {orderDetails.trackingDetails[0].dateTime}
                    </TableCell>
                    <TableCell>
                      <b>Payment Status</b>
                    </TableCell>
                    <TableCell>{orderDetails.paymentMethod}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Service Type</b>
                    </TableCell>
                    <TableCell>{orderDetails.shippingMethod}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Mode Of Payment</b>
                    </TableCell>
                    <TableCell>{orderDetails.modeOfPayment}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Tracking Details</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Status</b>
                    </TableCell>
                    <TableCell>
                      <b>Time</b>
                    </TableCell>
                    <TableCell>
                      <b>Remarks</b>
                    </TableCell>
                  </TableRow>
                  {orderDetails.trackingDetails.map((detail, index) => (
                    <TableRow key={index}>
                      <TableCell>{detail.status}</TableCell>
                      <TableCell>{detail.dateTime}</TableCell>
                      <TableCell>{detail.remarks}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </div>
    </div>
  );
};

export default TrackPage;




const Expenses = () => {
  return (
    <div style={{ textAlign: "center", marginLeft:"180px" }}>
      <h3 style={{ color: "skyblue" }}>Hey Welcome to Expenses Page!!!</h3>

      <br />
      <h4 style={{ color: "red" }}>still working on this page</h4>
    </div>
  );
};

export default Expenses;

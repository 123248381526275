import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='container' style={{ fontSize: "10px" }}>
      <h1 style={{ fontSize: "20px", marginBottom: "20px" }}>Privacy Policy</h1>
      <h4 style={{ fontSize: "15px", marginBottom: "20px" }}>Effective Date: 23rd August 2024</h4>
      <p style={{ marginBottom: "10px" }}>
        Tech Avign Pvt Ltd ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines the types of information we collect from our users ("you," "your") and how we use, share, and protect that information.
      </p>

      <h5 style={{ fontSize: "15px", marginTop: "15px" }}>1. Information We Collect</h5>
      <p style={{ marginBottom: "10px" }}>
        We collect various types of information to provide and improve our services to you:
        <ul style={{ marginLeft: "20px" }}>
          <li>Personal Information: This may include your name, email address, phone number, and billing information when you register or purchase services.</li>
          <li>Transportation Data: Information related to your cargo, including pickup and delivery addresses, cargo details, and any special instructions.</li>
          <li>Usage Information: Details about your interactions with our website or app, such as IP addresses, browser types, and pages visited.</li>
          <li>Cookies: We use cookies and similar tracking technologies to enhance your user experience and analyse site traffic.</li>
        </ul>
      </p>

      <h5 style={{ fontSize: "15px", marginTop: "15px" }}>2. How We Use Your Information</h5>
      <p style={{ marginBottom: "10px" }}>
        We use the collected information for various purposes, including:
        <ul style={{ marginLeft: "20px" }}>
          <li>To provide and maintain our services.</li>
          <li>To process transactions and send you related information, including purchase confirmations and invoices.</li>
          <li>To communicate with you about your account, cargo, and other services.</li>
          <li>To improve our website, services, and user experience.</li>
          <li>To monitor and analyse trends, usage, and activities in connection with our services.</li>
        </ul>
      </p>

      <h5 style={{ fontSize: "15px", marginTop: "15px" }}>3. Sharing Your Information</h5>
      <p style={{ marginBottom: "10px" }}>
        We may share your information with third parties under the following circumstances:
        <ul style={{ marginLeft: "20px" }}>
          <li>Service Providers: We may share your information with third-party vendors, consultants, and other service providers who perform services on our behalf.</li>
          <li>Legal Requirements: We may disclose your information if required to do so by law or in response to a legal request.</li>
          <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
        </ul>
      </p>

      <h5 style={{ fontSize: "15px", marginTop: "15px" }}>4. Data Security</h5>
      <p style={{ marginBottom: "10px" }}>
        We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no data transmission over the Internet or method of electronic storage is 100% secure.
      </p>

      <h5 style={{ fontSize: "15px", marginTop: "15px" }}>5. Your Rights</h5>
      <p style={{ marginBottom: "10px" }}>
        Depending on your location, you may have the following rights regarding your personal information:
        <ul style={{ marginLeft: "20px" }}>
          <li>The right to access, update, or delete your personal information.</li>
          <li>The right to object to or restrict the processing of your personal information.</li>
          <li>The right to data portability.</li>
        </ul>
        To exercise these rights, please contact us at [Insert Contact Information].
      </p>

      <h5 style={{ fontSize: "15px", marginTop: "15px" }}>6. Children's Privacy</h5>
      <p style={{ marginBottom: "10px" }}>
        Our services are not directed to individuals under 13 years of age. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us, and we will take steps to delete such information.
      </p>

      <h5 style={{ fontSize: "15px", marginTop: "15px" }}>7. Changes to This Privacy Policy</h5>
      <p style={{ marginBottom: "10px" }}>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" above.
      </p>

      <h5 style={{ fontSize: "15px", marginTop: "15px" }}>8. Contact Us</h5>
      <p style={{ marginBottom: "10px" }}>
        If you have any questions about this Privacy Policy, please contact us at:
        <ul style={{ marginLeft: "20px" }}>
          <li>Email: request@shiftyourcargo.com</li>
          <li>Phone: +91 9900 2525 85</li>
          <li>Address: Shift Your Cargo - No 59, 2nd Main, Rukmini Nagar, Nagasandra post, Bangalore - 560073</li>
        </ul>
      </p>
    </div>
  );
}

export default PrivacyPolicy;

import React from 'react'

const User = () => {
  return (
    <div>
       Hey welcome to User page Still working on this page!!!
        
        </div>
  )
}

export default User;
// import { Box, TextField, Typography, Button} from "@mui/material";
// import { useNavigate, Link, useParams } from "react-router-dom";
// import { useState, useEffect } from "react";
// import axios from "axios";
// import { toast } from 'react-toastify';
// import BackEndUrl from "../../../../api.js";


// const EditDetails = () => {

//     const navigate = useNavigate();

//   const { trackingNumber } = useParams();
//   const [orderDetails, setOrderDetails] = useState({});
//   const [editedDetails, setEditedDetails] = useState({});
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchOrderDetails = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         const response = await axios.get(`${BackEndUrl}/api/order/${trackingNumber}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setOrderDetails(response.data);
//         setEditedDetails(response.data); 
//         setError(null);
//       } catch (error) {
//         console.error("Error fetching order details:", error);
//         setError("Error fetching order details. Please try again.");
//       }
//     };

//     fetchOrderDetails();
//   }, [trackingNumber]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setEditedDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleUpdate = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       await axios.put(`${BackEndUrl}/api/order/${trackingNumber}`, editedDetails,

//         {
//           headers : {
//             Authorization : `Bearer ${token}`,
//           },
//         },
//       );
//       toast.success("Order Updated Successfully.")
//     } catch (error) {
//       console.error("Error updating order details:", error);
//       setError("Error updating order details. Please try again.");
//     };
//     navigate('/Admin')
//   };

//   const handleDelete = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       await axios.delete(`${BackEndUrl}/api/order/${trackingNumber}`,
//         {
//           headers:{
//             Authorization:`Bearer ${token}`,
//           },
//         }
//       );
//       toast.info("Order deleted successfully.")
//     } catch (error) {
//       console.error("Error deleting order details:", error);
//       setError("Error deleting order details. Please try again.");
//     }
//     navigate("/Admin")
//   };




//     return (
//         <Box  sx={{mb:"50px", ml:"180px"}}>
//       <h1 style={{textAlign:'center', color:"black"}}>
//         Edit Order Details
//       </h1>
//       {error && <Typography color="error">{error}</Typography>}
//        <Box width={400} margin="0 auto">
//         <TextField
//           name="trackingNumber"
//           label="Tracking Number"
//           value={editedDetails.trackingNumber || ""}
//           onChange={handleInputChange}
//           fullWidth
//           disabled
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="mode"
//           label="Mode"
//           value={editedDetails.mode || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="freightMode"
//           label="FreightMode"
//           value={editedDetails.freightMode || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="pickupType"
//           label="PickupType"
//           value={editedDetails.pickupType || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="pickupLocation"
//           label="Pickup Location"
//           value={editedDetails.pickupLocation || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="productDescription"
//           label="Product Description"
//           value={editedDetails.productDescription || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="totalWeight"
//           label="Total Weight"
//           value={editedDetails.totalWeight || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="clientReferenceID"
//           label="Client Reference ID"
//           value={editedDetails.clientReferenceID || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="quantity1"
//           label="Qty"
//           value={editedDetails.quantity1 || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="dimensionsUnit"
//           label="Dimensions Unit"
//           value={editedDetails.dimensionsUnit || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="quantity"
//           label="qty"
//           value={editedDetails.quantity || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="length"
//           label="length"
//           value={editedDetails.length || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />

//         <TextField
//           name="height"
//           label="Height"
//           value={editedDetails.height || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="width"
//           label="Width"
//           value={editedDetails.width || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="freightMode"
//           label="FreightMode"
//           value={editedDetails.freightMode || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="amountCollection"
//           label="Amount Collection"
//           value={editedDetails.amountCollection || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="amountToCollect"
//           label="Amount To Collect"
//           value={editedDetails.amountToCollect || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="eWaybill"
//           label="eWaybill"
//           value={editedDetails.eWaybill || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="invoiceAmount"
//           label="Invoice Amount"
//           value={editedDetails.invoiceAmount || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="invoiceNumber"
//           label="Invoice Number"
//           value={editedDetails.invoiceNumber || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="shipperGSTIN"
//           label="shipper GSTIN"
//           value={editedDetails.shipperGSTIN || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="consigneeGSTIN"
//           label="consignee GSTIN"
//           value={editedDetails.consigneeGSTIN || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="sendToPinCode"
//           label="Send To PinCode"
//           value={editedDetails.sendToPinCode || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//         <TextField
//           name="toCity"
//           label="To City"
//           value={editedDetails.toCity || ""}
//           onChange={handleInputChange}
//           fullWidth
//           style={{ marginBottom: 10 }}
//         />
//       </Box> 

//       <Box sx={{display:"flex", justifyContent:"center"}}>
   
//     <Link to='/Admin'>
//     <Button variant="contained" color="error" size="small" style={{marginRight:"10px"}}> 
//         cancel
//       </Button>
//     </Link>
     

//       <Button variant="contained" color="primary" size="small" onClick={handleUpdate} style={{marginRight:"15px"}}>
//         Update
//       </Button>
   
//       <Button variant="contained" color="error" size="small" onClick={handleDelete}>
//         Delete
//       </Button>
//       </Box>


//     </Box>
//     )
// }

// export default EditDetails;




import {
  Box,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import BackEndUrl from "../../../../api.js";

const EditDetails = () => {
  const navigate = useNavigate();
  const { trackingNumber } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const [editedDetails, setEditedDetails] = useState({});
  const [error, setError] = useState(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${BackEndUrl}/api/order/${trackingNumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrderDetails(response.data);
        setEditedDetails(response.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching order details:", error);
        setError("Error fetching order details. Please try again.");
      }
    };

    fetchOrderDetails();
  }, [trackingNumber]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    setOpenUpdateDialog(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(`${BackEndUrl}/api/order/${trackingNumber}`, editedDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Order Updated Successfully.");
      navigate('/Admin');
    } catch (error) {
      console.error("Error updating order details:", error);
      setError("Error updating order details. Please try again.");
    }
    setOpenUpdateDialog(false);
  };

  const handleDelete = async () => {
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${BackEndUrl}/api/order/${trackingNumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.info("Order deleted successfully.");
      navigate("/Admin");
    } catch (error) {
      console.error("Error deleting order details:", error);
      setError("Error deleting order details. Please try again.");
    }
    setOpenDeleteDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenUpdateDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
    <Box sx={{ mb: "50px", ml: "180px" }}>
      <h1 style={{ textAlign: 'center', color: "black" }}>Edit Order Details</h1>
      {error && <Typography color="error">{error}</Typography>}

      <Box component="form" sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {/* Form Fields */}
        <TextField
          label="Tracking Number"
          name="trackingNumber"
          value={editedDetails.trackingNumber || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Booking Date"
          name="bookingDate"
          value={editedDetails.bookingDate || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Origin"
          name="origin"
          value={editedDetails.origin || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Destination"
          name="destination"
          value={editedDetails.destination || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Reference"
          name="reference"
          value={editedDetails.reference || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Shipping Method"
          name="shippingMethod"
          value={editedDetails.shippingMethod || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Sender Code"
          name="senderCode"
          value={editedDetails.senderCode || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Booking Branch"
          name="bookingBranch"
          value={editedDetails.bookingBranch || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Consignor Name"
          name="consignorName"
          value={editedDetails.consignorName || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Consignor Phone"
          name="consignorPhone"
          value={editedDetails.consignorPhone || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Consignor Address"
          name="consignorAddress"
          value={editedDetails.consignorAddress || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Consignee Name"
          name="consigneeName"
          value={editedDetails.consigneeName || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Consignee Phone"
          name="consigneePhone"
          value={editedDetails.consigneePhone || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Consignee Address"
          name="consigneeAddress"
          value={editedDetails.consigneeAddress || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="GST No"
          name="gstNo"
          value={editedDetails.gstNo || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Invoice No"
          name="invoiceNo"
          value={editedDetails.invoiceNo || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Invoice Value"
          name="invoiceValue"
          value={editedDetails.invoiceValue || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Invoice Date"
          name="invoiceDate"
          value={editedDetails.invoiceDate || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="eWay Bill No"
          name="eWayBillNo"
          value={editedDetails.eWayBillNo || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="eWay Bill Validity"
          name="eWayBillValidity"
          value={editedDetails.eWayBillValidity || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Product Type"
          name="productType"
          value={editedDetails.productType || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Payment Method"
          name="paymentMethod"
          value={editedDetails.paymentMethod || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Risk Coverage"
          name="riskCoverage"
          value={editedDetails.riskCoverage || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Policy No"
          name="policyNo"
          value={editedDetails.policyNo || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Validity"
          name="validity"
          value={editedDetails.validity || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Mode of Payment"
          name="modeOfPayment"
          value={editedDetails.modeOfPayment || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Cheque/DD"
          name="chequeDD"
          value={editedDetails.chequeDD || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Bank"
          name="bank"
          value={editedDetails.bankName || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Quantity"
          name="quantity"
          value={editedDetails.quantity || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <TextField
          label="Weight"
          name="weight"
          value={editedDetails.weight || ''}
          onChange={handleInputChange}
          sx={{ mb: 2, width: "50%" }}
        />

        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Link to='/Admin'>
            <Button variant="contained" color="error" size="small" style={{ marginRight: "10px" }}>
              Cancel
            </Button>
          </Link>

          <Button variant="contained" color="primary" size="small" onClick={handleUpdate} style={{ marginRight: "15px" }}>
            Update
          </Button>

          <Button variant="contained" color="error" size="small" onClick={handleDelete}>
            Delete
          </Button>
        </Box>
      </Box>

      {/* Update Confirmation Dialog */}
      <Dialog open={openUpdateDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to update the order details?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} size="small" color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpdate} size="small" variant="contained" color="success">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} size="small" color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} size="small" color="error" sx={{"&:hover":{bgcolor:"red", color:"white"}}}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default EditDetails;


 
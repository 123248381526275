


import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
  IconButton,
} from "@mui/material";
import BasicDetails from "./BasicDetails";
import ShippingMethod from "./ShippingMethod";
import SenderConsignmentDetails from "./SenderConsignmentDetails";
import PackageInfoGstProductType from "./PackageInfoGstProductType";
import Dimensions from "./Dimensions";
import PaymentC from "./PaymentC";
import Documents from "./Documents";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import BackEndUrl from "../../../../api";

const CreateNewOrder = () => {
  const navigate = useNavigate();

  const [basicDetails, setBasicDetails] = useState({
    trackingNumber: "",
    bookingDate: "",
    origin: "",
    time: "",
    destination: "",
    reference: "",
  });

  const [shippingMethod, setShippingMethod] = useState("");
  const [surfaceOption, setSurfaceOption] = useState("");
  const [airOption, setAirOption] = useState("");

  const [senderConsignmentDetails, setSenderConsignmentDetails] = useState({
    senderCode: "",
    bookingBranch: "",
    consignorName: "",
    consignorPhone: "",
    consignorAddress: "",
    consigneeName: "",
    consigneePhone: "",
    consigneeAddress: "",
  });

  const [packageInfo, setPackageInfo] = useState({
    gstNo1: "",
    gstNo: "",
    invoiceNo: "",
    invoiceValue: "",
    invoiceDate: "",
    eWayBillNo: "",
    eWayBillValidity: "",
    productType: "",
  });


  const [paymentCData, setPaymentCData] = useState({
    paymentMethod: "",
    riskCoverage: "",
    policyNo: "",
    validity: "",
    modeOfPayment: "",
    chequeDD: "",
    bankName: "",
    amount: "",
  });

  // State for storing uploaded documents
  const [invoiceDocument, setInvoiceDocument] = useState(null);
  const [supportDocument, setSupportDocument] = useState(null);

  // Handle file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (event.target.name === "invoiceDocument") {
      setInvoiceDocument(file);
    } else if (event.target.name === "supportDocument") {
      setSupportDocument(file);
    }
  };

  const intialTrackingDetail = {
    status: "pickup",
    dateTime: moment().tz("Asia/Kolkata").format("YYYY-MM-DDTHH:mm"),
    remarks: "Consignment is picked Up.",
  };

  const [trackingDetails, setTrackingDetails] = useState([
    intialTrackingDetail,
  ]);

  const addTrackingDetail = () => {
    const istDateTime = moment().tz("Asia/Kolkata").format("YYYY-MM-DDTHH:mm");
    const newTrackingDetail = {
      ...intialTrackingDetail,
      dateTime: istDateTime,
    };
    setTrackingDetails([...trackingDetails, newTrackingDetail]);
  };

  const removeTrackingDetail = (index) => {
    const updateTrackingDetails = trackingDetails.filter((_, i) => i !== index);
    setTrackingDetails(updateTrackingDetails);
  };

  const handleTrackingChange = (index, field, value) => {
    const updatedTrackingDetails = [...trackingDetails];
    updatedTrackingDetails[index][field] = value;
    setTrackingDetails(updatedTrackingDetails);
  };

  const [dimensionsData, setDimensionsData] = useState({
    totalWeight: "",
    dimensionsUnit: "Centimeter",
    quantityDetails: [{ quantity: "", length: "", height: "", width: "" }],
    totalQuantity: "",
  });

  // Handler for Dimensions
  const handleDimensionsChange = (event) => {
    const { name, value } = event.target;
    setDimensionsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleQuantityDetailsChange = (index, field, value) => {
    const updatedQuantityDetails = [...dimensionsData.quantityDetails];
    updatedQuantityDetails[index][field] = value;
    setDimensionsData({
      ...dimensionsData,
      quantityDetails: updatedQuantityDetails,
    });
  };

  const addQuantityDetail = () => {
    setDimensionsData({
      ...dimensionsData,
      quantityDetails: [
        ...dimensionsData.quantityDetails,
        { quantity: "", length: "", height: "", width: "" },
      ],
    });
  };

  const removeQuantityDetail = (index) => {
    const updatedQuantityDetails = dimensionsData.quantityDetails.filter(
      (_, i) => i !== index
    );
    setDimensionsData({
      ...dimensionsData,
      quantityDetails: updatedQuantityDetails,
    });
  };

  const handleCreateOrder = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append(
        "order",
        JSON.stringify({
          ...basicDetails,
          shippingMethod,
          surfaceOption,
          airOption,
          ...senderConsignmentDetails,
          ...packageInfo,
          ...paymentCData,
          // dimensionsData: {
          //   ...dimensionsData,
          //   totalWeight: dimensionsData.totalWeight,
          //   dimensionsUnit: dimensionsData.dimensionsUnit,
          //   totalQuantity: dimensionsData.totalQuantity
          // }
        })
      );
  
      // Append trackingDetails as JSON string
      const currentTrackingDetails = trackingDetails.map((detail) => ({
        ...detail,
        dateTime: moment(detail.dateTime)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DDTHH:mm"),
      }));

      formDataToSend.append("totalWeight", dimensionsData.totalWeight);
      formDataToSend.append("dimensionsUnit", dimensionsData.dimensionsUnit);
      formDataToSend.append("totalQuantity", dimensionsData.totalQuantity);

      formDataToSend.append(
        "trackingDetails",
        JSON.stringify(currentTrackingDetails)
      );
  
      // Append quantityDetails as JSON string
      formDataToSend.append(
        "quantityDetails",
        JSON.stringify(dimensionsData.quantityDetails)
      );
  
      formDataToSend.append("invoiceDocument", invoiceDocument);
      if (supportDocument) {
        formDataToSend.append("supportDocument", supportDocument);
      }
  
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BackEndUrl}/api/order`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
    //  console.log("Response:", response.data);
  
      if (response.status === 200 || response.status === 201) {
      //  console.log("Order created successfully");
  
        // Reset all states to initial values
        setBasicDetails({
          trackingNumber: "",
          bookingDate: "",
          origin: "",
          time: "",
          destination: "",
          reference: "",
        });
        setShippingMethod("");
        setSurfaceOption("");
        setAirOption("");
  
        setSenderConsignmentDetails({
          senderCode: "",
          bookingBranch: "",
          consignorName: "",
          consignorPhone: "",
          consignorAddress: "",
          consigneeName: "",
          consigneePhone: "",
          consigneeAddress: "",
        });
  
        setPackageInfo({
          gstNo1: "",
          gstNo: "",
          invoiceNo: "",
          invoiceValue: "",
          invoiceDate: "",
          eWayBillNo: "",
          eWayBillValidity: "",
          productType: "",
        });
  
        setPaymentCData({
          paymentMethod: "",
          riskCoverage: "",
          policyNo: "",
          validity: "",
          modeOfPayment: "",
          chequeDD: "",
          bankName: "",
          amount: "",
        });
  
        setInvoiceDocument(null);
        setSupportDocument(null);
        setTrackingDetails([intialTrackingDetail]);
  
        toast.success("Order created successfully");
  
        navigate("/Admin");
      } else {
        console.error("Failed to create order");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Order is not created");
    }
  };
  
  

  const isTrackingDetailFilled = (trackingDetail) => {
    return (
      trackingDetail.status !== "" &&
      trackingDetail.dateTime !== "" &&
      trackingDetail.remarks !== ""
    );
  };

  return (
    <Container>
      <Box sx={{ marginLeft: 6, marginTop: 2 }}>
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          sx={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Create New Order
        </Typography>
        <BasicDetails
          basicDetails={basicDetails}
          setBasicDetails={setBasicDetails}
        />
        <ShippingMethod
          shippingMethod={shippingMethod}
          setShippingMethod={setShippingMethod}
          setSurfaceOption={setSurfaceOption}
          setAirOption={setAirOption}
        />
        <SenderConsignmentDetails
          senderConsignmentDetails={senderConsignmentDetails}
          setSenderConsignmentDetails={setSenderConsignmentDetails}
        />
        <PackageInfoGstProductType
          packageInfo={packageInfo}
          setPackageInfo={setPackageInfo}
        />
        
        <Dimensions
        dimensionsData={dimensionsData}
        handleDimensionsChange={handleDimensionsChange}
        handleQuantityDetailsChange={handleQuantityDetailsChange}
        addQuantityDetail={addQuantityDetail}
        removeQuantityDetail={removeQuantityDetail}
      />

        <PaymentC setPaymentCData={setPaymentCData} />
        <Documents
          handleFileChange={handleFileChange}
          invoiceDocument={invoiceDocument}
          supportDocument={supportDocument}
        />

        <Box
          sx={{
            display: "none",
          }}
        >
          {trackingDetails.map((trackingDetail, index) => (
            <Box key={index}>
              <TextField
                select
                value={trackingDetail.status}
                onChange={(e) =>
                  handleTrackingChange(index, "status", e.target.value)
                }
              >
                <MenuItem value="pickup">Pick Up</MenuItem>
                <MenuItem value="intransit">In Transit</MenuItem>
                <MenuItem value="delivered">Delivered</MenuItem>
              </TextField>
              <TextField
                type="datetime-local"
                value={trackingDetail.dateTime}
                onChange={(e) =>
                  handleTrackingChange(index, "dateTime", e.target.value)
                }
              />
              <TextField
                value={trackingDetail.remarks}
                onChange={(e) =>
                  handleTrackingChange(index, "remarks", e.target.value)
                }
              />
              {isTrackingDetailFilled(trackingDetail) && (
                <IconButton onClick={() => removeTrackingDetail(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
          <Button
            onClick={addTrackingDetail}
            disabled={
              !isTrackingDetailFilled(
                trackingDetails[trackingDetails.length - 1]
              )
            }
          >
            Add More
          </Button>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mt: "50px" }}>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: "black",
              color: "white",
              "&:hover": { bgcolor: "#292423" },
            }}
            onClick={handleCreateOrder}
          >
            Create Order
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateNewOrder;




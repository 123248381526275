import { Box, Grid, TextField, Typography } from '@mui/material';
import React from 'react';

const SenderConsignmentDetails = ({ senderConsignmentDetails, setSenderConsignmentDetails }) => {
  const handleChange = (e) => {
    setSenderConsignmentDetails({
      ...senderConsignmentDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            Sender Code
          </Typography>
          <TextField
            fullWidth
            name="senderCode"
            value={senderConsignmentDetails.senderCode}
            onChange={handleChange}
            id="senderCode"
            variant="outlined"
            size="small"
            sx={{ width: "70%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            Booking Branch
          </Typography>
          <TextField
            fullWidth
            name="bookingBranch"
            value={senderConsignmentDetails.bookingBranch}
            onChange={handleChange}
            id="bookingBranch"
            size="small"
            variant="outlined"
            sx={{ width: "70%" }}
          />
        </Grid>
      </Grid>

      <Box sx={{ width: "100%", mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Consignment Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom sx={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
              Consignor Detail
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom sx={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
              Consignee Details
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
              Name
            </Typography>
            <TextField
              fullWidth
              name="consignorName"
              value={senderConsignmentDetails.consignorName}
              onChange={handleChange}
              id="consignorName"
              variant="outlined"
              size="small"
              sx={{ width: "70%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
              Name
            </Typography>
            <TextField
              fullWidth
              name="consigneeName"
              value={senderConsignmentDetails.consigneeName}
              onChange={handleChange}
              id="consigneeName"
              variant="outlined"
              size="small"
              sx={{ width: "70%" }}
            />
          </Grid>
          <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
                Phone
              </Typography>
              <TextField
                fullWidth
                name="consignorPhone"
                id="phone"
                variant="outlined"
                size="small"
                sx={{ width: "70%" }}
                value={senderConsignmentDetails.consignorPhone}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
                Phone
              </Typography>
              <TextField
                fullWidth
                name="consigneePhone"
                id="phone"
                size="small"
                variant="outlined"
                sx={{ width: "70%" }}
                value={senderConsignmentDetails.consigneePhone}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
                Address
              </Typography>
              <TextField
                fullWidth
                name="consignorAddress"
                id="address"
                variant="outlined"
                size="small"
                sx={{ width: "70%" }}
                value={senderConsignmentDetails.consignorAddress}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
                Address
              </Typography>
              <TextField
                fullWidth
                name="consigneeAddress"
                id="address"
                size="small"
                variant="outlined"
                sx={{ width: "70%" }}
                value={senderConsignmentDetails.consigneeAddress}
                onChange={handleChange}
              />
            </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SenderConsignmentDetails;






// import { Box, Grid, TextField, Typography } from '@mui/material'
// import React from 'react'

// const Sender_ConsignmentDetails = () => {
//   return (
//     <>
//            <Grid container spacing={2}>
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             sx={{ display: "flex", alignItems: "center" }}
//           >
//             <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//               Sender Code
//             </Typography>
//             <TextField
//               fullWidth
//               name="senderCode"
//               id="senderCode"
//               variant="outlined"
//               size="small"
//               sx={{ width: "70%" }}
//             />
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             sx={{ display: "flex", alignItems: "center" }}
//           >
//             <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//               Booking Branch
//             </Typography>
//             <TextField
//               fullWidth
//               name="bookingBranch"
//               id="bookingBranch"
//               size="small"
//               variant="outlined"
//               sx={{ width: "70%" }}
//             />
//           </Grid>
//         </Grid>

//         <Box sx={{ width: "100%", mt: 2 }}>
//           <Typography variant="h6" gutterBottom>
//             Consignment Details
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid
//               item
//               xs={12}
//               sm={6}
//               sx={{ display: "flex", alignItems: "center" }}
//             >
//               <Typography
//                 variant="body1"
//                 gutterBottom
//                 sx={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
//               >
//                 Consignor Detail
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={6}
//               sx={{ display: "flex", alignItems: "center" }}
//             >
//               <Typography
//                 variant="body1"
//                 gutterBottom
//                 sx={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
//               >
//                 Consignee Details
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container spacing={2}>
//             <Grid
//               item
//               xs={12}
//               sm={6}
//               sx={{ display: "flex", alignItems: "center" }}
//             >
//               <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//                 Name
//               </Typography>
//               <TextField
//                 fullWidth
//                 name="name"
//                 id="name"
//                 variant="outlined"
//                 size="small"
//                 sx={{ width: "70%" }}
//               />
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={6}
//               sx={{ display: "flex", alignItems: "center" }}
//             >
//               <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//                 Name
//               </Typography>
//               <TextField
//                 fullWidth
//                 name="name"
//                 id="name"
//                 variant="outlined"
//                 size="small"
//                 sx={{ width: "70%" }}
//               />
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={6}
//               sx={{ display: "flex", alignItems: "center" }}
//             >
//               <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//                 Phone
//               </Typography>
//               <TextField
//                 fullWidth
//                 name="phone"
//                 id="phone"
//                 variant="outlined"
//                 size="small"
//                 sx={{ width: "70%" }}
//               />
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={6}
//               sx={{ display: "flex", alignItems: "center" }}
//             >
//               <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//                 Phone
//               </Typography>
//               <TextField
//                 fullWidth
//                 name="phone"
//                 id="phone"
//                 size="small"
//                 variant="outlined"
//                 sx={{ width: "70%" }}
//               />
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={6}
//               sx={{ display: "flex", alignItems: "center" }}
//             >
//               <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//                 Address
//               </Typography>
//               <TextField
//                 fullWidth
//                 name="address"
//                 id="address"
//                 variant="outlined"
//                 size="small"
//                 sx={{ width: "70%" }}
//               />
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={6}
//               sx={{ display: "flex", alignItems: "center" }}
//             >
//               <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//                 Address
//               </Typography>
//               <TextField
//                 fullWidth
//                 name="address"
//                 id="address"
//                 size="small"
//                 variant="outlined"
//                 sx={{ width: "70%" }}
//               />
//             </Grid>
//           </Grid>
//         </Box>
//     </>
//   )
// }

// export default Sender_ConsignmentDetails



// import { Box, TextField, Typography, Button, MenuItem } from "@mui/material";
// import { useNavigate, Link, useParams } from "react-router-dom";
// import { useState, useEffect } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import BackEndUrl from "../../../../api.js";

// const UpdateOrderStatus = () => {
//   const navigate = useNavigate();

//   const { trackingNumber } = useParams();
//   const [editedDetails, setEditedDetails] = useState({ trackingDetails: [] });
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchOrderDetails = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         const response = await axios.get(
//           `${BackEndUrl}/api/order/${trackingNumber}`,
//           {
//             headers:{
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
     
//         setEditedDetails(response.data);
//         setError(null);
//       } catch (error) {
//         console.error("Error fetching order details:", error);
//         setError("Error fetching order details. Please try again.");
//       }
//     };

//     fetchOrderDetails();
//   }, [trackingNumber]);

//   const handleInputChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedDetails = [...editedDetails.trackingDetails];
//     updatedDetails[index][name] = value;

//     setEditedDetails((prevDetails) => ({
//       ...prevDetails,
//       trackingDetails: updatedDetails,
//     }));
//   };

//   const handleUpdate = async () => {
//     try {
//       const token = localStorage.getItem("token")

//       await axios.put(
        
//         `${BackEndUrl}/api/order/${trackingNumber}`,
//         editedDetails ,
//         {
//           headers:{
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       toast.success("Status updated successfully.");
//     } catch (error) {
//       console.error("Error updating order details:", error);
//       setError("Error updating order details. Please try again.");
//       toast.error("Order Tracking Details Status Failed.");
//     }
//     navigate("/Admin");
//   };

//   const addRow = () => {
//     setEditedDetails((prevDetails) => ({
//       ...prevDetails,
//       trackingDetails: [
//         ...prevDetails.trackingDetails,
//         { status: "", dateTime: "", remarks: "" },
//       ],
//     }));
//   };

//   const removeRow = (index) => {
//     const updatedDetails = [...editedDetails.trackingDetails];
//     updatedDetails.splice(index, 1);
//     setEditedDetails((prevDetails) => ({
//       ...prevDetails,
//       trackingDetails: updatedDetails,
//     }));
//   };

//   const selectedStatuses = editedDetails.trackingDetails.map(
//     (detail) => detail.status
//   );

//   return (
//     <Box sx={{ mb: "50px", ml: "180px" }}>
//       <Typography
//         variant="h4"
//         sx={{
//           textAlign: "center",
//           color: "black",
//           fontWeight: "bold",
//           mb: "20px",
//         }}
//       >
//         Update Tracking status
//       </Typography>
//       {error && <Typography color="error">{error}</Typography>}
//       <Box sx={{ width: "800px", margin: "0 auto" }}>
//         <TextField
//           name="trackingNumber"
//           label="Tracking Number"
//           value={editedDetails.trackingNumber || ""}
//           onChange={handleInputChange}
//           fullWidth
//           disabled
//           style={{ marginBottom: 10 }}
//         />

//         {editedDetails.trackingDetails.map((detail, index) => (
//           <Box
//             key={index}
//             sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb:1}}
//           >
//             <TextField
//               select
//               name="status"
//               value={detail.status || ""}
//               onChange={(e) => handleInputChange(e, index)}
//             >
//               <MenuItem
//                 value="pickup"
//                 disabled={selectedStatuses.includes("pickup")}
//               >
//                 Pick Up
//               </MenuItem>
//               <MenuItem value="intransit">In Transit</MenuItem>
//               <MenuItem
//                 value="delivered"
//                 disabled={selectedStatuses.includes("delivered")}
//               >
//                 Delivered
//               </MenuItem>
//             </TextField>
//             <TextField
//               type="datetime-local"
//               name="dateTime"
//               value={detail.dateTime || ""}
//               onChange={(e) => handleInputChange(e, index)}
//             />
//             <TextField
//               name="remarks"
//               value={detail.remarks || ""}
//               onChange={(e) => handleInputChange(e, index)}
//               sx={{ width: "50%" }}
//             />
//             <Button
//               variant="outlined"
//               color="error"
//               onClick={() => removeRow(index)}
//               sx={{
//                 "&:hover": {bgcolor:"red", color:"white"}
//               }}
//             >
//               x
//             </Button>
//           </Box>
//         ))}
//       </Box>

//       <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
//         <Link to="/Admin">
//           <Button
//             variant="contained"
//             color="error"
//             size="small"
//             style={{ marginRight: "10px" }}
//           >
//             Cancel
//           </Button>
//         </Link>

//         <Button
//           variant="contained"
//           color="primary"
//           size="small"
//           onClick={handleUpdate}
//           style={{ marginRight: "15px" }}
//         >
//           Update
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           size="small"
//           onClick={addRow}
//           style={{ marginRight: "15px" }}
//         >
//           Add
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default UpdateOrderStatus;


import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import BackEndUrl from "../../../../api.js";

const UpdateOrderStatus = () => {
  const navigate = useNavigate();
  const { trackingNumber } = useParams();
  const [editedDetails, setEditedDetails] = useState({ trackingDetails: [] });
  const [error, setError] = useState(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${BackEndUrl}/api/order/${trackingNumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEditedDetails(response.data || { trackingDetails: [] });
        setError(null);
      } catch (error) {
        console.error("Error fetching order details:", error);
        setError("Error fetching order details. Please try again.");
      }
    };
    fetchOrderDetails();
  }, [trackingNumber]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedDetails = [...(editedDetails.trackingDetails || [])];
    updatedDetails[index][name] = value;

    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      trackingDetails: updatedDetails,
    }));
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(`${BackEndUrl}/api/order/${trackingNumber}`, editedDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Status updated successfully.");
      navigate("/Admin");
    } catch (error) {
      console.error("Error updating order details:", error);
      setError("Error updating order details. Please try again.");
      toast.error("Order Tracking Details Status Failed.");
    }
    setOpenUpdateDialog(false);
  };

  const confirmDeleteRow = (index) => {
    setDeleteIndex(index);
    setOpenDeleteDialog(true);
  };

  const deleteRow = () => {
    const updatedDetails = [...(editedDetails.trackingDetails || [])];
    updatedDetails.splice(deleteIndex, 1);
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      trackingDetails: updatedDetails,
    }));
    setOpenDeleteDialog(false);
  };

  const addRow = () => {
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      trackingDetails: [
        ...(prevDetails.trackingDetails || []),
        { status: "", dateTime: "", remarks: "" },
      ],
    }));
  };

  const selectedStatuses = (editedDetails.trackingDetails || []).map((detail) => detail.status);

  return (
    <Box sx={{ mb: "50px", ml: "180px" }}>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          color: "black",
          fontWeight: "bold",
          mb: "20px",
        }}
      >
        Update Tracking status
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Box sx={{ width: "800px", margin: "0 auto" }}>
        <TextField
          name="trackingNumber"
          label="Tracking Number"
          value={editedDetails.trackingNumber || ""}
          onChange={handleInputChange}
          fullWidth
          disabled
          style={{ marginBottom: 10 }}
        />

        {(editedDetails.trackingDetails || []).map((detail, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <TextField
              select
              name="status"
              value={detail.status || ""}
              onChange={(e) => handleInputChange(e, index)}
            >
              <MenuItem
                value="pickup"
                disabled={selectedStatuses.includes("pickup")}
              >
                Pick Up
              </MenuItem>
              <MenuItem value="intransit">In Transit</MenuItem>
              <MenuItem
                value="delivered"
                disabled={selectedStatuses.includes("delivered")}
              >
                Delivered
              </MenuItem>
            </TextField>
            <TextField
              type="datetime-local"
              name="dateTime"
              value={detail.dateTime || ""}
              onChange={(e) => handleInputChange(e, index)}
            />
            <TextField
              name="remarks"
              value={detail.remarks || ""}
              onChange={(e) => handleInputChange(e, index)}
              sx={{ width: "50%" }}
            />
            <Button
              variant="outlined"
              color="error"
              onClick={() => confirmDeleteRow(index)}
              sx={{
                "&:hover": { bgcolor: "red", color: "white" },
              }}
            >
              x
            </Button>
          </Box>
        ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
        <Link to="/Admin">
          <Button
            variant="contained"
            color="error"
            size="small"
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
        </Link>

        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setOpenUpdateDialog(true)}
          style={{ marginRight: "15px" }}
        >
          Update
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={addRow}
          style={{ marginRight: "15px" }}
        >
          Add
        </Button>
      </Box>

      <Dialog
        open={openUpdateDialog}
        onClose={() => setOpenUpdateDialog(false)}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to update this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUpdateDialog(false)} size="small" color="error">Cancel</Button>
          <Button onClick={handleUpdate} variant="contained" size="small" color="success">Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle >Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this tracking detail? <br />
           <b> If you click on delete then make sure to update the tracking status...</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} sx={{color:"black","&:hover":{bgcolor:"black", color:"white"}}}>Cancel</Button>
          <Button onClick={deleteRow} sx={{"&:hover":{bgcolor:"red", color:"white"}}} size="small" variant="outlined" color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UpdateOrderStatus;




// import { Box, TextField, Typography, Button, MenuItem } from "@mui/material";
// import { useNavigate, Link, useParams } from "react-router-dom";
// import { useState, useEffect } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import BackEndUrl from "../../../../api.js";

// const UpdateOrderStatus = () => {
//   const navigate = useNavigate();
//   const { trackingNumber } = useParams();
//   const [editedDetails, setEditedDetails] = useState({ trackingDetails: [] });
//   const [error, setError] = useState(null);


//   useEffect(() => {
//     const fetchOrderDetails = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         const response = await axios.get(
//           `${BackEndUrl}/api/order/${trackingNumber}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             }
//           }
//         );
  
//         // Make sure to handle cases where trackingDetails might be undefined
//         setEditedDetails(response.data || { trackingDetails: [] });
//         setError(null);
//       } catch (error) {
//         console.error("Error fetching order details:", error);
//         setError("Error fetching order details. Please try again.");
//       }
//     };
  
//     fetchOrderDetails();
//   }, [trackingNumber]);
  

//   const handleInputChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedDetails = [...(editedDetails.trackingDetails || [])];
//     updatedDetails[index][name] = value;

//     setEditedDetails((prevDetails) => ({
//       ...prevDetails,
//       trackingDetails: updatedDetails,
//     }));
//   };

//   const handleUpdate = async () => {
//     try {
//       const token = localStorage.getItem("token");

//       await axios.put(
//         `${BackEndUrl}/api/order/${trackingNumber}`,
//         editedDetails,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           }
//         }
//       );
//       toast.success("Status updated successfully.");
//       navigate("/Admin");
//     } catch (error) {
//       console.error("Error updating order details:", error);
//       setError("Error updating order details. Please try again.");
//       toast.error("Order Tracking Details Status Failed.");
//     }
//   };

//   // const handleUpdate = async () => {
//   //   try {
//   //     const token = localStorage.getItem("token");
  
//   //     await axios.put(
//   //       `${BackEndUrl}/api/order/${trackingNumber}`,
//   //       { trackingDetails: editedDetails.trackingDetails },
//   //       {
//   //         headers: {
//   //           Authorization: `Bearer ${token}`,
//   //         }
//   //       }
//   //     );
//   //     toast.success("Status updated successfully.");
//   //     navigate("/Admin");
//   //   } catch (error) {
//   //     console.error("Error updating order details:", error);
//   //     setError("Error updating order details. Please try again.");
//   //     toast.error("Order Tracking Details Status Failed.");
//   //   }
//   // };
  

//   const addRow = () => {
//     setEditedDetails((prevDetails) => ({
//       ...prevDetails,
//       trackingDetails: [
//         ...(prevDetails.trackingDetails || []),
//         { status: "", dateTime: "", remarks: "" },
//       ],
//     }));
//   };

//   const removeRow = (index) => {
//     const updatedDetails = [...(editedDetails.trackingDetails || [])];
//     updatedDetails.splice(index, 1);
//     setEditedDetails((prevDetails) => ({
//       ...prevDetails,
//       trackingDetails: updatedDetails,
//     }));
//   };

//   const selectedStatuses = (editedDetails.trackingDetails || []).map(
//     (detail) => detail.status
//   );

//   return (
//     <Box sx={{ mb: "50px", ml: "180px" }}>
//       <Typography
//         variant="h4"
//         sx={{
//           textAlign: "center",
//           color: "black",
//           fontWeight: "bold",
//           mb: "20px",
//         }}
//       >
//         Update Tracking status
//       </Typography>
//       {error && <Typography color="error">{error}</Typography>}
//       <Box sx={{ width: "800px", margin: "0 auto" }}>
//         <TextField
//           name="trackingNumber"
//           label="Tracking Number"
//           value={editedDetails.trackingNumber || ""}
//           onChange={handleInputChange}
//           fullWidth
//           disabled
//           style={{ marginBottom: 10 }}
//         />

//         {(editedDetails.trackingDetails || []).map((detail, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//               mb: 1,
//             }}
//           >
//             <TextField
//               select
//               name="status"
//               value={detail.status || ""}
//               onChange={(e) => handleInputChange(e, index)}
//             >
//               <MenuItem
//                 value="pickup"
//                 disabled={selectedStatuses.includes("pickup")}
//               >
//                 Pick Up
//               </MenuItem>
//               <MenuItem value="intransit">In Transit</MenuItem>
//               <MenuItem
//                 value="delivered"
//                 disabled={selectedStatuses.includes("delivered")}
//               >
//                 Delivered
//               </MenuItem>
//             </TextField>
//             <TextField
//               type="datetime-local"
//               name="dateTime"
//               value={detail.dateTime || ""}
//               onChange={(e) => handleInputChange(e, index)}
//             />
//             <TextField
//               name="remarks"
//               value={detail.remarks || ""}
//               onChange={(e) => handleInputChange(e, index)}
//               sx={{ width: "50%" }}
//             />
//             <Button
//               variant="outlined"
//               color="error"
//               onClick={() => removeRow(index)}
//               sx={{
//                 "&:hover": { bgcolor: "red", color: "white" },
//               }}
//             >
//               x
//             </Button>
//           </Box>
//         ))}
//       </Box>

//       <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
//         <Link to="/Admin">
//           <Button
//             variant="contained"
//             color="error"
//             size="small"
//             style={{ marginRight: "10px" }}
//           >
//             Cancel
//           </Button>
//         </Link>

//         <Button
//           variant="contained"
//           color="primary"
//           size="small"
//           onClick={handleUpdate}
//           style={{ marginRight: "15px" }}
//         >
//           Update
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           size="small"
//           onClick={addRow}
//           style={{ marginRight: "15px" }}
//         >
//           Add
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default UpdateOrderStatus;

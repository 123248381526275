

import React from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import "./footer.css";
import { Link } from "react-router-dom";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  return (
    <footer>
      <Box className="top">
        <Box className="pages">
          <Box component="ul">
            <Typography variant="h6">Quick Links</Typography>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/Service">Services</Link></li>
            <li><Link to="/Track">Track</Link></li>
            <li><Link to="/Contact">Contact</Link></li>
          </Box>

          {/* <Box component="ul">
            <Typography variant="h6">Careers</Typography>
            <li><Link to="/">Apply Online</Link></li>
            <li><Link to="/">Available Positions</Link></li>
          </Box> */}

          <Box component="ul">
            <Typography variant="h6">About Us</Typography>
            <li><Link to="/">Meet Our Team</Link></li>
            <li><Link to="/">Our Responsibilities</Link></li>
            <li><Link to="/">Our Codes</Link></li>
            <li><Link to="/">Our Values</Link></li>
          </Box>
        </Box>

        <Box className="newsletter">
          <Typography variant="h6">Stay in Touch</Typography>
          <form>
            {/* <TextField
              type="email"
              name="newsletter_email"
              id="newsletter_email"
              placeholder="Email"
              variant="outlined"
              sx={{p:0, m:0}}
              InputProps={{
                endAdornment: (
                  <Button variant="contained" sx={{color: "white", bgcolor: "#6A43C7", "&:hover": { bgcolor: "#3e0faa" } }} >
                    Submit
                  </Button>
                ),
              }}
            /> */}
            <div className="flex h-[35px] bg-transparent">
              <input type="text"  className="bg-transparent border rounded-l border-gray-700 pl-[10px]" placeholder="Email"/>
              <button className="text-white bg-[#6A43C7] hover:bg-[#3e0faa] transform px-[10px] text-center rounded-r">Submit</button>
            </div>
          </form>
        </Box>
      </Box>

      {/* <Box className="social">
        <LinkedInIcon className="fab fa-linkedin" style={{cursor:"pointer"}} />
        <FacebookIcon className="fab fa-facebook" style={{cursor:"pointer"}} />
        <TwitterIcon className="fab fa-twitter" style={{cursor:"pointer"}} />
        <YouTubeIcon className="fab fa-youtube" style={{cursor:"pointer"}} />
      </Box> */}

      <Box className="info">
        <Box className="legal">
          <Link to="TermsandConditions">Terms & Conditions</Link>
          <Link to="PrivacyPolicy">Privacy Policy</Link>
         <Link to="cancellationPolicy">Cancellation and Refund Policy</Link>
        </Box>
        <Typography
          variant="body2"
          align="center"
          className="copyright"
        >
          {"Copyright © "}
          <a

            target="_blank"
            href="https://shiftyourcargo.com/"
          >
            ShiftYourCargo
          </a>{" "}
          {""}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;


// import {
//   Box,
//   Button,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
//   Typography,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";
// import React, { useState } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import BackEndUrl from "../../../api";

// const RaiseRequest = () => {
//   const getCurrentDate = () => {
//     const today = new Date();
//     const year = today.getFullYear();
//     const month = String(today.getMonth() + 1).padStart(2, "0");
//     const date = String(today.getDate()).padStart(2, "0");
//     return `${year}-${month}-${date}`;
//   };

//   const navigate = useNavigate();

//   const [companyName, setCompanyName] = useState("");
//   const [sourcePin, setSourcePin] = useState("");
//   const [destinationPin, setDestinationPin] = useState("");
//   const [approximateWeight, setApproximateWeight] = useState("");
//   const [address, setAddress] = useState("");
//   const [name, setName] = useState("");
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [expectedPackages, setExpectedPackages] = useState("");
//   const [date, setDate] = useState(getCurrentDate());
//   const [time, setTime] = useState("");
//   const [openDialog, setOpenDialog] = useState(false);

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     handleOpenDialog();
//   };

//   const handleConfirmSubmit = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.post(
//         `${BackEndUrl}/api/pickUpRequest`,
//         {
//           companyName,
//           name,
//           mobileNumber,
//           sourcePin,
//           destinationPin,
//           address,
//           expectedPackages,
//           approximateWeight,
//           date,
//           time,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("Response:", response.data);
//       toast.success("Pickup Request Created successfully..");
//       setCompanyName("");
//       setAddress("");
//       setName("");
//       setMobileNumber("");
//       setExpectedPackages("");
//       setApproximateWeight("");
//       setDate(getCurrentDate()); // Reset date to current date
//       setTime("");
//       handleCloseDialog();
//       navigate("/Admin");
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("Pickup Request Failed..");
//       handleCloseDialog();
//     }
//   };

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         px: 2,
//         py: 4,
//       }}
//     >
//       <Box
//         sx={{
//           width: { xs: "100%", sm: "75%", md: "50%", lg: "35%" },
//         }}
//       >
//         <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 2 }}>
//           Create New Request
//         </Typography>

//         <Box
//           sx={{
//             bgcolor: "rgb(240, 240, 240)",
//             p: 3,
//             borderRadius: 2,
//           }}
//         >
//           <form onSubmit={handleSubmit}>
//             <Box sx={{ mb: 3 }}>
//               <TextField
//                 label="Company Name/Code"
//                 variant="standard"
//                 required
//                 fullWidth
//                 value={companyName}
//                 onChange={(e) => setCompanyName(e.target.value)}
//               />
//             </Box>
//             <Box sx={{ mb: 3 }}>
//               <TextField
//                 label="Name"
//                 variant="standard"
//                 required
//                 fullWidth
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//               />
//             </Box>
//             <Box sx={{ mb: 3 }}>
//               <TextField
//                 label="Number"
//                 variant="standard"
//                 required
//                 fullWidth
//                 value={mobileNumber}
//                 onChange={(e) => setMobileNumber(e.target.value)}
//               />
//             </Box>
//             <Box sx={{ mb: 3 }}>
//               <TextField
//                 label="Source Pin Code"
//                 variant="standard"
//                 required
//                 fullWidth
//                 value={sourcePin}
//                 onChange={(e) => setSourcePin(e.target.value)}
//               />
//             </Box>
//             <Box sx={{ mb: 3 }}>
//               <TextField
//                 label="Destination Pin Code"
//                 variant="standard"
//                 required
//                 fullWidth
//                 value={destinationPin}
//                 onChange={(e) => setDestinationPin(e.target.value)}
//               />
//             </Box>
//             <Box sx={{ mb: 3 }}>
//               <TextField
//                 label="Source Address"
//                 variant="standard"
//                 required
//                 fullWidth
//                 value={address}
//                 onChange={(e) => setAddress(e.target.value)}
//               />
//             </Box>
//             <Box sx={{ mb: 3 }}>
//               <TextField
//                 label="Expected Packages"
//                 variant="standard"
//                 required
//                 fullWidth
//                 value={expectedPackages}
//                 onChange={(e) => setExpectedPackages(e.target.value)}
//               />
//             </Box>
//             <Box sx={{ mb: 3 }}>
//               <TextField
//                 label="Approximate Weight"
//                 variant="standard"
//                 required
//                 fullWidth
//                 value={approximateWeight}
//                 onChange={(e) => setApproximateWeight(e.target.value)}
//               />
//             </Box>
//             <Box sx={{ mb: 3 }}>
//               <TextField
//                 type="date"
//                 label="Date"
//                 variant="standard"
//                 required
//                 fullWidth
//                 inputProps={{ min: getCurrentDate() }}
//                 value={date}
//                 onChange={(e) => setDate(e.target.value)}
//               />
//             </Box>
//             <Box sx={{ mb: 3 }}>
//               <FormControl fullWidth>
//                 <InputLabel id="pickup-time-label">Pickup Time</InputLabel>
//                 <Select
//                   labelId="pickup-time-label"
//                   label="Pickup Time"
//                   variant="standard"
//                   required
//                   value={time}
//                   onChange={(e) => setTime(e.target.value)}
//                 >
//                   <MenuItem value={"01:00 PM - 04:00 PM"}>
//                     01:00 PM - 04:00 PM
//                   </MenuItem>
//                   <MenuItem value={"04:00 PM - 07:00 PM"}>
//                     04:00 PM - 07:00 PM
//                   </MenuItem>
//                   <MenuItem value={"07:00 PM - 10:00 PM"}>
//                     07:00 PM - 10:00 PM
//                   </MenuItem>
//                 </Select>
//               </FormControl>
//             </Box>
//             <Box sx={{ display: "flex", justifyContent: "center" }}>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 sx={{
//                   bgcolor: "#fff",
//                   color: "#000",
//                   "&:hover": { color: "white", bgcolor: "#000" },
//                 }}
//               >
//                 Create
//               </Button>
//             </Box>
//           </form>
//         </Box>
//       </Box>

//       <Dialog open={openDialog} onClose={handleCloseDialog}>
//         <DialogTitle>Confirm Request Creation</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Are you sure you want to create this pickup request?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="error">
//             Cancel
//           </Button>
//           <Button onClick={handleConfirmSubmit} color="success" variant="contained">
//             Confirm
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default RaiseRequest;



import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BackEndUrl from "../../../api";

const RaiseRequest = () => {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const date = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${date}`;
  };

  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [sourcePin, setSourcePin] = useState("");
  const [destinationPin, setDestinationPin] = useState("");
  const [approximateWeight, setApproximateWeight] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [expectedPackages, setExpectedPackages] = useState("");
  const [date, setDate] = useState(getCurrentDate());
  const [time, setTime] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleOpenDialog();
  };

  const handleConfirmSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BackEndUrl}/api/pickUpRequest`,
        {
          companyName,
          name,
          mobileNumber: `+91${mobileNumber}`,
          sourcePin,
          destinationPin,
          address,
          expectedPackages,
          approximateWeight,
          date,
          time,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log("Response:", response.data);
      toast.success("Pickup Request Created successfully..");
      setCompanyName("");
      setAddress("");
      setName("");
      setMobileNumber("");
      setExpectedPackages("");
      setApproximateWeight("");
      setDate(getCurrentDate()); // Reset date to current date
      setTime("");
      handleCloseDialog();
      navigate("/Admin");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Pickup Request Failed..");
      handleCloseDialog();
    }
  };

  const validatePinCode = (value) => {
    return value.replace(/[^0-9]/g, "").slice(0, 6);
  };

  const validateMobileNumber = (value) => {
    return value.replace(/[^0-9]/g, "").slice(0, 10);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 2,
        py: 4,
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "75%", md: "50%", lg: "35%" },
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 2 }}>
          Create New Request
        </Typography>

        <Box
          sx={{
            bgcolor: "rgb(240, 240, 240)",
            p: 3,
            borderRadius: 2,
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Company Name/Code"
                variant="standard"
                required
                fullWidth
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Name"
                variant="standard"
                required
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Mobile Number"
                variant="standard"
                required
                fullWidth
                value={mobileNumber}
                onChange={(e) => setMobileNumber(validateMobileNumber(e.target.value))}
                InputProps={{ startAdornment: "+91 " }}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Source Pin Code"
                variant="standard"
                required
                fullWidth
                value={sourcePin}
                onChange={(e) => setSourcePin(validatePinCode(e.target.value))}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Destination Pin Code"
                variant="standard"
                required
                fullWidth
                value={destinationPin}
                onChange={(e) => setDestinationPin(validatePinCode(e.target.value))}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Source Address"
                variant="standard"
                required
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Expected Packages"
                variant="standard"
                required
                fullWidth
                value={expectedPackages}
                onChange={(e) => setExpectedPackages(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Approximate Weight"
                variant="standard"
                required
                fullWidth
                value={approximateWeight}
                onChange={(e) => setApproximateWeight(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                type="date"
                label="Date"
                variant="standard"
                required
                fullWidth
                inputProps={{ min: getCurrentDate() }}
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="pickup-time-label">Pickup Time</InputLabel>
                <Select
                  labelId="pickup-time-label"
                  label="Pickup Time"
                  variant="standard"
                  required
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <MenuItem value={"01:00 PM - 04:00 PM"}>
                    01:00 PM - 04:00 PM
                  </MenuItem>
                  <MenuItem value={"04:00 PM - 07:00 PM"}>
                    04:00 PM - 07:00 PM
                  </MenuItem>
                  <MenuItem value={"07:00 PM - 10:00 PM"}>
                    07:00 PM - 10:00 PM
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#fff",
                  color: "#000",
                  "&:hover": { color: "white", bgcolor: "#000" },
                }}
              >
                Create
              </Button>
            </Box>
          </form>
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Request Creation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to create this pickup request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmSubmit} color="success" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RaiseRequest;

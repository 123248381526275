// import { Box, Button } from "@mui/material";
// import React, { useRef } from "react";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import logo from "../../../../images/syc-logo.jpg";
// import { useLocation } from "react-router-dom";

// const PdfFile = () => {
//   const pdfRef = useRef();
//   const location = useLocation();
//   const { orderDetails } = location.state || {};

//   const handleDownload = () => {
//     const input = pdfRef.current;
//     html2canvas(input, { scale: 2 })
//       .then((canvas) => {
//         const imgData = canvas.toDataURL("image/png");
//         const pdf = new jsPDF("p", "mm", "a4");
//         const pdfWidth = pdf.internal.pageSize.getWidth();
//         const pdfHeight = pdf.internal.pageSize.getHeight();
//         const imgWidth = canvas.width;
//         const imgHeight = canvas.height;
//         const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

//         const imgProps = pdf.getImageProperties(imgData);
//         const pdfImgWidth = imgProps.width * ratio;
//         const pdfImgHeight = imgProps.height * ratio;

//         pdf.addImage(imgData, "PNG", 0, 0, pdfImgWidth, pdfImgHeight);
//         pdf.save("document.pdf");
//       })
//       .catch((error) => {
//         console.error("Could not generate PDF", error);
//       });
//   };

//   if (!orderDetails) {
//     return <div>Loading...</div>;
//   }

import { Box, Button } from "@mui/material";
import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../../../images/syc-logo.jpg";
import { useLocation } from "react-router-dom";

const PdfFile = () => {
  const pdfRef = useRef();
  const location = useLocation();
  const { orderDetails } = location.state || {};

  const handleDownload = () => {
    const input = pdfRef.current;
    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4"); // Use A4 size
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

        const imgProps = pdf.getImageProperties(imgData);
        const pdfImgWidth = imgProps.width * ratio;
        const pdfImgHeight = imgProps.height * ratio;

        // Center the image on the PDF
        const xOffset = (pdfWidth - pdfImgWidth) / 2;
        const yOffset = (pdfHeight - pdfImgHeight) / 2;

        pdf.addImage(
          imgData,
          "PNG",
          xOffset,
          yOffset,
          pdfImgWidth,
          pdfImgHeight
        );
        pdf.save("document.pdf");
      })
      .catch((error) => {
        console.error("Could not generate PDF", error);
      });
  };

  if (!orderDetails) {
    return <div>Loading...</div>;
  }
  return (
    <Box>
      <Box sx={{ mb: 2, display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          size="small"
          sx={{
            bgcolor: "black",
            "&:hover": { bgcolor: "grey", color: "black" },
          }}
          onClick={handleDownload}
        >
          Download Pdf
        </Button>
      </Box>
      {/*  width: "220mm", height: "auto", */}
      {/* pdf file */}
      <Box
        ref={pdfRef}
        sx={{ p: 4, width: "310mm", height: "auto", boxSizing: "border-box" }}
      >
        <Box
          sx={{
            width: "100%",
            border: "1px solid black",
            mb: "50px",
            fontSize: "11px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ borderRight: "1px solid black", width: "20%", p: 2 }}>
              <img src={logo} alt="logo" width="100px" />
            </Box>
            <Box
              sx={{
                borderRight: "1px solid black",
                width: "30%",
                fontSize: "10px",
                p: 1,
              }}
            >
              <div
                style={{ fontSize: "12px", margin: "0px", fontWeight: "bold" }}
              >
                {" "}
                SHIFT YOUR CARGO
              </div>{" "}
              <br /> No - 70/3, Yalakki Narasimha Industrial Area,
              <br />
              Peenya SRS, College Road, Bangalore - 560 058 <br />
              Transporter ID - 29AAJCT5373M1ZJ <br /> CIN No -
              U72900KA2022PTC163814 <br />
              PAN - AAJCT5373M
            </Box>
            <Box sx={{ borderRight: "1px solid black", width: "20%" }}>
              <Box
                sx={{
                  borderBottom: "1px solid black",
                  width: "100%",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Origin
              </Box>
              <Box sx={{ p: 2 }}>{orderDetails.origin}</Box>
            </Box>
            <Box sx={{ borderRight: "1px solid black", width: "20%" }}>
              <Box
                sx={{
                  borderBottom: "1px solid black",
                  width: "100%",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Destination
              </Box>
              <Box sx={{ p: 2 }}>{orderDetails.destination}</Box>
            </Box>
            <Box sx={{ borderRight: "1px solid black", width: "20%" }}>
              <Box
                sx={{
                  borderBottom: "1px solid black",
                  width: "100%",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                LR No
              </Box>
              <Box sx={{ p: 2 }}>{orderDetails.trackingNumber}</Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.8%",
                p: 1,
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Booking Date:{" "}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.8%",
                p: 1,
              }}
            >
              {orderDetails.bookingDate}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.8%",
                p: 1,
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Time:{" "}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.8%",
                p: 1,
              }}
            >
              {orderDetails.time}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.5%",
                p: 1,
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Service Type:{" "}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.5%",
                p: 1,
              }}
            >
              {orderDetails.shippingMethod}
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.8%",
                p: 1,
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Sender Code:{" "}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.8%",
                p: 1,
              }}
            >
              {orderDetails.senderCode}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.8%",
                p: 1,
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Booking Branch:{" "}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.8%",
                p: 1,
              }}
            >
              {orderDetails.bookingBranch}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.5%",
                p: 1,
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              PUR Reference No:{" "}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "16.5%",
                p: 1,
              }}
            >
              {orderDetails.reference}
            </Box>
          </Box>
          {/* Rest of the PDF content */}
          <Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Consignor Details
              </Box>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Consignee Details
              </Box>
              <Box
                sx={{
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Actual Weight
              </Box>
              <Box
                sx={{
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Charged Weight
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  p: 1,
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    display: "inline-block",
                    marginRight: "30%",
                  }}
                >
                  Name:
                </div>{" "}
                {orderDetails.consignorName}
              </Box>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  p: 1,
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    display: "inline-block",
                    marginRight: "30%",
                  }}
                >
                  Name:
                </div>{" "}
                {orderDetails.consigneeName}
              </Box>

              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  borderBottom: "1px solid black",
                  textAlign: "center",
                  p: 1,
                }}
              >
                {orderDetails.totalWeight}
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  borderBottom: "1px solid black",
                  textAlign: "center",
                  p: 1,
                }}
              >
                {orderDetails.totalWeight}
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  p: 1,
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    display: "inline-block",
                    marginRight: "30%",
                  }}
                >
                  Phone:
                </div>{" "}
                {orderDetails.consignorPhone}
              </Box>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  p: 1,
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    display: "inline-block",
                    marginRight: "30%",
                  }}
                >
                  Phone:
                </div>{" "}
                {orderDetails.consigneePhone}
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  p: 1,
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    display: "inline-block",
                    marginRight: "28%",
                  }}
                >
                  Address:
                </div>{" "}
                {orderDetails.consignorAddress}
              </Box>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  p: 1,
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    display: "inline-block",
                    marginRight: "28%",
                  }}
                >
                  Address:
                </div>{" "}
                {orderDetails.consigneeAddress}
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: 1,
                }}
              ></Box>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: 1,
                }}
              ></Box>
              <Box
                sx={{
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Product Type
              </Box>
              <Box
                sx={{
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Payment Type
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: 1,
                }}
              ></Box>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: 1,
                }}
              ></Box>
              <Box
                sx={{
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                }}
              >
                {orderDetails.productType}
              </Box>
              <Box
                sx={{
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                }}
              >
                {orderDetails.paymentMethod}
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: 1,
                }}
              ></Box>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: 1,
                }}
              ></Box>
              <Box
                sx={{
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Risk Coverage
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    p: 1,
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Policy
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    p: 1,
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Validity
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  p: 1,
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    marginRight: "28%",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  GST No:
                </div>{" "}
                {orderDetails.gstNo1}
              </Box>
              <Box
                sx={{
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  p: 1,
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    marginRight: "28%",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  GST No:
                </div>{" "}
                {orderDetails.gstNo}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                }}
              >
                <Box sx={{ width: "100%", p: 1 }}>
                  {orderDetails.riskCoverage}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                  p: 1,
                }}
              >
                <Box
                  sx={{ width: "50%", borderRight: "1px solid black", p: 1 }}
                >
                  {orderDetails.policyNo}
                </Box>
                <Box sx={{ width: "50%", p: 1 }}>{orderDetails.validity}</Box>
              </Box>
            </Box>
          </Box>

           <Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "80%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: "5px",
                  fontWeight:"bold",
                  fontSize:"12px"
                }}
              >
                Package Information
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "20%",
                  border: "1px solid black",
                  borderLeft: "none",
                  textAlign: "center",
                }}
              >
                <Box width="100%">Charges</Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                    p: "5px",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Invoice No
                </Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Invoice Date
                </Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Invoice Value
                </Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Eway Bill No
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  {" "}
                  No Of Boxes
                </Box>
                <Box
                  sx={{
                    width: "75%",
                    textAlign: "center",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Dimensions
                </Box>
                
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Package Type
                </Box>
                <Box sx={{ width: "50%", textAlign: "center",fontWeight:"bold",
                  fontSize:"12px" }}>Content</Box>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  fontWeight:"bold",
                  fontSize:"12px"
                }}
              >
                (Retail Pick Up)
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  {orderDetails.invoiceNo}
                </Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                >{orderDetails.invoiceDate}</Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                >{orderDetails.invoiceValue}</Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >{orderDetails.eWayBillNo}</Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >{orderDetails.totalQuantity}</Box>
                <Box
                 sx={{
                  width: "75%",
                  textAlign: "center",
                }}
                > <Box sx={{p:1,display:"flex", flexDirection:"column"}}>{orderDetails.quantityDetails.map((qd, index) => (
                  <div key={index}>
                    <span style={{marginRight:"2px"}}>Qty: <b>{qd.quantity}</b> </span>
                    <span style={{marginRight:"2px"}}>Length:<b>{qd.length}</b> </span>
                    <span style={{marginRight:"2px"}}>Height:<b>{qd.height}</b> </span>
                    <span style={{marginRight:"2px"}}>Width: <b>{qd.width}</b></span>
                  </div>
                ))}</Box></Box>
               
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    p: "5px",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Base Freight
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "75%",
                    textAlign: "center",
                  }}
                ></Box>
                
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    p: "5px",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Processing Charge
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                 sx={{
                  width: "75%",
                  textAlign: "center",
                }}
                ></Box>
               
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    p: "5px",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Fuel Surcharge
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "75%",
                    textAlign: "center",
                  }}
                ></Box>
               
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    p: "5px",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Rov Charge
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "75%",
                    textAlign: "center",
                  }}
                ></Box>
                
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    p: "5px",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  FOD/COD/DOD
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                 sx={{
                  width: "75%",
                  textAlign: "center",
                }}
                ></Box>
                
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    p: "5px",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Handling Charge
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                 sx={{
                  width: "75%",
                  textAlign: "center",
                }}
                ></Box>
                
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    p: "5px",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  ODA Charge
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "30%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "25%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                ></Box>
                <Box
                 sx={{
                  width: "75%",
                  textAlign: "center",
                }}
                ></Box>
                
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
                <Box sx={{ width: "50%", textAlign: "center" }}></Box>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid black",
                    p: "5px",
                    fontWeight:"bold",
                  fontSize:"12px"
                  }}
                >
                  Special Delivery Charge
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  p: "5px",
                  fontWeight:"bold",
                  fontSize:"12px"
                }}
              >
                Retail Terms & Conditions
              </Box>
              <Box
                sx={{
                  width: "20%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  fontWeight:"bold",
                  fontSize:"12px"
                }}
              >
                cod
              </Box>
              <Box
                sx={{
                  width: "30%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                }}
              >
               <div style={{fontWeight:"bold", fontSize:"12px", display:"inline-block", marginRight:"10%" }}>LR No:</div> {orderDetails.trackingNumber}
              </Box>
              <Box
                sx={{
                  width: "20%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  fontWeight:"bold",
                  fontSize:"12px"
                }}
              >
                Demurrage Charge
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  fontSize: "10px",
                  p: "5px",
                }}
              >
                I/we have carefully checked and verified the contents of this
                consginment note and hereby agree to the terms and conditions
                set out on the reverse of this consignor copy and declare the
                contents on the waybill are true and correct. The To-pay (FOD)
                freight amount has my/our consent and will be paud by the
                consignee along with the applicable service charges or any taxes
                at the time of delivery
              </Box>
              <Box
                sx={{
                  width: "20%",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "1px solid black",
                    p: "5px",
                  }}
                >
                  <Box>Cash/DD/Cheque</Box>
                  <Box>COD Amount</Box>
                </Box>
                <Box>
                  Consignor hereby authorize SHIFT YOUR CARGO (a unit of TECH
                  AVIGN Pvt Ltd to collect the COD amount.
                </Box>
              </Box>
              <Box
                sx={{
                  width: "30%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                }}
              ></Box>
              <Box
                sx={{
                  width: "20%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                }}
              >
                <Box>
                  <Box sx={{ borderBottom: "1px solid black", p: "5px",fontWeight:"bold",
                  fontSize:"12px" }}>
                    Other Charges:
                  </Box>
                  <Box sx={{ borderBottom: "1px solid black", p: "5px",fontWeight:"bold",
                  fontSize:"12px" }}>
                    Sub Total:
                  </Box>
                  <Box sx={{ borderBottom: "1px solid black", p: "5px",fontWeight:"bold",
                  fontSize:"12px" }}>
                    GST@18%:
                  </Box>
                  <Box sx={{ borderBottom: "1px solid black", p: "5px",fontWeight:"bold",
                  fontSize:"12px" }}>
                    Grand Total:
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid black",
                      p: "5px",
                      fontWeight:"bold",
                  fontSize:"12px"
                    }}
                  >
                    Mode Of Payment
                  </Box>
                  <Box
                    sx={{ borderBottom: "1px solid black", display: "flex" }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        p: "5px",
                      }}
                    >
                      {orderDetails.modeOfPayment}
                    </Box>
                   
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid black",
                      display: "flex",
                      p: "5px",
                    }}
                  >
                    <Box>Cheque/DD(Only Fop)</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box> 

          {/* <Box sx={{display:"flex"}}>
            <Box sx={{display:"flex", width:"30%",borderTop:"1px solid black"}}>
            <Box sx={{}}>
              <Box sx={{borderBottom:"1px solid black"}}>Invoice No</Box>
              <Box>{orderDetails.invoiceNo}</Box>
            </Box>
            <Box>
              <Box>Invoice Date</Box>
              <Box>{orderDetails.invoiceDate}</Box>
            </Box>
            <Box>
              <Box>Invoice Value</Box>
              <Box>{orderDetails.invoiceValue}</Box>
            </Box>
            <Box>
              <Box>Eway Bill No</Box>
              <Box>{orderDetails.eWayBillNo}</Box>
            </Box>
            </Box>
           
            <Box sx={{width:"30%",borderTop:"1px solid black"}}>
              <Box>No Of Boxes & Dimensions</Box>
              <Box sx={{display:"flex", flexDirection:"column"}}>{orderDetails.quantityDetails.map((qd, index) => (
                  <div key={index}>
                    <span>Quantity: {qd.quantity}</span>
                    <span>Length: {qd.length}</span>
                    <span>Height: {qd.height}</span>
                    <span>Width: {qd.width}</span>
                  </div>
                ))}</Box>
            </Box>
            <Box sx={{display:"flex", width:"20%"}}>
              <Box>
                <Box>Package Type</Box>
              </Box>
              <Box>
                <Box>Contents</Box>
              </Box>
            </Box>
            <Box sx={{display:"flex", width:"20%"}}>
              Charges (Retail PickUP)
            </Box>
          </Box> */}

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                width: "30%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  borderRight: "1px solid black",
                  p: "5px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Documents Received
              </Box>
              <Box sx={{ fontWeight: "bold", fontSize: "12px" }}>
                No of Documents
              </Box>
            </Box>
            <Box
              sx={{
                width: "20%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Value Added Services
            </Box>
            <Box
              sx={{
                width: "30%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              consignee name with seal and signature
            </Box>
            <Box sx={{ width: "20%", fontWeight: "bold", fontSize: "12px" }}>
              Cheque in favour of TECH AVIGN PVT LTD
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                width: "30%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "50%",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    widht: "50%",
                    borderRight: "1px solid black",
                    textAligh: "center",
                    p: "5px",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Invoice
                </Box>
                <Box sx={{ widht: "50%" }}>Other</Box>
              </Box>
              <Box></Box>
            </Box>
            <Box sx={{ width: "20%", borderRight: "1px solid black" }}>
              Appointment DeliverySpecial Delivery{" "}
            </Box>
            <Box sx={{ width: "30%", borderRight: "1px solid black" }}></Box>
            <Box sx={{ display: "flex", width: "20%", fontSize: "12px" }}>
              <Box
                sx={{
                  width: "50%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Cheque/ NEFT No
              </Box>
              <Box
                sx={{
                  width: "50%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                {orderDetails.chequeDD}
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                width: "30%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "50%",
                  borderRight: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    widht: "50%",
                    borderRight: "1px solid black",
                    textAligh: "center",
                    p: "5px",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  E Way Bill
                </Box>
                <Box sx={{ widht: "50%" }}></Box>
              </Box>
              <Box></Box>
            </Box>
            <Box sx={{ width: "20%", borderRight: "1px solid black" }}> </Box>
            <Box sx={{ width: "30%", borderRight: "1px solid black" }}></Box>
            <Box sx={{ display: "flex", width: "20%", fontSize: "12px" }}>
              <Box
                sx={{
                  width: "50%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Bank
              </Box>
              <Box
                sx={{
                  width: "50%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                {orderDetails.bankName}
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                width: "30%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "50%",
                }}
              >
                <Box
                  sx={{
                    widht: "50%",
                    textAligh: "center",
                  }}
                ></Box>
                <Box sx={{ widht: "50%" }}></Box>
              </Box>
              <Box></Box>
            </Box>
            <Box
              sx={{
                width: "20%",
                borderRight: "1px solid black",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Name{" "}
            </Box>
            <Box sx={{ width: "30%", borderRight: "1px solid black" }}></Box>
            <Box sx={{ display: "flex", width: "20%", fontSize: "12px" }}>
              <Box
                sx={{
                  width: "50%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Amount
              </Box>
              <Box
                sx={{
                  width: "50%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                {orderDetails.amount}
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                width: "30%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              <Box
                sx={{
                  widht: "100%",
                  borderRight: "1px solid black",
                  textAligh: "center",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Consignor Signature
              </Box>
            </Box>
            <Box
              sx={{
                width: "20%",
                borderRight: "1px solid black",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Pick Up Agent sign{" "}
            </Box>
            <Box
              sx={{
                width: "30%",
                borderRight: "1px solid black",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              {" "}
              Visit www.shiftyourcargo.com
            </Box>
            <Box sx={{ display: "flex", width: "20%", fontSize: "12px" }}>
              <Box
                sx={{
                  width: "100%",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign: "center",
                  p: "5px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Consignor Copy
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfFile;




// import { useState } from "react";
// import about_img from "../../images/about.jpg";
// import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
// import VideoModal from "./VideoModal"; // Adjust the path as per your project structure

// const About = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div
//       style={{
//         padding: "20px",
//         display: "flex",
//         justifyContent: "center",
//         gap: "5%",
//       }}
//     >
//       <div>
//         <img src={about_img} alt="about" />
//         <h2
//           style={{
//             textAlign: "center",
//             padding: "10px",
//             fontWeight: 700,
//             backgroundColor: "#6A43C7",
//             color: "white",
//             margin: 0,
//           }}
//         >
//           10<sup>+</sup> Years Experience
//         </h2>
//       </div>
//       <div style={{ width: "50%" }}>
//         <div>
//           <h5>ABOUT US</h5>
//           <h1>
//             Trusted & Faster Logistic <br /> Service Provider
//           </h1>
//         </div>
//         <div style={{ width: "50%", fontSize: "18px" }}>
//           <p>
//             Dolores lorem lorem ipsum sit et ipsum. Sadip sea amet diam
//             dolore sed et. Sit rebum labore sit sit ut vero no sit. Et elitr
//             stet dolor sed sit et sed ipsum et kasd ut. Erat duo eos et erat
//             sed diam duo
//           </p>
//         </div>
//         <div style={{ width:"120px"}} >
//           <PlayCircleOutlinedIcon
//             sx={{ color: "#6A43C7", mr: "10px",cursor: "pointer" }} onClick={handleOpenModal}
//             className="animate-ping  opacity-75"
//           />{" "}
//           Play Video
//         </div>
//         <VideoModal open={isModalOpen} handleClose={handleCloseModal} />
//       </div>
//     </div>
//   );
// };

// export default About;

import { useState } from "react";
import about_img from "../../images/about.jpg";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import VideoModal from "./VideoModal"; // Adjust the path as per your project structure

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "70px",
        gap: "5%",
        flexDirection: "row", // Default row layout
        alignItems: "flex-start", // Align items at the start of the flex container
        flexWrap: "wrap", // Wrap items if they exceed container width
        textAlign: "center", // Center text within the container
      }}
    >
      <div
        style={{
          flexBasis: "40%", // Set initial width for image section
          minWidth: "250px", // Minimum width for the image section
        }}
      >
        <img
          src={about_img}
          alt="about"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        {/* <h2
          style={{
            textAlign: "center",
            padding: "10px",
            fontWeight: 700,
            backgroundColor: "#6A43C7",
            color: "white",
            margin: 0,
          }}
        >
          10<sup>+</sup> Years Experience
        </h2> */}
      </div>
      <div
        style={{
          flexBasis: "50%", // Set initial width for content section
          minWidth: "300px", // Minimum width for the content section
          textAlign: "center", // Align text to the left within the container
          margin: "auto", // Center the content horizontally on smaller screen
        }}
      >
        <div>
          <h5 style={{ color: "#6A43C7", fontSize: "25px", marginBottom: "15px", fontWeight: 600 }}>ABOUT US</h5>
          <h1 style={{ fontSize: "20px", marginBottom: "15px", fontWeight: 500 }}>
            Trusted & Faster Logistic <br /> Service Provider
          </h1>
        </div>
        <div style={{ fontSize: "16px" }}>
          <p>
            At Shift Your Cargo, we offer reliable logistics and cargo solutions designed to keep your business moving. With extensive industry experience, we provide tailored services for land, air, and sea transport, ensuring timely and secure deliveries every time.
            <br /><br />
            Our global network, advanced technology, and skilled team enable us to deliver fast and cost-effective shipping solutions. From small parcels to large freight, we handle every shipment with care and precision.
            <br /><br />
            We are committed to building strong client relationships through transparency and exceptional customer service. At Shift Your Cargo, your cargo is our priority.
            <br /><br />
            Join us for seamless logistics—where your success is our mission.
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", fontWeight: 600 }}>
          <PlayCircleOutlinedIcon
            sx={{ color: "#6A43C7", mr: "10px", cursor: "pointer" }}
            onClick={handleOpenModal}
            className="animate-ping  opacity-75"
          />{" "}
          Play Video
        </div>
        <VideoModal open={isModalOpen} handleClose={handleCloseModal} />
      </div>
    </div>
  );
};

export default About;
import * as React from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, Outlet, useNavigate } from "react-router-dom";
import company_logo from "../../images/syc-logo.jpg";
import "./appbar.css";
import { toast } from "react-toastify";

function Appbar() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const [userRole, setUserRole] = React.useState(""); 
  const [triggerRender, setTriggerRender] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null); 

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const role = localStorage.getItem("role");

    if (token && email) {
      setIsLoggedIn(true);
      setUserEmail(email);
      setUserRole(role);
    } else {
      setIsLoggedIn(false);
      setUserEmail("");
      setUserRole("");
    }

    const handleStorageChange = () => {
      const updatedToken = localStorage.getItem("token");
      const updatedEmail = localStorage.getItem("email");
      const updatedRole = localStorage.getItem("role");

      if (updatedToken && updatedEmail) {
        setIsLoggedIn(true);
        setUserEmail(updatedEmail);
        setUserRole(updatedRole);
      } else {
        setIsLoggedIn(false);
        setUserEmail("");
        setUserRole("");
      }

      setTriggerRender((prev) => !prev);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    setIsLoggedIn(false);
    setUserEmail("");
    setUserRole(""); 
    navigate("/");
    toast.success("hope you like our services");
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const DrawerList = (
    <Box sx={{ width: 250, textAlign: "center" }} role="presentation">
      <List>
        <Link to="/" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="Home" className="custom-link" />
          </ListItem>
        </Link>
        <Link to="/Service" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="Services" className="custom-link" />
          </ListItem>
        </Link>
        <Link to="/Contact" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="Contact Us" className="custom-link" />
          </ListItem>
        </Link>
        <Link to="/Track" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="Track" className="custom-link" />
          </ListItem>
        </Link>
        <Link to="/pickUpRequest" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="PickUp Request" className="custom-link" />
          </ListItem>
        </Link>
        {isLoggedIn ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ bgcolor: "#000", cursor: "pointer" }}
              onClick={handleMenuOpen}
            >
              {userEmail.charAt(0).toUpperCase()}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem disabled>{userEmail}</MenuItem>
              {userRole === "USER" && ( 
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false);
                    handleMenuClose();
                    navigate("RaiseRequest");
                  }}
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    marginBottom: "5px",
                  }}
                >
                  Raise Pickup Request
                </MenuItem>
                
              )}
              {userRole === "ADMIN" && ( 
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false);
                    handleMenuClose();
                    navigate("/Admin");
                  }} 
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    marginBottom: "5px",
                  }}
                >
                  Admin
                </MenuItem>
              )}
              <MenuItem
                onClick={handleLogout}
                style={{
                  backgroundColor: "red",
                  color: "#fff",
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <Link to="/SignIn">
            <Button
              variant="outlined"
              sx={{
                border: "1px solid black",
                color: "#475466",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#000",
                  color: "#fff",
                  border: "1px solid black",
                },
              }}
            >
              SignIn/SignUp
            </Button>
          </Link>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          height: "85px",
          bgcolor: "#fff",
          boxShadow: "0px 1px rgb(240, 240, 240)",
          p: "0 20px",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box>
              <Link to="/">
                <img
                  width="80px"
                  style={{ borderRadius: "50%", cursor: "pointer" }}
                  src={company_logo}
                  alt="Company Logo"
                />
              </Link>
            </Box>

            <Box
              sx={{
                width: "650px",
                justifyContent: "space-between",
                alignItems: "center",
                display: { xs: "none", md: "flex" },
              }}
            >
              <Link to="/">
                <Button sx={{ color: "#475466" }}>Home</Button>
              </Link>

              <Link to="/Service">
                <Button sx={{ color: "#475466" }}>Services</Button>
              </Link>

              <Link to="/Contact">
                <Button sx={{ color: "#475466" }}>Contact Us</Button>
              </Link>

              <Link to="/Track">
                <Button sx={{ color: "#475466" }}>Track</Button>
              </Link>

              <Link to="RaiseRequest">
                <Button sx={{ color: "#475466" }}>PickUp Request</Button>
              </Link>

              {isLoggedIn ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    sx={{
                      bgcolor: "#000",
                      cursor: "pointer",
                      "&:hover": { bgcolor: "#393b3d" },
                    }}
                    onClick={handleMenuOpen}
                  >
                    {userEmail.charAt(0).toUpperCase()}
                  </Avatar>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem disabled>{userEmail}</MenuItem>
                    {userRole === "USER" && ( 
                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          navigate("RaiseRequest");
                        }} 
                        style={{
                          backgroundColor: "#000",
                          color: "#fff",
                          marginBottom: "5px",
                        }}
                      >
                        Raise Pickup Request
                      </MenuItem>
                    )}
                    {userRole === "ADMIN" && ( 
                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          navigate("/Admin");
                        }} 
                        style={{
                          backgroundColor: "#000",
                          color: "#fff",
                          marginBottom: "5px",
                        }}
                      >
                        Admin
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={handleLogout}
                      style={{
                        backgroundColor: "red",
                        color: "#fff",
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <Link to="/SignIn">
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid black",
                      color: "#475466",
                      textTransform: "none",
                      "&:hover": {
                        bgcolor: "#000",
                        color: "#fff",
                        border: "1px solid black",
                      },
                    }}
                  >
                    SignIn/SignUp
                  </Button>
                </Link>
              )}
            </Box>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Button onClick={toggleDrawer(true)}>
              <MenuIcon sx={{ color: "#6A43C7" }} />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              {DrawerList}
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>

      <Outlet />
    </div>
  );
}

export default Appbar;


/* 
import * as React from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, Outlet, useNavigate } from "react-router-dom";
import company_logo from "../../images/syc-logo.jpg";
import "./appbar.css";
import { toast } from "react-toastify";

function Appbar() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const [userRole, setUserRole] = React.useState(""); // New state for user role
  const [triggerRender, setTriggerRender] = React.useState(false); // New state to trigger re-render
  const [anchorEl, setAnchorEl] = React.useState(null); // State for menu anchor

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const role = localStorage.getItem("role");

    if (token && email) {
      setIsLoggedIn(true);
      setUserEmail(email);
      setUserRole(role); // Set the user role
    } else {
      setIsLoggedIn(false);
      setUserEmail("");
      setUserRole(""); // Reset the user role
    }

    const handleStorageChange = () => {
      const updatedToken = localStorage.getItem("token");
      const updatedEmail = localStorage.getItem("email");
      const updatedRole = localStorage.getItem("role");

      if (updatedToken && updatedEmail) {
        setIsLoggedIn(true);
        setUserEmail(updatedEmail);
        setUserRole(updatedRole); // Update the user role
      } else {
        setIsLoggedIn(false);
        setUserEmail("");
        setUserRole(""); // Reset the user role
      }

      setTriggerRender((prev) => !prev); // Force re-render
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Helper function to check token expiry
  const isTokenExpired = (token) => {
    if (!token) return true;
    const [, payload] = token.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const expiryDate = decodedPayload.exp * 1000; // Convert to milliseconds
    return Date.now() > expiryDate;
  };

  // Function to periodically check token validity
  const checkTokenValidity = () => {
    const token = localStorage.getItem("token");
    if (isTokenExpired(token)) {
      handleLogout();
    }
  };

  // Set an interval to check the token every minute
  React.useEffect(() => {
    const interval = setInterval(checkTokenValidity, 60000); // Check every 60 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("role"); // Remove the user role
    setIsLoggedIn(false);
    setUserEmail("");
    setUserRole(""); // Reset the user role
    navigate("/");
    toast.success("hope you like our services");
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const DrawerList = (
    <Box sx={{ width: 250, textAlign: "center" }} role="presentation">
      <List>
        <Link to="/" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="Home" className="custom-link" />
          </ListItem>
        </Link>
        <Link to="/Service" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="Services" className="custom-link" />
          </ListItem>
        </Link>
        <Link to="/Contact" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="Contact Us" className="custom-link" />
          </ListItem>
        </Link>
        <Link to="/Track" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="Track" className="custom-link" />
          </ListItem>
        </Link>
        <Link to="/pickUpRequest" className="custom-link">
          <ListItem onClick={toggleDrawer(false)}>
            <ListItemText primary="PickUp Request" className="custom-link" />
          </ListItem>
        </Link>
        {isLoggedIn ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ bgcolor: "#000", cursor: "pointer" }}
              onClick={handleMenuOpen}
            >
              {userEmail.charAt(0).toUpperCase()}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem disabled>{userEmail}</MenuItem>
              {userRole === "USER" && ( 
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false);
                    handleMenuClose();
                    navigate("RaiseRequest");
                  }} // Navigate to the Admin page
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    marginBottom: "5px",
                  }}
                >
                  Raise Pickup Request
                </MenuItem>
                
              )}
              {userRole === "ADMIN" && ( 
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false);
                    handleMenuClose();
                    navigate("/Admin");
                  }} 
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    marginBottom: "5px",
                  }}
                >
                  Admin
                </MenuItem>
              )}
              <MenuItem
                onClick={handleLogout}
                style={{
                  backgroundColor: "red",
                  color: "#fff",
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <Link to="/SignIn">
            <Button
              variant="outlined"
              sx={{
                border: "1px solid black",
                color: "#475466",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#000",
                  color: "#fff",
                  border: "1px solid black",
                },
              }}
            >
              SignIn/SignUp
            </Button>
          </Link>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          height: "85px",
          bgcolor: "#fff",
          boxShadow: "0px 1px rgb(240, 240, 240)",
          p: "0 20px",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box>
              <Link to="/">
                <img
                  width="80px"
                  style={{ borderRadius: "50%", cursor: "pointer" }}
                  src={company_logo}
                  alt="Company Logo"
                />
              </Link>
            </Box>

            <Box
              sx={{
                width: "650px",
                justifyContent: "space-between",
                alignItems: "center",
                display: { xs: "none", md: "flex" },
              }}
            >
              <Link to="/">
                <Button sx={{ color: "#475466" }}>Home</Button>
              </Link>

              <Link to="/Service">
                <Button sx={{ color: "#475466" }}>Services</Button>
              </Link>

              <Link to="/Contact">
                <Button sx={{ color: "#475466" }}>Contact Us</Button>
              </Link>

              <Link to="/Track">
                <Button sx={{ color: "#475466" }}>Track</Button>
              </Link>

              <Link to="RaiseRequest">
                <Button sx={{ color: "#475466" }}>PickUp Request</Button>
              </Link>

              {isLoggedIn ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    sx={{
                      bgcolor: "#000",
                      cursor: "pointer",
                      "&:hover": { bgcolor: "#393b3d" },
                    }}
                    onClick={handleMenuOpen}
                  >
                    {userEmail.charAt(0).toUpperCase()}
                  </Avatar>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem disabled>{userEmail}</MenuItem>
                    {userRole === "USER" && ( 
                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          navigate("RaiseRequest");
                        }} 
                        style={{
                          backgroundColor: "#000",
                          color: "#fff",
                          marginBottom: "5px",
                        }}
                      >
                        Raise Pickup Request
                      </MenuItem>
                    )}
                    {userRole === "ADMIN" && ( 
                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          navigate("/Admin");
                        }} 
                        style={{
                          backgroundColor: "#000",
                          color: "#fff",
                          marginBottom: "5px",
                        }}
                      >
                        Admin
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={handleLogout}
                      style={{
                        backgroundColor: "red",
                        color: "#fff",
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <Link to="/SignIn">
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid black",
                      color: "#475466",
                      textTransform: "none",
                      "&:hover": {
                        bgcolor: "#000",
                        color: "#fff",
                        border: "1px solid black",
                      },
                    }}
                  >
                    SignIn/SignUp
                  </Button>
                </Link>
              )}
            </Box>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Button onClick={toggleDrawer(true)}>
              <MenuIcon sx={{ color: "#6A43C7" }} />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              {DrawerList}
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>

      <Outlet />
    </div>
  );
}

export default Appbar;

*/
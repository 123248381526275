import {
  Accordion,
  AccordionActions,
  AccordionSummary,
} from "@mui/material";
import "./adminLayout.css";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaTruckPickup } from "react-icons/fa6";
import { Link, Outlet } from "react-router-dom";

const AdminLayout = () => {
  return (
    
    <div style={{ height: "1200px" }} className="admin-container">
      <div className="admin-sidebar">
        <div className="admin-sidebar-links">
          <Accordion sx={{ bgcolor: "rgb(245, 245, 245)" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <LocalShippingIcon
                sx={{
                  mr: "5px",
                  color: "black",
                  borderRadius: "50%",
                  bgcolor: "white",
                  p: "3px",
                }}
              />{" "}
              Orders
            </AccordionSummary>
            <AccordionActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link to=''>All Orders</Link>
              <Link to="createNewOrder" >Create New Order</Link>
              <Link to="CreateBulkOrder" >Create Bulk Order</Link>
            </AccordionActions>
          </Accordion>

          <Accordion sx={{ bgcolor: "rgb(245, 245, 245)" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ManageAccountsIcon
                sx={{
                  mr: "5px",
                  color: "black",
                  borderRadius: "50%",
                  bgcolor: "white",
                  p: "3px",
                }}
              />{" "}
              Accounts
            </AccordionSummary>
            <AccordionActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link to="customers" >Customers</Link>
              <Link to="Invoices" >Invoices</Link>
              <Link to="Expenses" >Expenses</Link>
            </AccordionActions>
          </Accordion>

          <Accordion sx={{ bgcolor: "rgb(245, 245, 245)" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ManageAccountsIcon
                sx={{
                  mr: "5px",
                  color: "black",
                  borderRadius: "50%",
                  bgcolor: "white",
                  p: "3px",
                }}
              />{" "}
              Pickup Locations
            </AccordionSummary>
            <AccordionActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link to='WareHouses'>Manage Warehouses</Link>
            </AccordionActions>
          </Accordion>

          <Accordion sx={{ bgcolor: "rgb(245, 245, 245)" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FaTruckPickup
                style={{
                  
                  marginRight: "5px",
                  color: "black",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  padding: "3px",
                }}
                size={22}
              />{" "}
              PickUp Request
            </AccordionSummary>
            <AccordionActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link to='pickUpRequest'>Pick Up Request</Link>
            </AccordionActions>
          </Accordion>


        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminLayout;




// import React, { useState } from "react";
// import {
//   Accordion,
//   AccordionActions,
//   AccordionSummary,
//   IconButton,
//   Box,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Link, Outlet } from "react-router-dom";
// import "./adminLayout.css";

// const AdminLayout = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <div style={{ height: "1200px" }} className="admin-container">
//       <IconButton
//         onClick={toggleSidebar}
//         sx={{ 
//           position: "fixed", // fixed position so it's always visible
//           top: "100px", 
//           left: "10px",
//         }}
//       >
//         <MenuIcon />
//       </IconButton>
//       {isSidebarOpen && (
//         <div className="admin-sidebar">
//           <div className="admin-sidebar-links">
//             <Accordion sx={{ bgcolor: "rgb(245, 245, 245)" }}>
//               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                 <LocalShippingIcon
//                   sx={{
//                     mr: "5px",
//                     color: "black",
//                     borderRadius: "50%",
//                     bgcolor: "white",
//                     p: "3px",
//                   }}
//                 />{" "}
//                 Orders
//               </AccordionSummary>
//               <AccordionActions sx={{ display: "flex", flexDirection: "column" }}>
//                 <Link to="">All Orders</Link>
//                 <Link to="createNewOrder">Create New Order</Link>
//                 <Link to="CreateBulkOrder">Create Bulk Order</Link>
//               </AccordionActions>
//             </Accordion>

//             <Accordion sx={{ bgcolor: "rgb(245, 245, 245)" }}>
//               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                 <ManageAccountsIcon
//                   sx={{
//                     mr: "5px",
//                     color: "black",
//                     borderRadius: "50%",
//                     bgcolor: "white",
//                     p: "3px",
//                   }}
//                 />{" "}
//                 Accounts
//               </AccordionSummary>
//               <AccordionActions sx={{ display: "flex", flexDirection: "column" }}>
//                 <Link to="customers">Customers</Link>
//                 <Link to="Invoices">Invoices</Link>
//                 <Link to="Expenses">Expenses</Link>
//               </AccordionActions>
//             </Accordion>

//             <Accordion sx={{ bgcolor: "rgb(245, 245, 245)" }}>
//               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                 <ManageAccountsIcon
//                   sx={{
//                     mr: "5px",
//                     color: "black",
//                     borderRadius: "50%",
//                     bgcolor: "white",
//                     p: "3px",
//                   }}
//                 />{" "}
//                 Pickup Locations
//               </AccordionSummary>
//               <AccordionActions sx={{ display: "flex", flexDirection: "column" }}>
//                 <Link to="WareHouses">Manage Warehouses</Link>
//               </AccordionActions>
//             </Accordion>
//           </div>
//         </div>
//       )}
//       <Box sx={{ marginLeft: isSidebarOpen ? "250px" : "0", transition: "margin-left 0.3s" }}>
//         <Outlet />
//       </Box>
//     </div>
//   );
// };

// export default AdminLayout;

import React from 'react'
import { Link } from 'react-router-dom'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const PageNotFound = () => {
  return (
    <div style={{
      display:"flex", 
      flexDirection:"column",
      alignItems:"center"
    }}>
      
        <h1>Page Not Found..</h1>
        <p>Go back to HomePage <ArrowRightAltIcon/> <Link to='/' style={{color:'red'}}> <u>Home</u></Link></p>
    </div>
  )
}

export default PageNotFound
import React from 'react';
import './termsConditions.css'

const TermsAndConditions = () => {
  return (
    <div className='container' style={{ fontSize: "10px" }}>
      <h1 style={{ fontSize: "20px", marginBottom: "20px" }}>Terms & Conditions</h1>
      <h3 style={{ fontSize: "15px", marginBottom: "20px" }}>Last updated on 24-08-2024</h3>

      <p style={{ marginBottom: "10px", fontSize: "12px" }}>
        Welcome to <span className='font-bold text-[13px]'>Shift Your Cargo or Tech Avign Pvt Ltd or TAPL </span> ("we," "us," "our"). By using our services, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>1. Acceptance of Terms</h4>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        By accessing and using our website, mobile application, or any of our services, you ("Customer," "User," "you," or "your") agree to these Terms and Conditions, our Privacy Policy, and any additional terms applicable to specific services. If you do not agree to these terms, you may not use our services.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>2. Services Provided</h4>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        Shift Your Cargo provides cargo transportation, storage, and related logistics services. The scope and nature of our services are described on our website and mobile application. We reserve the right to modify, suspend, or discontinue any part of our services at any time without prior notice.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>3. User Responsibilities</h4>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>3.1. Accurate Information</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        You agree to provide accurate, current, and complete information when using our services, including but not limited to personal details, cargo details, and payment information. You are responsible for updating this information as necessary to ensure its accuracy.
      </p>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>3.2. Compliance with Laws</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        You agree to use our services in compliance with all applicable laws and regulations. You may not use our services for any illegal or unauthorized purpose, including the transportation of prohibited items.
      </p>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>3.3. Account Security</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        If you create an account with us, you are responsible for maintaining the confidentiality of your account credentials. You are responsible for all activities that occur under your account. Notify us immediately of any unauthorized use of your account.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>4. Booking and Payment</h4>
      <h5 style={{ fontSize: "12px", marginLeft: "25px", marginBottom: "10px" }}>4.1. Booking Process</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        To use our services, you must book through our website or mobile application. Once your booking is confirmed, you will receive a confirmation email with the details of your service.
      </p>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>4.2. Payment Terms</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        All payments must be made in accordance with the terms outlined during the booking process. We accept [List Payment Methods]. Payments must be made in full before the service is rendered unless otherwise agreed upon.
      </p>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>4.3. Pricing</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        Prices for our services are subject to change without notice. The price applicable to your service will be the price in effect at the time of booking.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>5. Cancellations and Refunds</h4>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        Our Cancellation and Refund Policy governs the terms under which cancellations and refunds are handled. Please review this policy [Link to Cancellation and Refund Policy] for details.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>6. Cargo Responsibilities</h4>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>6.1. Packaging</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        You are responsible for ensuring that your cargo is adequately packaged to withstand the rigors of transportation. We are not liable for damage to cargo that results from inadequate or improper packaging.
      </p>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>6.2. Prohibited Items</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        You agree not to include any prohibited items in your cargo. Prohibited items include, but are not limited to, hazardous materials, illegal substances, perishable goods, and items that are illegal to transport under applicable laws.
      </p>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>6.3. Inspection</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        We reserve the right to inspect cargo at any time to ensure compliance with these Terms and Conditions. If prohibited items are found, we may refuse service, and you may be liable for any damages or legal consequences that arise.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>7. Liability</h4>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>7.1. Limitation of Liability</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        To the fullest extent permitted by law, Shift Your Cargo shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses resulting from your use of our services.
      </p>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>7.2. Liability for Cargo</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        Our liability for loss or damage to cargo is limited to the amount specified in our [Insurance Policy/Terms of Service]. We are not liable for any loss or damage caused by factors beyond our control, including but not limited to natural disasters, acts of war, or government actions.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>8. Dispute Resolution</h4>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>8.1. Governing Law</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        These Terms and Conditions shall be governed by and construed in accordance with the laws of Bangalore jurisdiction.
      </p>
      <h5 style={{ fontSize: "12px", marginBottom: "10px", marginLeft: "25px", }}>8.2. Arbitration</h5>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        Any disputes arising out of or relating to these Terms and Conditions or our services shall be resolved through binding arbitration in accordance with the rules of [Insert Arbitration Organization]. The arbitration shall take place in [Insert Location], and the arbitrator’s decision shall be final and binding.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>9. Termination</h4>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        We reserve the right to terminate or suspend your access to our services at our discretion, without notice, if you violate these Terms and Conditions or engage in conduct that we deem harmful to our interests or the interests of other users.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>10. Changes to Terms and Conditions</h4>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        We may update these Terms and Conditions from time to time. Any changes will be posted on this page with an updated "Effective Date." Your continued use of our services after any changes constitutes your acceptance of the new terms.
      </p>

      <h4 style={{ fontSize: "13px", marginBottom: "10px" }}>11. Contact Information</h4>
      <p style={{ marginBottom: "10px", marginLeft: "25px", }}>
        If you have any questions or concerns about these Terms and Conditions, please contact us at:
      </p>
      <p style={{ marginBottom: "10px" }}>
        <strong>Shift Your Cargo or Tech Avign Pvt Ltd or TAPL</strong><br />
        Email: contact@shiftyourcargo.com<br />
        Phone: +91 9900 2525 85
        <br />
        Address: Shift Your Cargo - No 59, 2nd Main, Rukmini Nagar, Nagasandra post, Bangalore - 560073
      </p>
    </div>
  );
}

export default TermsAndConditions;

// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";


// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

// import "./styles.css";

// // import required modules
// import { Autoplay, Pagination } from "swiper/modules";

// export default function Slider() {
//   return (
//     <>
//      <div className="swiper-container">
//       <h2 style={{ color: "grey", textAlign: "center" }}>
//         Trusted by the best companies
//       </h2>
//       <Swiper
//         slidesPerView={3}
//         spaceBetween={30}
//         autoplay={{
//           delay: 2500,
//           disableOnInteraction: false,
//         }}
//         pagination={{
//           clickable: true,
//         }}
//         modules={[Autoplay, Pagination]}
//         className="mySwiper"
//       >
//         <SwiperSlide>BAMC Hydraulics Pvt Ltd</SwiperSlide>
//         <SwiperSlide>Componix</SwiperSlide>
//         <SwiperSlide>Js Equipments</SwiperSlide>
//         <SwiperSlide>Oncrop</SwiperSlide>
//         <SwiperSlide>General Aeronautics</SwiperSlide>
//       </Swiper>
//     </div>
//     </>
//   );
// }
// Import Swiper React components

// import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

// Import required modules
// import { Autoplay, Pagination } from "swiper/modules";

// import "./styles.css";

// export default function Slider() {
//   return (
//     <div className="swiper-container">
//       <h2 style={{ color: "grey", textAlign: "center", fontSize:"25px", fontWeight:600 }}>
//         Trusted by the best companies
//       </h2>
//       <Swiper
//         slidesPerView={3}
//         spaceBetween={30}
//         autoplay={{
//           delay: 2500,
//           disableOnInteraction: false,
//         }}
//         pagination={{
//           clickable: true,
//         }}
//         breakpoints={{
//           0: {
//             slidesPerView: 1,
//             spaceBetween: 10,
//           },
//           320: {
//             slidesPerView: 2,
//             spaceBetween: 10,
//           },
//           768: {
//             slidesPerView: 3,
//             spaceBetween: 30,
//           },
//         }}
//         modules={[Autoplay, Pagination]}
//         className="mySwiper"
//       >
//         <SwiperSlide>BAMC Hydraulics Pvt Ltd</SwiperSlide>
//         <SwiperSlide>Componix</SwiperSlide>
//         <SwiperSlide>Js Equipments</SwiperSlide>
//         <SwiperSlide>Oncrop</SwiperSlide>
//         <SwiperSlide>General Aeronautics</SwiperSlide>
//       </Swiper>
//     </div>
//   );
// }


import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BAMC from "../../images/sliderLogos/BAMC.webp"
import Ampsware from "../../images/sliderLogos/ampsware-logo-120x120.webp"
import Arteo from "../../images/sliderLogos/arteo.webp"
import BSS from "../../images/sliderLogos/BSS.png"
import Coatings from "../../images/sliderLogos/coatings_and_coatings_logo.jpeg"
import Dreamfly from "../../images/sliderLogos/dreamFly.webp"
import Duratech from "../../images/sliderLogos/duraTech.png"
import Epsilon from "../../images/sliderLogos/epsilon-threads-logo.png"
import GeneralAero from "../../images/sliderLogos/generalAerospace.png"
import Gmats from "../../images/sliderLogos/gmats.png"
import GVK from "../../images/sliderLogos/GVK.jpeg"
import MakeYourOwnPerfume from "../../images/sliderLogos/makeYourOwnPerfume.avif"
import MaxLite from "../../images/sliderLogos/MAXLITE-LOGO-CHANGE-Grey-Final.png"
import NephroCare from "../../images/sliderLogos/nephroCare.svg"
import PanElectronics from "../../images/sliderLogos/panElectronics.webp"
import Pentawis from "../../images/sliderLogos/pentawis.png"
import PlanItDesigns from "../../images/sliderLogos/planItDesigns.jpg"
import PremierPolymers from "../../images/sliderLogos/premier-polymers-120x120.jpg"
import PSM from "../../images/sliderLogos/PSM.avif"
import Robotronicx from "../../images/sliderLogos/robotronicx_logo.jpeg"
import SriMiriNutrition from "../../images/sliderLogos/srimiriNutrition.png"
import Tevah from "../../images/sliderLogos/tevahAutomations.png"
import WandH from "../../images/sliderLogos/w&h.png"

const LogoSlider = () => {
  const logos = [
    { id: 1, name: "Arteo Luxury Pvt Ltd", src: Arteo },
    { id: 2, name: "BSS Corporations", src: BSS },
    { id: 3, name: "General Aeronautics", src: GeneralAero },
    { id: 4, name: "W&H Dental India Pvt Ltd", src: WandH },
    { id: 5, name: "Coatings and Coatings India Pvt Ltd", src: Coatings },
    { id: 6, name: "Dreamfly Innovations", src: Dreamfly },
    { id: 7, name: "Duratech Furnitures LLP", src: Duratech },
    { id: 8, name: "Epsilon Threads", src: Epsilon },
    { id: 9, name: "Tevah Automations Pvt Ltd", src: Tevah },
    { id: 10, name: "GMATS Machineries", src: Gmats },
    { id: 11, name: "GVK Power and Engineering Solutions", src: GVK },
    { id: 12, name: "Make Your Own Perfume", src: MakeYourOwnPerfume },
    { id: 13, name: "MAXLITE AAC BLOCKES IND PVT LTD", src: MaxLite },
    { id: 14, name: "Nephrocare Health Services Pvt Ltd", src: NephroCare },
    { id: 15, name: "PAN Electronics India Ltd", src: PanElectronics },
    { id: 16, name: "Pentawis Innovations Pvt Ltd", src: Pentawis },
    { id: 17, name: "Plan It Designs Pvt Ltd", src: PlanItDesigns },
    { id: 18, name: "Premier Polymers", src: PremierPolymers },
    { id: 19, name: "PSM Scientific Instruments Pvt Ltd", src: PSM },
    { id: 20, name: "Robotronicx Systems Private Limited", src: Robotronicx },
    { id: 21, name: "Sirimiri Nutrition Food Products Pvt Ltd", src: SriMiriNutrition },
    { id: 22, name: "BAMC Hydraulics Pvt Ltd", src: BAMC },
    { id: 23, name: "Ampsware Manufacturing", src: Ampsware },
  ];

  const settings = {
    infinite: true,
    speed: 10000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <h2 style={{ color: "grey", textAlign: "center", fontSize: "25px", marginBottom: "30px", fontWeight: 600 }}>
        Trusted by the best companies
      </h2>
      <div className="p-[20px]">
        <Slider {...settings}>
          {logos.map((logo) => (
            <div key={logo.id} style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <img src={logo.src} alt={logo.name} className={` ${logo.id === 3 || logo.id===12 ? "bg-gray-700 px-[10px]": ""} max-w-[100px] h-[70px] w-[100px] object-contain`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default LogoSlider;

import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";

const BasicDetails = ({ basicDetails, setBasicDetails }) => {
  const handleChange = (e) => {
    setBasicDetails({
      ...basicDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Basic Details
      </Typography>
      <Grid container spacing={2}>
        {/* Repeat for each field */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            LR Number
          </Typography>
          <TextField
            fullWidth
            name="trackingNumber"
            value={basicDetails.trackingNumber}
            onChange={handleChange}
            id="lrnumber"
            variant="outlined"
            size="small"
            sx={{ width: "70%" }}
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            Booking Date
          </Typography>
          <TextField
            fullWidth
            name="bookingDate"
            id="bookingdate"
            type="date"
            variant="outlined"
            size="small"
            required
            value={basicDetails.bookingDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "70%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            Origin
          </Typography>
          <TextField
            fullWidth
            name="origin"
            id="origin"
            variant="outlined"
            size="small"
            required
            value={basicDetails.origin}
            onChange={handleChange}
            sx={{ width: "70%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            Time
          </Typography>
          <TextField
            fullWidth
            name="time"
            id="time"
            type="time"
            size="small"
            variant="outlined"
            required
            value={basicDetails.time}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "70%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            Destination
          </Typography>
          <TextField
            fullWidth
            name="destination"
            id="destination"
            variant="outlined"
            size="small"
            required
            value={basicDetails.destination}
            onChange={handleChange}
            sx={{ width: "70%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            PUR Reference No
          </Typography>
          <TextField
            fullWidth
            name="reference"
            id="reference"
            size="small"
            variant="outlined"
            required
            value={basicDetails.reference}
            onChange={handleChange}
            sx={{ width: "70%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicDetails;



// import { Box, Grid, TextField, Typography } from "@mui/material";
// import React from "react";

// const BasicDetails = ({ basicDetails, setBasicDetails }) => {
//   const handleChange = (e) => {
//     setBasicDetails({
//       ...basicDetails,
//       [e.target.name]: e.target.value,
//     });
//   };

//   return (
//     <Box sx={{ width: "100%", mt: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         Basic Details
//       </Typography>
//       <Grid container spacing={2}>
//         {[
//           { label: "LR Number", name: "trackingNumber", type: "text", id: "lrnumber" },
//           { label: "Booking Date", name: "bookingDate", type: "date", id: "bookingdate" },
//           { label: "Origin", name: "origin", type: "text", id: "origin" },
//           { label: "Time", name: "time", type: "time", id: "time" },
//           { label: "Destination", name: "destination", type: "text", id: "destination" },
//           { label: "PUR Reference No", name: "reference", type: "text", id: "reference" }
//         ].map(({ label, name, type, id }) => (
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             sx={{ display: "flex", alignItems: "center" }}
//             key={id}
//           >
//             <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//               {label}
//             </Typography>
//             <TextField
//               fullWidth
//               name={name}
//               id={id}
//               type={type}
//               variant="outlined"
//               size="small"
//               required
//               value={basicDetails[name]}
//               onChange={handleChange}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               sx={{ width: "100%" }}
//             />
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//   );
// };

// export default BasicDetails;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   Button,
// } from "@mui/material";
// import { toast } from "react-toastify";
// import BackEndUrl from "../../../api.js"

// const AllPickUpRequests = () => {
//   const [pickupRequests, setPickupRequests] = useState([]);

//   useEffect(() => {
//     const fetchPickupRequests = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         const response = await axios.get(
//           `${BackEndUrl}/api/pickUpRequests`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setPickupRequests(response.data);
//       } catch (error) {
//         console.error("Error fetching pickup requests:", error);
//         toast.error("Failed to fetch pickup requests");
//       }
//     };

//     fetchPickupRequests();
//   }, []);

//   const handleRemoveRequest = async (id) => {
//     try {
//       const token = localStorage.getItem("token");
//       await axios.delete( `${BackEndUrl}/api/pickUpRequest/${id}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setPickupRequests(pickupRequests.filter((request) => request.id !== id));
//       toast.success("Pickup request removed successfully");
//     } catch (error) {
//       console.error("Error removing pickup request:", error);
//       toast.error("Failed to remove pickup request");
//     }
//   };

//   return (
//     <Box>
//       <Typography variant="h6" sx={{ marginBottom: "16px" }}>
//         All Pickup Requests
//       </Typography>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           mt: 4,
//         }}
//       >
//         <TableContainer component={Paper}>
//           <Table sx={{ minWidth: 650 }} aria-label="pickup requests table">
//             <TableHead>
//               <TableRow>
//                 {/* <TableCell align="center">ID</TableCell> */}
//                 <TableCell align="center">
//                   <b>CompanyName/Code</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Source Address</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Name</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Mobile Number</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Source Pin Code</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Destination Pin Code</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Expected Packages</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Approximate Weight</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Date</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Time</b>
//                 </TableCell>
//                 <TableCell align="center">
//                   <b>Actions</b>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {pickupRequests.slice().reverse().map((request) => (
//                 <TableRow key={request.id}>
//                   {/* <TableCell align="center">{request.id}</TableCell> */}
//                   <TableCell align="center">{request.companyName}</TableCell>
//                   <TableCell align="center">{request.name}</TableCell>
//                   <TableCell align="center">{request.mobileNumber}</TableCell>
//                   <TableCell align="center">{request.sourcePin}</TableCell>
//                   <TableCell align="center">{request.destinationPin}</TableCell>
//                   <TableCell align="center">{request.address}</TableCell>
//                   <TableCell align="center">
//                     {request.expectedPackages}
//                   </TableCell>
//                   <TableCell align="center">{request.approximateWeight}</TableCell>
//                   <TableCell align="center">{request.date}</TableCell>
//                   <TableCell align="center">{request.time}</TableCell>
//                   <TableCell align="center">
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={() => handleRemoveRequest(request.id)}
//                     >
//                       Remove
//                     </Button>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Box>
//   );
// };

// export default AllPickUpRequests;


import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import BackEndUrl from "../../../api.js";

const AllPickUpRequests = () => {
  const [pickupRequests, setPickupRequests] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [requestIdToRemove, setRequestIdToRemove] = useState(null);

  useEffect(() => {
    const fetchPickupRequests = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${BackEndUrl}/api/pickUpRequests`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPickupRequests(response.data);
      } catch (error) {
        console.error("Error fetching pickup requests:", error);
        toast.error("Failed to fetch pickup requests");
      }
    };

    fetchPickupRequests();
  }, []);

  const handleOpenDialog = (id) => {
    setRequestIdToRemove(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setRequestIdToRemove(null);
  };

  const handleRemoveRequest = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${BackEndUrl}/api/pickUpRequest/${requestIdToRemove}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPickupRequests(pickupRequests.filter((request) => request.id !== requestIdToRemove));
      toast.success("Pickup request removed successfully");
      handleCloseDialog();
    } catch (error) {
      console.error("Error removing pickup request:", error);
      toast.error("Failed to remove pickup request");
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: "16px" }}>
        All Pickup Requests
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="pickup requests table">
            <TableHead>
              <TableRow>
              <TableCell align="center">
                  <b>Id</b>
                </TableCell>
                <TableCell align="center">
                  <b>CompanyName/Code</b>
                </TableCell>
                <TableCell align="center">
                  <b>Source Address</b>
                </TableCell>
                <TableCell align="center">
                  <b>Name</b>
                </TableCell>
                <TableCell align="center">
                  <b>Mobile Number</b>
                </TableCell>
                <TableCell align="center">
                  <b>Source Pin Code</b>
                </TableCell>
                <TableCell align="center">
                  <b>Destination Pin Code</b>
                </TableCell>
                <TableCell align="center">
                  <b>Expected Packages</b>
                </TableCell>
                <TableCell align="center">
                  <b>Approximate Weight</b>
                </TableCell>
                <TableCell align="center">
                  <b>Date</b>
                </TableCell>
                <TableCell align="center">
                  <b>Time</b>
                </TableCell>
                <TableCell align="center">
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pickupRequests.slice().reverse().map((request) => (
                <TableRow key={request.id}>
                  <TableCell align="center">{request.id}</TableCell>
                  <TableCell align="center">{request.companyName}</TableCell>
                  <TableCell align="center">{request.name}</TableCell>
                  <TableCell align="center">{request.mobileNumber}</TableCell>
                  <TableCell align="center">{request.sourcePin}</TableCell>
                  <TableCell align="center">{request.destinationPin}</TableCell>
                  <TableCell align="center">{request.address}</TableCell>
                  <TableCell align="center">{request.expectedPackages}</TableCell>
                  <TableCell align="center">{request.approximateWeight}</TableCell>
                  <TableCell align="center">{request.date}</TableCell>
                  <TableCell align="center">{request.time}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleOpenDialog(request.id)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Remove</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this pickup request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} size="small" sx={{color:"black","&:hover":{bgcolor:"black", color:"white"}}}>Cancel</Button>
          <Button onClick={handleRemoveRequest} color="error"  size="small" sx={{"&:hover":{bgcolor:"red", color:"white"}}}>Remove</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AllPickUpRequests;

import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";

const ShippingMethod = ({ shippingMethod, setShippingMethod, setSurfaceOption, setAirOption }) => {
  const handleShippingMethodChange = (event) => {
    setShippingMethod(event.target.value);
  };

  return (
    <Box>
      <Box sx={{ width: "40%", mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
          Shipping Method
        </Typography>

        <RadioGroup
          aria-label="shippingMethod"
          name="shippingMethod"
          row
          value={shippingMethod}
          onChange={handleShippingMethodChange}
        >
          <FormControlLabel
            value="surface"
            control={<Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />}
            label="Surface"
            labelPlacement="end"
            required
          />
          <FormControlLabel
            value="air"
            control={<Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />}
            label="Air"
            labelPlacement="end"
            required
          />
        </RadioGroup>
      </Box>
      {shippingMethod === "surface" && (
        <Box sx={{ width: "40%", textAlign: "center", mt: 1 }}>
          <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
            Surface Shipping Options
          </Typography>
          <RadioGroup
            aria-label="surfaceOptions"
            name="surfaceOptions"
            row
            sx={{ ml: "20%" }}
            onChange={(e) => setSurfaceOption(e.target.value)}
          >
            <FormControlLabel
              value="express"
              control={<Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />}
              label="Express"
              labelPlacement="end"
              required
            />
            <FormControlLabel
              value="regular"
              control={<Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />}
              label="Regular"
              labelPlacement="end"
              required
            />
          </RadioGroup>
        </Box>
      )}
      {shippingMethod === "air" && (
        <Box sx={{ width: "40%", mt: 1, textAlign: "center", justifyContent: "center" }}>
          <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
            Air Shipping Options
          </Typography>
          <RadioGroup
            aria-label="airOptions"
            name="airOptions"
            row
            sx={{ ml: "20%" }}
            onChange={(e) => setAirOption(e.target.value)}
          >
            <FormControlLabel
              value="oneday"
              control={<Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />}
              label="1 Day Service"
              labelPlacement="end"
              required
            />
            <FormControlLabel
              value="regular"
              control={<Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />}
              label="Regular"
              labelPlacement="end"
              required
            />
          </RadioGroup>
        </Box>
      )}
    </Box>
  );
};

export default ShippingMethod;




// import {
//   Box,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   Typography,
// } from "@mui/material";
// import React, { useState } from "react";

// const ShippingMethod = () => {
//   const [shippingMethod, setShippingMethod] = useState("");

//   const handleShippingMethodChange = (event) => {
//     setShippingMethod(event.target.value);
//   };

//   return (
//     <Box>
//       <Box
//         sx={{
//           width: "40%",
//           mt: 2,
//           display: "flex",
//           justifyContent: "space-between",
//         }}
//       >
//         <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
//           Shipping Method
//         </Typography>

//         <RadioGroup
//           aria-label="shippingMethod"
//           name="shippingMethod"
//           row
//           value={shippingMethod}
//           onChange={handleShippingMethodChange}
//         >
//           <FormControlLabel
//             value="surface"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="Surface"
//             labelPlacement="end"
//             required
//           />

//           <FormControlLabel
//             value="air"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="Air"
//             labelPlacement="end"
//             required
//           />
//         </RadioGroup>
//       </Box>
//       {shippingMethod === "surface" && (
//         <Box sx={{ width: "40%", textAlign: "center", mt: 1 }}>
//           <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
//             Surface Shipping Options
//           </Typography>
//           <RadioGroup
//             aria-label="surfaceOptions"
//             name="surfaceOptions"
//             row
//             sx={{ ml: "20%" }}
//           >
//             <FormControlLabel
//               value="express"
//               control={
//                 <Radio
//                   sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }}
//                 />
//               }
//               label="Express"
//               labelPlacement="end"
//               required
//             />

//             <FormControlLabel
//               value="regular"
//               control={
//                 <Radio
//                   sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }}
//                 />
//               }
//               label="Regular"
//               labelPlacement="end"
//               required
//             />
//           </RadioGroup>
//         </Box>
//       )}
//       {shippingMethod === "air" && (
//         <Box
//           sx={{
//             width: "40%",
//             mt: 1,
//             textAlign: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
//             Air Shipping Options
//           </Typography>
//           <RadioGroup
//             aria-label="airOptions"
//             name="airOptions"
//             row
//             sx={{ ml: "20%" }}
//           >
//             <FormControlLabel
//               value="oneday"
//               control={
//                 <Radio
//                   sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }}
//                 />
//               }
//               label="1 Day Service"
//               labelPlacement="end"
//               required
//             />

//             <FormControlLabel
//               value="regular"
//               control={
//                 <Radio
//                   sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }}
//                 />
//               }
//               label="Regular"
//               labelPlacement="end"
//               required
//             />
//           </RadioGroup>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ShippingMethod;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import BackEndUrl from "../../../../api.js";

const OrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const { trackingNumber } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    //console.log("Tracking number:", trackingNumber);
    fetchOrderDetails();
  }, [trackingNumber]);

  const fetchOrderDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BackEndUrl}/api/order/${trackingNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log("Order details response:", response.data);
      setOrderDetails(response.data);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const isImageFile = (fileName) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <Box sx={{ mb: "30px", ml: "180px" }}>
     <Box sx={{ mb: 2, display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          size="small"
          sx={{
            bgcolor: "black",
            "&:hover": { bgcolor: "grey", color: "black" },
          }}
          onClick={() => navigate("/pdfFile", { state: { orderDetails } })}
        >
          View Pdf
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="auto"
        padding="10px"
      >
        <Box width="800px" textAlign="center">
          <h1 style={{ color: "black" }}>Order Details</h1>
          <div className="underline-container">
            <p className="orderdetails-underline"></p>
          </div>

         
          {orderDetails ? (
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>Tracking Number</b>
                      </TableCell>
                      <TableCell>{orderDetails.trackingNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Booking Date</b>
                      </TableCell>
                      <TableCell>{orderDetails.bookingDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Origin</b>
                      </TableCell>
                      <TableCell>{orderDetails.origin}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Time</b>
                      </TableCell>
                      <TableCell>{orderDetails.time}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Destination</b>
                      </TableCell>
                      <TableCell>{orderDetails.destination}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>PUR Reference</b>
                      </TableCell>
                      <TableCell>{orderDetails.reference}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Shipping Method</b>
                      </TableCell>
                      <TableCell>{orderDetails.shippingMethod}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Sender Code</b>
                      </TableCell>
                      <TableCell>{orderDetails.senderCode}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Booking Branch</b>
                      </TableCell>
                      <TableCell>{orderDetails.bookingBranch}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Consignor Name</b>
                      </TableCell>
                      <TableCell>{orderDetails.consignorName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Consignor Phone</b>
                      </TableCell>
                      <TableCell>{orderDetails.consignorPhone}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Consignor Address</b>
                      </TableCell>
                      <TableCell>{orderDetails.consignorAddress}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Consignee Name</b>
                      </TableCell>
                      <TableCell>{orderDetails.consigneeName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Consignee Phone</b>
                      </TableCell>
                      <TableCell>{orderDetails.consigneePhone}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Consignee Address</b>
                      </TableCell>
                      <TableCell>{orderDetails.consigneeAddress}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>GST No</b>
                      </TableCell>
                      <TableCell>{orderDetails.gstNo1}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>GST No</b>
                      </TableCell>
                      <TableCell>{orderDetails.gstNo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Invoice No</b>
                      </TableCell>
                      <TableCell>{orderDetails.invoiceNo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Invoice Value</b>
                      </TableCell>
                      <TableCell>{orderDetails.invoiceValue}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Invoice Date</b>
                      </TableCell>
                      <TableCell>{orderDetails.invoiceDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>eWay Bill No</b>
                      </TableCell>
                      <TableCell>{orderDetails.eWayBillNo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>eWay Bill Validity</b>
                      </TableCell>
                      <TableCell>{orderDetails.eWayBillValidity}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Product Type</b>
                      </TableCell>
                      <TableCell>{orderDetails.productType}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Payment Method</b>
                      </TableCell>
                      <TableCell>{orderDetails.paymentMethod}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Risk Coverage</b>
                      </TableCell>
                      <TableCell>{orderDetails.riskCoverage}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Policy No</b>
                      </TableCell>
                      <TableCell>{orderDetails.policyNo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Validity</b>
                      </TableCell>
                      <TableCell>{orderDetails.validity}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Mode of Payment</b>
                      </TableCell>
                      <TableCell>{orderDetails.modeOfPayment}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Cheque/DD</b>
                      </TableCell>
                      <TableCell>{orderDetails.chequeDD}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Bank Name</b>
                      </TableCell>
                      <TableCell>{orderDetails.bankName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Amount</b>
                      </TableCell>
                      <TableCell>{orderDetails.amount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>No of Items</b>
                      </TableCell>
                      <TableCell>{orderDetails.totalQuantity}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Total Weight</b>
                      </TableCell>
                      <TableCell>{orderDetails.totalWeight}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Dimensions Unit</b>
                      </TableCell>
                      <TableCell>{orderDetails.dimensionsUnit}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Quantity Details</b>
                      </TableCell>
                      <TableCell>
                        {orderDetails.quantityDetails.map((qd, index) => (
                          <div key={index}>
                            <p>Quantity: {qd.quantity}</p>
                            <p>Length: {qd.length}</p>
                            <p>Height: {qd.height}</p>
                            <p>Width: {qd.width}</p>
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {orderDetails.supportDocument && (
                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <h4>Support Document</h4>
                  {isImageFile(orderDetails.supportDocument) ? (
                    <img
                      src=""
                      alt="Support Document"
                      style={{
                        maxWidth: "100%",
                        height: "200px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleImageClick(orderDetails.supportDocument)
                      }
                      ref={async (img) => {
                        if (img) {
                          const token = localStorage.getItem("token");
                          const response = await fetch(
                            `${BackEndUrl}/api/files/${orderDetails.supportDocument
                              .split("/")
                              .pop()}`,
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          );
                          const blob = await response.blob();
                          const objectURL = URL.createObjectURL(blob);
                          img.src = objectURL;
                        }
                      }}
                    />
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={async () => {
                        const token = localStorage.getItem("token");
                        const response = await fetch(
                          `${BackEndUrl}/api/files/${orderDetails.supportDocument
                            .split("/")
                            .pop()}`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        );
                        const blob = await response.blob();
                        const objectURL = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = objectURL;
                        link.download = "support_document.pdf"; // You can set a filename here
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    >
                      Download Support Document
                    </Button>
                  )}
                </div>
              )}

              {/* Modal for Image Display */}
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Full Image View</DialogTitle>
                <DialogContent>
                  {selectedImage && (
                    <img
                      src=""
                      alt="Full Image"
                      style={{ maxWidth: "100%", height: "auto" }}
                      ref={async (img) => {
                        if (img) {
                          const token = localStorage.getItem("token");
                          const response = await fetch(
                            `${BackEndUrl}/api/files/${selectedImage
                              .split("/")
                              .pop()}`,
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          );
                          const blob = await response.blob();
                          const objectURL = URL.createObjectURL(blob);
                          img.src = objectURL;
                        }
                      }}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                  {selectedImage && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={async () => {
                        const token = localStorage.getItem("token");
                        const response = await fetch(
                          `${BackEndUrl}/api/files/${selectedImage
                            .split("/")
                            .pop()}`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        );
                        const blob = await response.blob();
                        const objectURL = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = objectURL;
                        link.download = "full_image.jpg"; // You can set a filename here
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    >
                      Download
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            </Box>
          ) : (
            <Typography>Loading...</Typography>
          )}
          <Link to="/Admin">
            <Button
              variant="contained"
              color="error"
              style={{ marginTop: "30px" }}
            >
              Back
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderDetails;


import { useEffect, useState } from "react";

const Counter = ({ target, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = parseInt(target, 10);
    if (start === end) return;

    const totalMilSecDur = parseInt(duration, 10) * 1000;
    const incrementValue = Math.ceil(end / (totalMilSecDur / 10)); // Calculate increment value
    const incrementTime = totalMilSecDur / (end / incrementValue); // Adjust time interval based on increment value

    const timer = setInterval(() => {
      start += incrementValue;
      if (start >= end) {
        start = end;
        clearInterval(timer);
      }
      setCount(start);
    }, incrementTime);

    return () => clearInterval(timer);
  }, [target, duration]);

  return (
    <div style={{ fontSize: "40px", fontWeight: 700, color: "#6A43C7" }}>
      {count}<sup>+</sup>
    </div>
  );
};

export default Counter;

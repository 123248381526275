import "./homepage.css";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Aos from "aos";
import "aos/dist/aos.css";
//import { AuroraHero } from "./AuroraHero";
import Slider from "./Slider";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Counter from "./Counter1";
import About from "./About";
import DeliveryTeam from "./DeliveryTeam";

const Homepage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });

  return (
    <div>
      <div className="hero-container">
        <Box className="homepage-hero-container">
          <div className="homepage-welcome-container" data-aos="fade-right">
            <div className="homepage-welcome">Welcome to</div>
            <div>
              <h1 className="homepage-shift-your-cargo">Shift Your Cargo</h1>
              <div className="homepage-divider"></div>
            </div>

            <div className="homepage-message">
              Global turnkey logistics and transportation services via sea, land
              and air.
            </div>
            <div className="homepage-message">
              We will protect you from risk and liability.
            </div>
          </div>
          <div className="homepage-trackorder-container" data-aos="fade-left">
            <div>
              <h1 className="homepage-trackorder">Track Order</h1>
              <div className="homepage-trackorder-divider"></div>
            </div>
            <div className="homepage-track-message">
              Track Your Order Through Traking Id
            </div>
            <div>Click Here</div>
            <Link to="/Track">
              <Button
                variant="contained"
                sx={{ bgcolor: "#6A43C7", "&:hover": { bgcolor: "#4818bf" } }}
              >
                Track Your Shipping Details Here
              </Button>
            </Link>
          </div>
        </Box>
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "50px",
          display: "flex",
          justifyContent: "center",
          gap: "30px",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            padding: "10px",
            width: "calc(50% - 30px)",
            maxWidth: "300px",
            borderRadius: "5px",
            marginBottom: "20px",
          }}
        >
          <Counter target="3000" duration="1" />
          <h1 style={{fontSize:"25px", fontWeight:600}}>Happy clients</h1>
        </div>
        <div
          style={{
            padding: "10px",
            width: "calc(50% - 30px)",
            maxWidth: "300px",
            borderRadius: "5px",
            marginBottom: "20px",
          }}
        >
          <Counter target="400" duration="1" />
          <h1 style={{fontSize:"25px", fontWeight:600}}>Skilled Experts</h1>
        </div>
        <div
          style={{
            padding: "10px",
            width: "calc(50% - 30px)",
            maxWidth: "300px",
            borderRadius: "5px",
            marginBottom: "20px",
          }}
        >
          <Counter target="5000" duration="1" />
          <h1 style={{fontSize:"25px", fontWeight:600}}>Projects</h1>
        </div>
      </div>

      <div>
        <About />
      </div>

      <DeliveryTeam />

      {/* <AuroraHero/> */}
      <Box sx={{ mt: "50px", mb: "50px" }}>
        <Slider />
      </Box>

      {/* <div className="image" style={{ marginTop: "20px", width: "100%" }}></div> */}
    </div>
  );
};

export default Homepage;

import * as React from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  TablePagination,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import BackEndUrl from "../../../api.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "3px",
};

const WareHouses = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedPickupDetails, setSelectedPickupDetails] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };
  const handleAddNewOpen = () => setOpen1(true);
  const handleAddClose = () => setOpen1(false);

  const [pickUpLocation, setPickUpLocation] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    pickUpLocations();
  }, []);

  const pickUpLocations = async () => {
    try {
      const token = localStorage.getItem("token");
      
      const result = await axios.get(`${BackEndUrl}/api/pickUpLocations`,
        {
          headers:{
            Authorization: `Bearer ${token}`
          }
        }
      );
      setPickUpLocation(result.data);
    } catch (error) {
     // console.log("error fetching pickuplocations", error);
      toast.error("Error fetching pickuplocations");
    }
  };

  const initialPickupData = {
    name: "",
    pinCode: "",
    city: "",
    state: "",
    country: "",
    address: "",
    contactPerson: "",
    email: "",
    mobileNumber: "",
    pickupSlot: "",
  };

  const [pickUpdata, setPickupData] = useState(initialPickupData);

  const handleChangeAddModal = (event) => {
    const { name, value } = event.target;
    setPickupData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setSelectedPickupDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddNewSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BackEndUrl}/api/pickUpLocation`,
        pickUpdata,
        {
          headers:{
            Authorization: `Bearer ${token}`
          }
        }
      );
      setOpen1(false);
      setPickupData(initialPickupData);
      //console.log("Pickup location added successfully", response.data);
      toast.success("Pickup location added successfully");
      pickUpLocations(); // Refresh the list after adding a new location
    } catch (error) {
      //console.log("Error adding pickup location", error);
      toast.error("Error adding pickup location");
    }
  };

  const handleEditSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${BackEndUrl}/api/pickUpLocation/${selectedPickupDetails.name}`,
        selectedPickupDetails,
        {
          headers:{
            Authorization: `Bearer ${token}`
          }
        }
      );
      setOpen(false);
      setIsEdit(false);
      //console.log("Pickup location updated successfully", response.data);
      toast.success("Pickup location updated successfully");
      pickUpLocations(); // Refresh the list after updating the location
    } catch (error) {
     // console.log("Error updating pickup location", error);
      toast.error("Error updating pickup location");
    }
  };

  const handleDelete = async () => {


    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${BackEndUrl}/api/pickUpLocation/${selectedPickupDetails.name}`,
        {
          headers:{
            Authorization: `Bearer ${token}`
          }
        }
      );
      //console.log("order deleted successfully.", response.data)
      toast.info("order deleted successfully.")
      setOpen(false);
      setIsEdit(false);
    } catch (error) {
     // console.log("Error deleting pickup location", error)
      toast.error("Error deleting pickup location")
    }

  }

  const handleChangePickup = (event) => {
    setPickupData({ ...pickUpdata, pickupSlot: event.target.value });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredPickUpLocations = pickUpLocation.filter((location) => {
    return (
      location.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      location.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
      location.pinCode.toString().includes(searchQuery) ||
      location.contactPerson.toString().includes(searchQuery)
    );
  });

  const paginatedLocations = filteredPickUpLocations.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleOpen = async (name) => {
    try {
      const token = localStorage.getItem("token");
      const result = await axios.get(
        `${BackEndUrl}/api/pickUpLocation/${name}`,
        {
          headers:{
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedPickupDetails(result.data);
      setOpen(true);
    } catch (error) {
     // console.log("Error fetching pickup location details", error);
      toast.error("Error fetching pickup location details");
    }
  };

  return (
    <Box sx={{marginLeft:"180px"}}>
      <TableContainer component={Paper} sx={{ width: "95%", margin: "0 auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "rgb(250, 250, 250)" }}>
            <TableRow>
              <TableCell
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>Pickup points ({filteredPickUpLocations.length})</span>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    marginRight: "17%",
                    marginLeft: "17%",
                  }}
                >
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Search by name, city or pincode"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddNewOpen}
                >
                  Add New
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} sx={{ width: "95%", margin: "0 auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>PinCode</TableCell>
              <TableCell align="right">City</TableCell>
              <TableCell align="right">Contact Person</TableCell>
              <TableCell align="right">Contact Email</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedLocations.map((data, index) => (
              <TableRow key={index}>
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.pinCode}</TableCell>
                <TableCell align="right">{data.city}</TableCell>
                <TableCell align="right">{data.contactPerson}</TableCell>
                <TableCell align="right">{data.email}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleOpen(data.name)}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={filteredPickUpLocations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Add New Pickup Location Modal */}

      <Modal
        open={open1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" component="h5" variant="bold">
              ADD NEW PICKUP POINT
            </Typography>
            <Typography variant="span">
              <CloseIcon sx={{ cursor: "pointer" }} onClick={handleAddClose} />
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ flex: 1, mr: "4%" }}>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  size="small"
                  fullWidth
                  value={pickUpdata.name}
                  onChange={handleChangeAddModal}
                />
              </Box>
              <Box>
                <Typography>PinCode</Typography>
                <TextField
                  name="pinCode"
                  size="small"
                  fullWidth
                  value={pickUpdata.pinCode}
                  onChange={handleChangeAddModal}
                />
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ mr: "4%", width: "30%" }}>
                <Typography>City</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="city"
                  value={pickUpdata.city}
                  onChange={handleChangeAddModal}
                />
              </Box>
              <Box sx={{ width: "31%" }}>
                <Typography>State</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="state"
                  value={pickUpdata.state}
                  onChange={handleChangeAddModal}
                />
              </Box>

              <Box>
                <Typography>Country</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="country"
                  value={pickUpdata.country}
                  onChange={handleChangeAddModal}
                />
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ flex: 1 }}>
                <Typography>Address</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="address"
                  value={pickUpdata.address}
                  onChange={handleChangeAddModal}
                />
              </Box>
            </Box>

            <Divider sx={{ bgcolor: "grey", mt: "3%", mb: "3%" }} />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ mr: "4%", width: "30%" }}>
                <Typography>Contact Person</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="contactPerson"
                  value={pickUpdata.contactPerson}
                  onChange={handleChangeAddModal}
                />
              </Box>
              <Box sx={{ width: "31%" }}>
                <Typography>Email</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="email"
                  value={pickUpdata.email}
                  onChange={handleChangeAddModal}
                />
              </Box>

              <Box>
                <Typography>Phone Number</Typography>
                <TextField
                  size="small"
                  fullWidth
                  name="mobileNumber"
                  value={pickUpdata.mobileNumber}
                  onChange={handleChangeAddModal}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: "2%",
              }}
            >
              <Box sx={{ minWidth: 200 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Pickup Slot
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pickUpdata.pickupSlot}
                    label="pickUp"
                    onChange={handleChangePickup}
                    variant="standard"
                  >
                    <MenuItem value={"01:00 Pm - 04:00 Pm"}>
                      01:00 Pm - 04:00 Pm
                    </MenuItem>
                    <MenuItem value={"04:00 Pm - 07:00 Pm"}>
                      04:00 Pm - 07:00 Pm
                    </MenuItem>
                    <MenuItem value={"07:00 Pm - 10:00 Pm"}>
                      07:00 Pm - 10:00 Pm
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              bgcolor: "rgb(230, 230, 230)",
              m: -2,
              p: 2,
              mt: "3%",
            }}
          >
            <Button
              size="small"
              variant="standard"
              sx={{ mr: "2%" }}
              onClick={handleAddClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              onClick={handleAddNewSubmit}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Edit Pickup Location Modal */}
      <Modal open={open}>
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" component="h5" variant="bold">
              Pickup Details
            </Typography>
            <Typography variant="span">
              <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
            </Typography>
          </Box>
          <Divider sx={{ marginBottom: 2 }} />
          {selectedPickupDetails && (
            <Box component="form">
              <Box sx={{ display: "flex" }}>
                <Box sx={{ flex: 1, mr: "4%" }}>
                  <Typography>Name</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    name="name"
                    value={selectedPickupDetails.name}
                    onChange={handleEditChange}
                    disabled
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography>PinCode</Typography>
                  <TextField
                    size="small"
                    name="pinCode"
                    fullWidth
                    value={selectedPickupDetails.pinCode}
                    onChange={handleEditChange}
                    disabled={!isEdit}
                  />
                </Box>
              </Box>
              <br />
              <Box sx={{ display: "flex" }}>
                <Box sx={{ flex: 1, mr: "4%" }}>
                  <Typography>City</Typography>
                  <TextField
                    size="small"
                    name="city"
                    fullWidth
                    value={selectedPickupDetails.city}
                    onChange={handleEditChange}
                    disabled={!isEdit}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography>State</Typography>
                  <TextField
                    size="small"
                    name="state"
                    fullWidth
                    value={selectedPickupDetails.state}
                    onChange={handleEditChange}
                    disabled={!isEdit}
                  />
                </Box>
              </Box>
              <br />
              <Box>
                <Typography>Country</Typography>
                <TextField
                  size="small"
                  name="country"
                  fullWidth
                  value={selectedPickupDetails.country}
                  onChange={handleEditChange}
                  disabled={!isEdit}
                />
              </Box>
              <br />
              <Box>
                <Typography>Address</Typography>
                <TextField
                  size="small"
                  name="address"
                  fullWidth
                  value={selectedPickupDetails.address}
                  onChange={handleEditChange}
                  disabled={!isEdit}
                />
              </Box>
              <br />
              <Box sx={{ display: "flex" }}>
                <Box sx={{ flex: 1, mr: "4%" }}>
                  <Typography>Contact Person</Typography>
                  <TextField
                    size="small"
                    name="contactPerson"
                    fullWidth
                    value={selectedPickupDetails.contactPerson}
                    onChange={handleEditChange}
                    disabled={!isEdit}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography>Email</Typography>
                  <TextField
                    size="small"
                    name="email"
                    fullWidth
                    value={selectedPickupDetails.email}
                    onChange={handleEditChange}
                    disabled={!isEdit}
                  />
                </Box>
              </Box>
              <br />
              <Box sx={{ display: "flex" }}>
                <Box sx={{ flex: 1, mr: "4%" }}>
                  <Typography>Phone Number</Typography>
                  <TextField
                    size="small"
                    name="mobileNumber"
                    fullWidth
                    value={selectedPickupDetails.mobileNumber}
                    onChange={handleEditChange}
                    disabled={!isEdit}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Pickup Slot
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="pickUp"
                      variant="standard"
                      name="pickupSlot"
                      value={selectedPickupDetails.pickupSlot}
                      onChange={handleEditChange}
                      disabled={!isEdit}
                    >
                      <MenuItem value={"01:00 Pm - 04:00 Pm"}>
                        01:00 Pm - 04:00 Pm
                      </MenuItem>
                      <MenuItem value={"04:00 Pm - 07:00 Pm"}>
                        04:00 Pm - 07:00 Pm
                      </MenuItem>
                      <MenuItem value={"07:00 Pm - 10:00 Pm"}>
                        07:00 Pm - 10:00 Pm
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "end", mt: "15px" }}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => setIsEdit((prev) => !prev)}
              sx={{ mr: "12px" }}
            >
              {isEdit ? "Cancel" : "Edit"}
            </Button>
            {isEdit && (
              <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditSubmit}
                sx={{mr:"5px"}}
                size="small"
              >
                Save
              </Button>
              <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              Delete
            </Button>
            </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default WareHouses;

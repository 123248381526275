import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Link} from "react-router-dom";
import BackEndUrl from "../../../../api.js";
import AllPickUpRequests from "../../pickuprequest/AllPickUpRequests.js";

const AllOrders = () => {

  const [trackData, setTrackData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("allOrders"); // State to keep track of the current view
  const [pickupRequestsCount, setPickupRequestsCount] = useState(0); // State to keep track of the pickup requests count

  useEffect(() => {
    fetchData();
    fetchPickupRequestsCount();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BackEndUrl}/api/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTrackData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPickupRequestsCount = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BackEndUrl}/api/pickUpRequests`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPickupRequestsCount(response.data.length);
    } catch (error) {
      console.error("Error fetching pickup requests count:", error);
    }
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  
  const filterDataByStatus = (status) => {
    if (!trackData) {
      return []; // Return empty array if trackData is not yet loaded or is undefined
    }
  
    if (status === "manifested") {
      return trackData.filter(
        (order) =>
          order.trackingDetails?.some((detail) => detail.status === "pickup") &&
          !order.trackingDetails?.some((detail) => detail.status === "intransit") &&
          !order.trackingDetails?.some((detail) => detail.status === "delivered")
      );
    } else if (status === "intransit") {
      return trackData.filter(
        (order) =>
          order.trackingDetails?.some((detail) => detail.status === "intransit") &&
          !order.trackingDetails?.some((detail) => detail.status === "delivered")
      );
    } else {
      return trackData.filter((order) =>
        order.trackingDetails?.some((detail) => detail.status === status)
      );
    }
  };

  
  return (
    <Box sx={{ padding: "16px", ml: "180px" }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              marginBottom: "16px",
            }}
          >
            <Box
              sx={{
                height: "80px",
                bgcolor: "#FFA500",
                flex: 1,
                textAlign: "center",
                marginRight: "8px",
                cursor: "pointer",
                padding: "8px",
              }}
              onClick={() => handleViewChange("pickupRequests")}
            >
              <Typography sx={{ color: "#000" }}>Pickup Requests</Typography>
              <Typography variant="h6" sx={{ color: "#000" }}>
                {pickupRequestsCount}
              </Typography>
            </Box>

            

            <Box
              sx={{
                height: "80px",
                bgcolor: "grey",
                flex: 1,
                textAlign: "center",
                cursor: "pointer",
                padding: "8px",
                marginRight: "8px",
              }}
              onClick={() => handleViewChange("manifested")}
            >
              <Typography sx={{ color: "#000" }}>Manifested</Typography>
              <Typography variant="h6" sx={{ color: "#000" }}>
                {filterDataByStatus("manifested").length}
              </Typography>
            </Box>

            <Box
              sx={{
                height: "80px",
                bgcolor: "#2196F3",
                flex: 1,
                textAlign: "center",
                cursor: "pointer",
                padding: "8px",
                marginRight: "8px",
              }}
              onClick={() => handleViewChange("inTransit")}
            >
              <Typography sx={{ color: "#000" }}>In Transit</Typography>
              <Typography variant="h6" sx={{ color: "#000" }}>
                {filterDataByStatus("intransit").length}
              </Typography>
            </Box>

            <Box
              sx={{
                height: "80px",
                bgcolor: "#4CAF50",
                flex: 1,
                textAlign: "center",
                marginRight: "8px",
                cursor: "pointer",
                padding: "8px",
              }}
              onClick={() => handleViewChange("delivered")}
            >
              <Typography sx={{ color: "#000" }}>Delivered</Typography>
              <Typography variant="h6" sx={{ color: "#000" }}>
                {filterDataByStatus("delivered").length}
              </Typography>
            </Box>

            
             
              
            </Box>
        </Grid>

        <Grid item xs={12}>
          {(() => {
            switch (view) {
              case "pickupRequests":
                return <AllPickUpRequests />;
              case "manifested":
                return (
                  <>
                    <Typography variant="h6" sx={{ marginBottom: "16px" }}>
                      Manifested Orders
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ bgcolor: "rgb(245, 245, 245)" }}>
                            <TableCell>
                              <b>LR No</b>
                            </TableCell>
                            <TableCell>
                              <b>Master Waybill</b>
                            </TableCell>
                            <TableCell>
                              <b>Consignee</b>
                            </TableCell>
                            <TableCell>
                              <b>Freight</b>
                            </TableCell>
                            <TableCell>
                              <b>Action</b>
                            </TableCell>
                            <TableCell>
                              <b>Status</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading
                            ? Array.from(new Array(5)).map((_, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                </TableRow>
                              ))
                            : filterDataByStatus("manifested")
                                .slice()
                                .reverse()
                                .map((details, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Link to={`${details.trackingNumber}`}>
                                        <Button
                                          size="small"
                                          sx={{ textTransform: "none" }}
                                        >
                                          {details.trackingNumber}
                                        </Button>
                                      </Link>
                                    </TableCell>
                                    <TableCell>{details.eWaybill}</TableCell>
                                    <TableCell>
                                      {details.consigneeGSTIN}
                                    </TableCell>
                                    <TableCell>{details.freightMode}</TableCell>
                                    <TableCell>
                                      <Link
                                        to={`${details.trackingNumber}/edit`}
                                      >
                                        <Button variant="outlined" size="small">
                                          Edit
                                        </Button>
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      <Link
                                        to={`${details.trackingNumber}/update`}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="success"
                                          size="small"
                                        >
                                          Update
                                        </Button>
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                );
              case "delivered":
                return (
                  <>
                    <Typography variant="h6" sx={{ marginBottom: "16px" }}>
                      Delivered Orders
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ bgcolor: "rgb(245, 245, 245)" }}>
                            <TableCell>
                              <b>LR No</b>
                            </TableCell>
                            <TableCell>
                              <b>Master Waybill</b>
                            </TableCell>
                            <TableCell>
                              <b>Consignee</b>
                            </TableCell>
                            <TableCell>
                              <b>Freight</b>
                            </TableCell>
                            <TableCell>
                              <b>Action</b>
                            </TableCell>
                            <TableCell>
                              <b>Status</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading
                            ? Array.from(new Array(5)).map((_, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                </TableRow>
                              ))
                            : filterDataByStatus("delivered")
                                .slice()
                                .reverse()
                                .map((details, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Link to={`${details.trackingNumber}`}>
                                        <Button
                                          size="small"
                                          sx={{ textTransform: "none" }}
                                        >
                                          {details.trackingNumber}
                                        </Button>
                                      </Link>
                                    </TableCell>
                                    <TableCell>{details.eWaybill}</TableCell>
                                    <TableCell>
                                      {details.consigneeGSTIN}
                                    </TableCell>
                                    <TableCell>{details.freightMode}</TableCell>
                                    <TableCell>
                                      <Link
                                        to={`${details.trackingNumber}/edit`}
                                      >
                                        <Button variant="outlined" size="small">
                                          Edit
                                        </Button>
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      <Link
                                        to={`${details.trackingNumber}/update`}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="success"
                                          size="small"
                                        >
                                          Update
                                        </Button>
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                );
              case "inTransit":
                return (
                  <>
                    <Typography variant="h6" sx={{ marginBottom: "16px" }}>
                      In Transit Orders
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ bgcolor: "rgb(245, 245, 245)" }}>
                            <TableCell>
                              <b>LR No</b>
                            </TableCell>
                            <TableCell>
                              <b>Master Waybill</b>
                            </TableCell>
                            <TableCell>
                              <b>Consignee</b>
                            </TableCell>
                            <TableCell>
                              <b>Freight</b>
                            </TableCell>
                            <TableCell>
                              <b>Action</b>
                            </TableCell>
                            <TableCell>
                              <b>Status</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading
                            ? Array.from(new Array(5)).map((_, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                </TableRow>
                              ))
                            : filterDataByStatus("intransit")
                                .slice()
                                .reverse()
                                .map((details, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Link to={`${details.trackingNumber}`}>
                                        <Button
                                          size="small"
                                          sx={{ textTransform: "none" }}
                                        >
                                          {details.trackingNumber}
                                        </Button>
                                      </Link>
                                    </TableCell>
                                    <TableCell>{details.eWaybill}</TableCell>
                                    <TableCell>
                                      {details.consigneeGSTIN}
                                    </TableCell>
                                    <TableCell>{details.freightMode}</TableCell>
                                    <TableCell>
                                      <Link
                                        to={`${details.trackingNumber}/edit`}
                                      >
                                        <Button variant="outlined" size="small">
                                          Edit
                                        </Button>
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      <Link
                                        to={`${details.trackingNumber}/update`}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="success"
                                          size="small"
                                        >
                                          Update
                                        </Button>
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                );
              case "allOrders":
              default:
                return (
                  <>
                    <Typography variant="h6" sx={{ marginBottom: "16px" }}>
                      All Orders
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ bgcolor: "rgb(245, 245, 245)" }}>
                            <TableCell>
                              <b>LR No</b>
                            </TableCell>
                            <TableCell>
                              <b>Master Waybill</b>
                            </TableCell>
                            <TableCell>
                              <b>Consignee</b>
                            </TableCell>
                            <TableCell>
                              <b>Freight</b>
                            </TableCell>
                            <TableCell>
                              <b>Action</b>
                            </TableCell>
                            <TableCell>
                              <b>Status</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading
                            ? Array.from(new Array(5)).map((_, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" />
                                  </TableCell>
                                </TableRow>
                              ))
                            : trackData
                                .slice()
                                .reverse()
                                .map((details, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Link to={`${details.trackingNumber}`}>
                                        <Button
                                          size="small"
                                          sx={{ textTransform: "none" }}
                                        >
                                          {details.trackingNumber}
                                        </Button>
                                      </Link>
                                    </TableCell>
                                    <TableCell>{details.eWaybill}</TableCell>
                                    <TableCell>
                                      {details.consigneeGSTIN}
                                    </TableCell>
                                    <TableCell>{details.freightMode}</TableCell>
                                    <TableCell>
                                      <Link
                                        to={`${details.trackingNumber}/edit`}
                                      >
                                        <Button variant="outlined" size="small">
                                          Edit
                                        </Button>
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      <Link
                                        to={`${details.trackingNumber}/update`}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="success"
                                          size="small"
                                        >
                                          Update
                                        </Button>
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                );
            }
          })()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllOrders;

// VideoModal.jsx

import React from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import video from "../../images/video.mp4";

const VideoModal = ({ open, handleClose }) => {
  const ModalWrapper = styled("div")({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjust modal width as per your design
    maxWidth: "800px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapper>
        <video controls style={{ width: "100%" }}>
          <source src={video} type="video/mp4" />
        </video>
      </ModalWrapper>
    </Modal>
  );
};

export default VideoModal;




import React, { useEffect, useState } from "react";
import "./contactpage.css";
import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import TimerIcon from "@mui/icons-material/Timer";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import Aos from "aos";
import 'aos/dist/aos.css'
import { toast } from 'react-toastify';

const ContactPage = () => {

  useEffect(()=>{
    Aos.init({duration:1000})
  }, [])



  
const [result, setResult] = useState("");

const onSubmit = async (event) => {
  event.preventDefault();
  setResult("Sending....");
  const formData = new FormData(event.target);

  formData.append("access_key", "06fa2e60-82a6-4f7f-84b3-c0f30cf35ee2");

  const response = await fetch("https://api.web3forms.com/submit", {
    method: "POST",
    body: formData
  });

  const data = await response.json();

  if (data.success) {
    setResult("Form Submitted Successfully");
    event.target.reset();
    event.target.reset();
    toast.success("Form Submitted Successfully");
  } else {
   // console.log("Error", data);
    setResult(data.message);
    toast.error("Failed to submit the form");
  }
};


  return (
    <div className="contactpage-container">
      <div className="contactpage-div1" data-aos="fade-right">
        <div>
          <h1>BANGALORE OFFICE</h1>
          <p>
            Everyday is a new day for us and we work really hard to satisfy
            our customers everywhere.
          </p>
        </div>
        <div className="contactpage-firsticon-container">
          <HomeIcon />
          <div className="first-container">
            <h3 className="contactpage-syc">Shift Your Cargo</h3>
            <p className="contactpage-p">
              No 59, 2nd Main, Rukmini Nagar, Nagasandra post, Banglore-560073
            </p>
          </div>
        </div>

        <div className="contactpage-firsticon-container">
          <CallIcon />
          <div className="first-container">
            <p className="contactpage-p">+91 9900252585</p>
          </div>
        </div>

        <div className="contactpage-firsticon-container">
          <EmailIcon />
          <div className="first-container">
            <p className="contactpage-p">Sales@shiftyourcargo.com</p>
            <p className="contactpage-p">info@shiftyourcargo.com</p>
          </div>
        </div>

        <div className="contactpage-firsticon-container">
          <TimerIcon />
          <div className="first-container">
            <h3 className="contactpage-syc">Shift Your Cargo</h3>
            <p className="contactpage-p">Mon - Sun 24 HOURS</p>
          </div>
        </div>
      </div>
      <div className="contactpage-div2" data-aos="fade-left">
        <div>
          <h1>SEND US A MESSAGE</h1>
          <p>
            We are here to answer any questions you may have about our combadi
            experiences. Reach out to us and we'll respond as soon as we can.
          </p>

          <form onSubmit={onSubmit}>
            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <TextField
                        name="firstName"
                        label="First Name"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="lastName"
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextField
                        name="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="phoneNumber"
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextField
                        name="subject"
                        label="Subject"
                        fullWidth
                        required
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="message"
                        label="Message"
                        variant="outlined"
                        multiline
                        fullWidth
                        required
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#6A43C7",
                marginTop: "20px",
                "&:hover": { bgcolor: "#4818bf" },
              }}
            >
              Send
            </Button>
          </form>
          <span>{result}</span>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;

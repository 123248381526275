


const CreateBulkOrder = () => {
  return (
    <div style={{ textAlign: "center", marginLeft:"180px" }}>
      <h3 style={{ color: "skyblue" }}>
        Hey Welcome to Create Bulk-Order-Page!!!
      </h3>
      <br /> <br />
      <h4 style={{ color: "red" }}>still working on this page</h4>
    </div>
  );
};

export default CreateBulkOrder;

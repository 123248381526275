import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./servicepage.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import CircularProgress from '@mui/material/CircularProgress'; // MUI loader
import PriceList from "./PriceList"


import Img1 from "../../images/servicesImages/air2.jpg"
import Img2 from "../../images/servicesImages/land.jpg"
import Img3 from "../../images/servicesImages/ocean.jpg"
import Img4 from "../../images/servicesImages/warehouse.jpg"

const ServicePage = () => {
  const [imgLoading, setImgLoading] = useState(false)

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = "scale(1.05)";
    e.currentTarget.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.2)";
  }

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = "scale(1)";
    e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
  }

  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: "3%" }}>
        <h5 style={{ fontWeight: 500, color: "#6A43C7", marginTop: "1%" }}>OUR SERVICES</h5>
        <h1 style={{ fontWeight: 700 }}>Best Logistic Services</h1>
      </div>
      <div
        className="card-container"
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "20px",
          marginBottom: "6%",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px 8px 0 0",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
          }}
          className="flex flex-col "
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <LazyLoadImage
            alt="Air Freight"
            src={Img1}
            effect="blur"
            width="100%"
            style={{ borderRadius: "8px 8px 0px 0px", width: "100%", margin: 0, padding: 0 }}
            afterLoad={() => setImgLoading(false)}
            beforeLoad={() => setImgLoading(true)}
          />

          <div
            style={{
              backgroundColor: "#6A43C7",
              color: "white",
              padding: "10px",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            <span style={{ fontSize: "24px" }}>Air Freight</span>
          </div>
          <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#f9f9f9" }}>
            <p style={{ fontSize: "14px", color: "#333", lineHeight: "1.5" }}>
              Our Air Freight services ensure your cargo reaches its destination swiftly
              and safely. With global coverage and efficient handling, we provide reliable
              and timely delivery for all your urgent shipments, no matter the size or
              destination.
            </p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            cursor: "pointer",
            backgroundColor: "#f9f9f9"

          }}
          className="flex flex-col"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <LazyLoadImage
            alt="Ocean Freight"
            src={Img3}
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "8px 8px 0 0", width: "100%", display: "block" }}  // Added display block
            afterLoad={() => setImgLoading(false)}
            beforeLoad={() => setImgLoading(true)}
          />
          <div
            style={{
              backgroundColor: "#6A43C7",
              color: "white",
              padding: "10px",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            <span style={{ fontSize: "24px" }}>Ocean Freight</span>
          </div>
          <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#f9f9f9" }}>
            <p style={{ fontSize: "14px", color: "#333", lineHeight: "1.5" }}>
              We offer comprehensive Ocean Freight solutions that connect major ports worldwide. Whether you need full container loads (FCL) or less-than-container loads (LCL), our services are designed to meet your needs with cost-effective and dependable transit options.
            </p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            cursor: "pointer",
            backgroundColor: "#f9f9f9"
          }}
          className="flex flex-col"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <LazyLoadImage
            alt="Land Transport"
            src={Img2}
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "8px 8px 0px 0px", width: "100%" }}

            afterLoad={() => setImgLoading(false)}
            beforeLoad={() => setImgLoading(true)}
          />
          <div
            style={{
              backgroundColor: "#6A43C7",
              color: "white",
              padding: "10px",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            <span style={{ fontSize: "24px" }}>Land Transport</span>
          </div>
          <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#f9f9f9" }}>
            <p style={{ fontSize: "14px", color: "#333", lineHeight: "1.5" }}>
              Our Land Transport services deliver your goods across the country with precision and care. We use an extensive network of routes and reliable carriers to ensure timely deliveries, whether for long-haul or last-mile needs.
            </p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            cursor: "pointer",
            backgroundColor: "#f9f9f9"

          }} s
          className="flex flex-col"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <LazyLoadImage
            alt="Cargo Storage"
            src={Img4}
            effect="blur"
            style={{ borderRadius: "8px 8px 0px 0px", width: "100%" }}

            afterLoad={() => setImgLoading(false)}
            beforeLoad={() => setImgLoading(true)}
          />
          <div
            style={{
              backgroundColor: "#6A43C7",
              color: "white",
              padding: "10px",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            <span style={{ fontSize: "24px" }}>Cargo Storage</span>
          </div>
          <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#f9f9f9" }}>
            <p style={{ fontSize: "14px", color: "#333", lineHeight: "1.5" }}>
              Secure and efficient, our Cargo Storage solutions offer the flexibility to store your goods safely. With climate-controlled facilities and advanced security measures, your cargo is protected and ready for distribution whenever you need it.
            </p>
          </div>
        </div>
      </div>
      <PriceList/>
    </div>
  );
};

export default ServicePage;

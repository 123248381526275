// import React, { useState } from "react";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// import { Link, useNavigate } from "react-router-dom";
// import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
// //import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import BackEndUrl from "../api";
// import { toast } from "react-toastify";
// import logo from "../images/syc-logo.jpg"
// import CircularProgress from "@mui/material/CircularProgress";

// function Copyright(props) {
//     return (
//       <Typography
//         variant="body2"
//         color="text.secondary"
//         align="center"
//         {...props}
//       >
//         {"Copyright © "}
//         <a
//           style={{ color: "inherit" }}
//           target="_blank"
//           href="https://shiftyourcargo.com/"
//         >
//           ShiftYourCargo
//         </a>{" "}
//         {""}
//         {new Date().getFullYear()}
//         {"."}
//       </Typography>
//     );
//   }

//   const logo1 = (
//     <img src={logo} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover'}} />
//   );

// function SignUp() {
//   const navigate = useNavigate();

//   const [loading, setLoading] = useState(false);

//   const [formErrors, setFormErrors] = useState({
//     firstName: false,
//     lastName: false,
//     email: false,
//     password: false,
//   });

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     setLoading(true)

//     //validate form fields
//     if (
//       !data.get("firstName") ||
//       !data.get("lastName") ||
//       !data.get("email") ||
//       !data.get("password")
//     ) {
//       setFormErrors({
//         firstName: !data.get("firstName"),
//         lastName: !data.get("lastName"),
//         email: !data.get("email"),
//         password: !data.get("password"),
//       });
//       return;
//     }

//     const response = await fetch(`${BackEndUrl}/api/v1/auth/register`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         firstName: data.get("firstName"),
//         lastName: data.get("lastName"),
//         email: data.get("email"),
//         password: data.get("password"),
//       }),
//     });

//     if (response.ok) {
//       const result = await response.json();
//       console.log(result.token);
//       // Store the token in localStorage or state management solution
//       localStorage.setItem("token", result.token);
//       localStorage.setItem("email", data.get("email")); // Store user's email

//       toast.success("Registration successful", {
//         onClose: () => {
//           navigate("/SignIn"); // Redirect to home page
//         },
//       });
//     } else {
//       console.error("Registration failed");
//     }
//   };

//   return (
//     <ThemeProvider theme={createTheme()}>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <Box
//           sx={{
//             marginTop: 8,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <Avatar sx={{ m: 1, border:"1px solid black" }}>
//            {logo1}
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Sign up
//           </Typography>
//           <Box
//             component="form"
//             noValidate
//             onSubmit={handleSubmit}
//             sx={{ mt: 3 }}
//           >
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   error={formErrors.firstName}
//                   helperText={
//                     formErrors.firstName ? "FirstName is required" : ""
//                   }
//                   autoComplete="given-name"
//                   name="firstName"
//                   required
//                   fullWidth
//                   id="firstName"
//                   label="First Name"
//                   autoFocus
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   error={formErrors.lastName}
//                   helperText={
//                     formErrors.lastName ? "LastName is required" : ""
//                   }
//                   required
//                   fullWidth
//                   id="lastName"
//                   label="Last Name"
//                   name="lastName"
//                   autoComplete="family-name"
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   error={formErrors.email}
//                   helperText={formErrors.email ? "Email is required" : ""}
//                   required
//                   fullWidth
//                   id="email"
//                   label="Email Address"
//                   name="email"
//                   autoComplete="email"
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   error={formErrors.password}
//                   helperText={
//                     formErrors.password ? "Password is required" : ""
//                   }
//                   required
//                   fullWidth
//                   name="password"
//                   label="Password"
//                   type="password"
//                   id="password"
//                   autoComplete="new-password"
//                 />
//               </Grid>
//             </Grid>
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{
//                 mt: 3,
//                 mb: 2,
//                 bgcolor: "#000",
//                 "&:hover": { bgcolor: "#fff", color: "#000" },
//               }}
//             >
//               Sign Up
//             </Button>
//             <Grid container justifyContent="flex-center">
//               <Grid item>
//                 <Button
//                   component={Link}
//                   to="/SignIn"
//                   variant="body2"
//                   fullWidth
//                   size="small"
//                   sx={{
//                     mt: 1,
//                     mb: 2,
//                     textTransform: "none",
//                     "&:hover": {
//                       textDecoration: "underline",
//                       bgcolor: "#000",
//                       color: "#fff"
//                     },
//                     fontSize:"13px"
//                   }}
//                 >
//                   Already have an account? Sign in
//                 </Button>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//         <Copyright sx={{ mt: 8, mb: 4 }} />
//       </Container>
//     </ThemeProvider>
//   );
// }

// export default SignUp;

import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BackEndUrl from "../api";
import { toast } from "react-toastify";
import logo from "../images/syc-logo.jpg";
import CircularProgress from "@mui/material/CircularProgress";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <a
        style={{ color: "inherit" }}
        target="_blank"
        href="https://shiftyourcargo.com/"
      >
        ShiftYourCargo
      </a>{" "}
      {""}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const logo1 = (
  <img
    src={logo}
    alt="Logo"
    style={{ width: "100%", height: "100%", objectFit: "cover" }}
  />
);

function SignUp() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  // const [formErrors, setFormErrors] = useState({
  //   firstName: false,
  //   lastName: false,
  //   email: false,
  //   password: false,
  // });
  const [formErrors, setFormErrors] = useState({});


  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   setLoading(true);

  //   const emailRegex = /^[^@]+@gmail\.com$/;
  //   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

  //   if (
  //     !data.get("firstName") ||
  //     !data.get("lastName") ||
  //     !emailRegex.test(data.email)||
  //     !passwordRegex.test(data.password)
  //   ) {
  //     setFormErrors({
  //       firstName: !data.get("firstName"),
  //       lastName: !data.get("lastName"),
  //       email: !data.get("email"),
  //       password: !data.get("password"),
  //     });
  //     setLoading(false);
  //     return;
  //   }

  //   const response = await fetch(`${BackEndUrl}/auth/register`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       firstName: data.get("firstName"),
  //       lastName: data.get("lastName"),
  //       email: data.get("email"),
  //       password: data.get("password"),
  //     }),
  //   });

  //   // console.log("response from  server: ",response)

  //   if (response.ok) {
  //     const result = await response.json();
  //     //  console.log(result.token);
  //     // Store the token in localStorage or state management solution
  //     localStorage.setItem("token", result.token);
  //     localStorage.setItem("email", data.get("email")); // Store user's email

  //     toast.success("Registration successful", {
  //       onClose: () => {
  //         navigate("/SignIn"); // Redirect to home page
  //       },
  //     });
  //   } else {
  //     if (response.status === 400) {
  //       toast.error("Email is alreayd registered. Please try with another Email.")
  //     } else {
  //       toast.error("Registration failed. Please try again.");
  //     }
  //     // console.error("Registration failed");
  //     // toast.error("Registration failed");
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // More general email regex
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/; // Password validation

    const email = data.get("email");
    const password = data.get("password");
    const firstName = data.get("firstName");
    const lastName = data.get("lastName");

    // Check for form errors
    const errors = {
      firstName: !firstName,
      lastName: !lastName,
      email: !email || !emailRegex.test(email),
      password: !password || !passwordRegex.test(password),
    };

    if (Object.values(errors).includes(true)) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${BackEndUrl}/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        localStorage.setItem("token", result.token);
        localStorage.setItem("email", email);

        toast.success("Registration successful", {
          onClose: () => {
            navigate("/SignIn");
          },
        });
      } else {
        const errorMessage = response.status === 400
          ? "Email is already registered. Please try another."
          : "Registration failed. Please try again.";
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, border: "1px solid black" }}>{logo1}</Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={formErrors.firstName}
                  helperText={
                    formErrors.firstName ? "FirstName is required" : ""
                  }
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  placeholder="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={formErrors.lastName}
                  helperText={formErrors.lastName ? "LastName is required" : ""}
                  required
                  fullWidth
                  id="lastName"
                  placeholder="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={formErrors.email}
                  helperText={formErrors.email ? "Invalid email format. Must be a valid Gmail address." : ""}
                  required
                  fullWidth
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={formErrors.password}
                  helperText={formErrors.password ? " Password must be at least 8 characters long, include uppercase and lowercase letters, and a special character" : ""}
                  required
                  fullWidth
                  name="password"
                  placeholder="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Box sx={{ position: "relative" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: "#000",
                  textTransform: "none",
                  "&:hover": {
                    bgcolor: "black",
                  }

                }}
                disabled={loading}
              >
                {loading ? "Signing up..." : "Sign Up"}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#000",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-8px",
                    marginLeft: "-70px",
                  }}
                />
              )}
            </Box>
            <Grid container justifyContent="flex-center">
              <Grid item>
                <Button
                  component={Link}
                  to="/SignIn"
                  variant="body2"
                  fullWidth
                  size="small"
                  sx={{
                    mt: 1,
                    mb: 2,
                    textTransform: "none",
                    "&:hover": {
                      textDecoration: "underline",
                      bgcolor: "#000",
                      color: "#fff",
                    },
                    fontSize: "13px",
                  }}
                >
                  Already have an account? Sign in
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

export default SignUp;

import img1 from "../../images/slider-1.jpg";

const DeliveryTeam = () => {
  return (
    // <div style={{ marginTop: "50px" }}>
    //   <div style={{ textAlign: "center", marginBottom: "2%" }}>
    //     <h5 style={{ fontWeight: 600, color: "#6A43C7", fontSize:"25px" }}>DELIVERY TEAM</h5>
    //     <h1 style={{ fontWeight: 500, fontSize:"20px"}}>Meet Our Delivery Team</h1>
    //   </div>
    //   <div style={{ display: "flex", justifyContent: "center", gap: "40px", flexWrap: "wrap" }}>
    //     <div style={{ flex: "1 1 300px", maxWidth: "300px", marginBottom: "20px" }}>
    //       <div>
    //         <div style={{ marginBottom: "10px" }}>
    //           <img src={img1} alt="Team Member" style={{ maxWidth: "100%", height: "auto" }} />
    //         </div>
    //         <div style={{ textAlign: "center" }}>
    //           <h5 style={{ fontWeight: 700, margin: 0 }}>Full Name</h5>
    //           <p>Designation</p>
    //         </div>
    //       </div>
    //     </div>
    //     <div style={{ flex: "1 1 300px", maxWidth: "300px", marginBottom: "20px" }}>
    //       <div>
    //         <div style={{ marginBottom: "10px" }}>
    //           <img src={img1} alt="Team Member" style={{ maxWidth: "100%", height: "auto" }} />
    //         </div>
    //         <div style={{ textAlign: "center" }}>
    //           <h5 style={{ fontWeight: 700, margin: 0 }}>Full Name</h5>
    //           <p>Designation</p>
    //         </div>
    //       </div>
    //     </div>
    //     <div style={{ flex: "1 1 300px", maxWidth: "300px", marginBottom: "20px" }}>
    //       <div>
    //         <div style={{ marginBottom: "10px" }}>
    //           <img src={img1} alt="Team Member" style={{ maxWidth: "100%", height: "auto" }} />
    //         </div>
    //         <div style={{ textAlign: "center" }}>
    //           <h5 style={{ fontWeight: 700, margin: 0 }}>Full Name</h5>
    //           <p>Designation</p>
    //         </div>
    //       </div>
    //     </div>
    //     <div style={{ flex: "1 1 300px", maxWidth: "300px", marginBottom: "20px" }}>
    //       <div>
    //         <div style={{ marginBottom: "10px" }}>
    //           <img src={img1} alt="Team Member" style={{ maxWidth: "100%", height: "auto" }} />
    //         </div>
    //         <div style={{ textAlign: "center" }}>
    //           <h5 style={{ fontWeight: 700, margin: 0 }}>Full Name</h5>
    //           <p>Designation</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div></div>
  );
};

export default DeliveryTeam;

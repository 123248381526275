

// import React, { useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   FormControl,
//   FormControlLabel,
//   InputLabel,
//   MenuItem,
//   Radio,
//   RadioGroup,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextareaAutosize,
//   TextField,
//   Typography,
// } from "@mui/material";
// import BackEndUrl from "../../../../api.js";

// const Customers = () => {
//   const [customerData, setCustomerData] = useState({
//     customerType: "",
//     primaryContact: "",
//     firstName: "",
//     lastName: "",
//     companyName: "",
//     customerDisplayName: "",
//     email: "",
//     workPhone: "",
//     mobilePhone: "",
//     gstTreatment: "",
//     gstInUin: "",
//     businessLegalName: "",
//     businessTradeName: "",
//     placeOfSupply: "",
//     pan: "",
//     taxPreference: "",
//     currency: "INR-Indian Rupee",
//     openingBalance: "",
//     paymentTerms: "",
//     document: "",
//     billingAddress: {
//       battention: "",
//       bcountry: "",
//       bstate: "",
//       bdistrict: "",
//       bstreet1: "",
//       bstreet2: "",
//       bzipCode: "",
//     },
//     shippingFromAddress: {
//       fattention: "",
//       fcountry: "",
//       fstate: "",
//       fdistrict: "",
//       fstreet1: "",
//       fstreet2: "",
//       fzipCode: "",
//     },
//     shippingToAddress: {
//       tattention: "",
//       tcountry: "",
//       tstate: "",
//       tdistrict: "",
//       tstreet1: "",
//       tstreet2: "",
//       tzipCode: "",
//     },
//     remarks:"",
//   });

//   const [contactPersons, setContactPersons] = useState([
//     {
//       id: 1,
//       salutation: "Mr.",
//       firstName: "",
//       middleName: "",
//       lastName: "",
//       email: "",
//       address: "",
//       workPhone: "",
//       mobile: "",
//     },
//   ]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setCustomerData({
//       ...customerData,
//       [name]: value,
//     });
//   };

//   const handleContactPersonChange = (e, id, field) => {
//     const updatedRows = contactPersons.map((row) => {
//       if (row.id === id) {
//         return { ...row, [field]: e.target.value };
//       }
//       return row;
//     });
//     setContactPersons(updatedRows);
//   };

//   const handleAddRow = () => {
//     const newRow = {
//       id: contactPersons.length + 1,
//       salutation: "Mr.",
//       firstName: "",
//       middleName: "",
//       lastName: "",
//       email: "",
//       address: "",
//       workPhone: "",
//       mobile: "",
//     };
//     setContactPersons([...contactPersons, newRow]);
//   };

//   const handleDeleteRow = (id) => {
//     if (contactPersons.length === 1) {
//       return;
//     }
//     const updatedRows = contactPersons.filter((row) => row.id !== id);
//     setContactPersons(updatedRows);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     const payload = {
//       ...customerData,
//       contactPersons,
//     };

//     try {
//       const response = await axios.post(`${BackEndUrl}/api/customer`, payload);
//       console.log("Success:", response.data);
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   return (
//     <Box sx={{ marginLeft:"180px" }}>
//       <Typography variant="h5">New Customer</Typography>
//       <hr />
//       <form onSubmit={handleSubmit}>
//         <Box sx={{ display: "flex", mb: "10px" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Customer Type
//           </Typography>
//           <RadioGroup
//             aria-label="customerType"
//             name="customerType"
//             value={customerData.customerType}
//             onChange={handleChange}
//             row
//           >
//             <FormControlLabel
//               value="business"
//               control={<Radio />}
//               label="Business"
//               labelPlacement="end"
//             />
//             <FormControlLabel
//               value="individual"
//               control={<Radio />}
//               label="Individual"
//               labelPlacement="end"
//             />
//           </RadioGroup>
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Primary Contact
//           </Typography>
//           <FormControl variant="outlined" size="small" sx={{ mr: 1 }}>
//             <Select
//               variant="outlined"
//               name="primaryContact"
//               value={customerData.primaryContact}
//               onChange={handleChange}
//               sx={{ width: "100px" }}
//             >
//               <MenuItem value="Mr.">Mr.</MenuItem>
//               <MenuItem value="Ms.">Ms.</MenuItem>
//               <MenuItem value="Mrs.">Mrs.</MenuItem>
//               <MenuItem value="Dr.">Dr.</MenuItem>
//             </Select>
//           </FormControl>
//           <TextField
//             required
//             variant="outlined"
//             size="small"
//             label="First Name"
//             name="firstName"
//             value={customerData.firstName}
//             onChange={handleChange}
//             sx={{ mr: 1 }}
//           />
//           <TextField
//             required
//             variant="outlined"
//             size="small"
//             label="Last Name"
//             name="lastName"
//             value={customerData.lastName}
//             onChange={handleChange}
//           />
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Company Name
//           </Typography>
//           <TextField
//             required
//             variant="outlined"
//             size="small"
//             name="companyName"
//             value={customerData.companyName}
//             onChange={handleChange}
//             fullWidth
//           />
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Customer Display Name
//           </Typography>
//           <TextField
//             required
//             variant="outlined"
//             size="small"
//             name="customerDisplayName"
//             value={customerData.customerDisplayName}
//             onChange={handleChange}
//             fullWidth
//           />
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Customer Email
//           </Typography>
//           <TextField
//             required
//             variant="outlined"
//             size="small"
//             name="email"
//             value={customerData.email}
//             onChange={handleChange}
//             fullWidth
//           />
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Customer Phone
//           </Typography>
//           <TextField
//             required
//             variant="outlined"
//             size="small"
//             label="Work Phone"
//             name="workPhone"
//             value={customerData.workPhone}
//             onChange={handleChange}
//             sx={{ mr: 1 }}
//           />
//           <TextField
//             required
//             variant="outlined"
//             size="small"
//             label="Mobile"
//             name="mobilePhone"
//             value={customerData.mobilePhone}
//             onChange={handleChange}
//           />
//         </Box>

//         <hr />
//         {/* Other Details */}
//         <Typography variant="h6">Other Details</Typography>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             GST Treatment
//           </Typography>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>Select a GST treatment</InputLabel>
//             <Select
//               variant="outlined"
//               size="small"
//               name="gstTreatment"
//               value={customerData.gstTreatment}
//               onChange={handleChange}
//               sx={{ width: "250px" }}
//             >
//               <MenuItem value="1">Registered Business - Regular</MenuItem>
//               <MenuItem value="2">Registered Business - Composition</MenuItem>
//               <MenuItem value="3">Unregistered Business</MenuItem>
//               <MenuItem value="4">Consumer</MenuItem>
//               <MenuItem value="5">Overseas</MenuItem>
//               <MenuItem value="6">Special Economic Zone</MenuItem>
//               <MenuItem value="7">Deemed Export</MenuItem>
//               <MenuItem value="8">Tax deductor</MenuItem>
//               <MenuItem value="9">SEZ Developer</MenuItem>
//             </Select>
//           </FormControl>
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             GSTIN/UIN
//           </Typography>
//           <TextField
//             variant="outlined"
//             size="small"
//             name="gstInUin"
//             value={customerData.gstInUin}
//             onChange={handleChange}
//             fullWidth
//           />
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Business Legal Name
//           </Typography>
//           <TextField
//             variant="outlined"
//             size="small"
//             name="businessLegalName"
//             value={customerData.businessLegalName}
//             onChange={handleChange}
//             fullWidth
//           />
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Business Trade Name
//           </Typography>
//           <TextField
//             variant="outlined"
//             size="small"
//             name="businessTradeName"
//             value={customerData.businessTradeName}
//             onChange={handleChange}
//             fullWidth
//           />
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Place of Supply
//           </Typography>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>Select a place of supply</InputLabel>
//             <Select
//               variant="outlined"
//               size="small"
//               name="placeOfSupply"
//               value={customerData.placeOfSupply}
//               onChange={handleChange}
//               sx={{ width: "250px" }}
//             >
//               <MenuItem value="1">Andaman and Nicobar Islands</MenuItem>
//               <MenuItem value="2">Andhra Pradesh</MenuItem>
//               <MenuItem value="3">Arunachal Pradesh</MenuItem>
//               <MenuItem value="4">Assam</MenuItem>
//               <MenuItem value="5">Bihar</MenuItem>
//               <MenuItem value="6">Chandigarh</MenuItem>
//               <MenuItem value="7">Chhattisgarh</MenuItem>
//               <MenuItem value="8">Dadra and Nagar Haveli</MenuItem>
//               <MenuItem value="9">Daman and Diu</MenuItem>
//               <MenuItem value="10">Delhi</MenuItem>
//               <MenuItem value="11">Goa</MenuItem>
//               <MenuItem value="12">Gujarat</MenuItem>
//               <MenuItem value="13">Haryana</MenuItem>
//               <MenuItem value="14">Himachal Pradesh</MenuItem>
//               <MenuItem value="15">Jammu and Kashmir</MenuItem>
//               <MenuItem value="16">Jharkhand</MenuItem>
//               <MenuItem value="17">Karnataka</MenuItem>
//               <MenuItem value="18">Kerala</MenuItem>
//               <MenuItem value="19">Ladakh</MenuItem>
//               <MenuItem value="20">Lakshadweep</MenuItem>
//               <MenuItem value="21">Madhya Pradesh</MenuItem>
//               <MenuItem value="22">Maharashtra</MenuItem>
//               <MenuItem value="23">Manipur</MenuItem>
//               <MenuItem value="24">Meghalaya</MenuItem>
//               <MenuItem value="25">Mizoram</MenuItem>
//               <MenuItem value="26">Nagaland</MenuItem>
//               <MenuItem value="27">Odisha</MenuItem>
//               <MenuItem value="28">Puducherry</MenuItem>
//               <MenuItem value="29">Punjab</MenuItem>
//               <MenuItem value="30">Rajasthan</MenuItem>
//               <MenuItem value="31">Sikkim</MenuItem>
//               <MenuItem value="32">Tamil Nadu</MenuItem>
//               <MenuItem value="33">Telangana</MenuItem>
//               <MenuItem value="34">Tripura</MenuItem>
//               <MenuItem value="35">Uttar Pradesh</MenuItem>
//               <MenuItem value="36">Uttarakhand</MenuItem>
//               <MenuItem value="37">West Bengal</MenuItem>
//             </Select>
//           </FormControl>
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             PAN
//           </Typography>
//           <TextField
//             variant="outlined"
//             size="small"
//             name="pan"
//             value={customerData.pan}
//             onChange={handleChange}
//             fullWidth
//           />
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Tax Preference
//           </Typography>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>Select a tax preference</InputLabel>
//             <Select
//               variant="outlined"
//               size="small"
//               name="taxPreference"
//               value={customerData.taxPreference}
//               onChange={handleChange}
//               sx={{ width: "250px" }}
//             >
//               <MenuItem value="1">Taxable</MenuItem>
//               <MenuItem value="2">Tax Exempt</MenuItem>
//               <MenuItem value="3">Non-Taxable</MenuItem>
//             </Select>
//           </FormControl>
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Currency
//           </Typography>
//           <FormControl variant="outlined" size="small">
//             {/* <InputLabel>Select a currency</InputLabel> */}
//             <Select
//               variant="outlined"
//               size="small"
//               name="currency"
//               value={customerData.currency}
//               onChange={handleChange}
//               sx={{ width: "250px" }}
//             >
//               <MenuItem value="INR-Indian Rupee">INR-Indian Rupee</MenuItem>
//             </Select>
//           </FormControl>
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Opening Balance
//           </Typography>
//           <TextField
//             variant="outlined"
//             size="small"
//             name="openingBalance"
//             value={customerData.openingBalance}
//             onChange={handleChange}
//             fullWidth
//           />
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px", alignItems: "center" }}>
//           <Typography variant="body1" sx={{ width: "150px" }}>
//             Payment Terms
//           </Typography>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>Select payment terms</InputLabel>
//             <Select
//               variant="outlined"
//               size="small"
//               name="paymentTerms"
//               value={customerData.paymentTerms}
//               onChange={handleChange}
//               sx={{ width: "250px" }}
//             >
//               <MenuItem value="1">Due on Receipt</MenuItem>
//               <MenuItem value="2">Net 15</MenuItem>
//               <MenuItem value="3">Net 30</MenuItem>
//               <MenuItem value="4">Net 45</MenuItem>
//               <MenuItem value="5">Net 60</MenuItem>
//             </Select>
//           </FormControl>
//         </Box>
//         <Box sx={{ display: "flex", mb: "10px" }}>
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               mr: "70px",
//             }}
//           >
//             <Typography variant="p" sx={{ width: "90px" }}>
//               Document
//             </Typography>
//           </Box>
//           <input
//             type="file"
//             name="document"
//             value={customerData.document}
//             onChange={handleChange}
//           />
//         </Box>

//         <hr />
//         {/* Address  */}
//         <Box>
//           <Box sx={{ display: "inline-block", mr: "40px" }}>
//             <Typography variant="h6">Billing Address</Typography>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "40px",
//                 }}
//               >
//                 <Typography variant="p">Attention</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.billingAddress.battention}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "50px",
//                 }}
//               >
//                 <Typography variant="p">Country</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.billingAddress.bcountry}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "70px",
//                 }}
//               >
//                 <Typography variant="p">State</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.billingAddress.bstate}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "55px",
//                 }}
//               >
//                 <Typography variant="p">District</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.billingAddress.bdistrict}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "start",
//                   mr: "40px",
//                 }}
//               >
//                 <Typography variant="p">Address</Typography>
//               </Box>

//               <Box sx={{ display: "flex", flexDirection: "column", ml: "8px" }}>
//                 <TextField
//                   variant="outlined"
//                   size="small"
//                   label="street 1"
//                   sx={{ mb: "5px" }}
//                   value={customerData.billingAddress.bstreet1}
//                   onChange={handleChange}
//                 />
//                 <TextField
//                   variant="outlined"
//                   size="small"
//                   label="street 2"
//                   value={customerData.billingAddress.bstreet2}
//                   onChange={handleChange}
//                 />
//               </Box>
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "45px",
//                 }}
//               >
//                 <Typography variant="p">Zip code</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.billingAddress.bzipCode}
//                 onChange={handleChange}
//               />
//             </Box>
//           </Box>

//           <Box sx={{ display: "inline-block", mr: "40px" }}>
//             <Typography variant="h6">Shipping From</Typography>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "40px",
//                 }}
//               >
//                 <Typography variant="p">Attention</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingFromAddress.fattention}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "50px",
//                 }}
//               >
//                 <Typography variant="p">Country</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingFromAddress.fcountry}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "70px",
//                 }}
//               >
//                 <Typography variant="p">State</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingFromAddress.fstate}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "55px",
//                 }}
//               >
//                 <Typography variant="p">District</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingFromAddress.fdistrict}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "start",
//                   mr: "40px",
//                 }}
//               >
//                 <Typography variant="p">Address</Typography>
//               </Box>

//               <Box sx={{ display: "flex", flexDirection: "column", ml: "8px" }}>
//                 <TextField
//                   variant="outlined"
//                   size="small"
//                   label="street 1"
//                   sx={{ mb: "5px" }}
//                   value={customerData.shippingFromAddress.fstreet1}
//                   onChange={handleChange}
//                 />
//                 <TextField
//                   variant="outlined"
//                   size="small"
//                   label="street 1"
//                   value={customerData.shippingFromAddress.fstreet2}
//                   onChange={handleChange}
//                 />
//               </Box>
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "45px",
//                 }}
//               >
//                 <Typography variant="p">Zip code</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingFromAddress.fzipCode}
//                 onChange={handleChange}
//               />
//             </Box>
//           </Box>

//           <Box sx={{ display: "inline-block" }}>
//             <Typography variant="h6">Shipping To</Typography>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "40px",
//                 }}
//               >
//                 <Typography variant="p">Attention</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingToAddress.tattention}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "50px",
//                 }}
//               >
//                 <Typography variant="p">Country</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingToAddress.tcountry}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "70px",
//                 }}
//               >
//                 <Typography variant="p">State</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingToAddress.tstate}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "55px",
//                 }}
//               >
//                 <Typography variant="p">District</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingToAddress.tdistrict}
//                 onChange={handleChange}
//               />
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "start",
//                   mr: "40px",
//                 }}
//               >
//                 <Typography variant="p">Address</Typography>
//               </Box>

//               <Box sx={{ display: "flex", flexDirection: "column", ml: "8px" }}>
//                 <TextField
//                   variant="outlined"
//                   size="small"
//                   label="street 1"
//                   sx={{ mb: "5px" }}
//                   value={customerData.shippingToAddress.tstreet1}
//                   onChange={handleChange}
//                 />
//                 <TextField
//                   variant="outlined"
//                   size="small"
//                   label="street 2"
//                   value={customerData.shippingToAddress.tstreet2}
//                   onChange={handleChange}
//                 />
//               </Box>
//             </Box>

//             <Box sx={{ display: "flex", mb: "10px" }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   mr: "45px",
//                 }}
//               >
//                 <Typography variant="p">Zip code</Typography>
//               </Box>

//               <TextField
//                 variant="outlined"
//                 size="small"
//                 value={customerData.shippingToAddress.tzipCode}
//                 onChange={handleChange}
//               />
//             </Box>
//           </Box>
//         </Box>

//         <hr />
//         {/* contact persons  */}

//         <Typography variant="h6">Contact Persons</Typography>
//         <TableContainer>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Salutation</TableCell>
//                 <TableCell>First Name</TableCell>
//                 <TableCell>Middle Name</TableCell>
//                 <TableCell>Last Name</TableCell>
//                 <TableCell>Email</TableCell>
//                 <TableCell>Work Phone</TableCell>
//                 <TableCell>Mobile</TableCell>
//                 <TableCell>Actions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {contactPersons.map((row) => (
//                 <TableRow key={row.id}>
//                   <TableCell>
//                     <FormControl variant="outlined" size="small">
//                       <Select
//                         value={row.salutation}
//                         onChange={(e) =>
//                           handleContactPersonChange(e, row.id, "salutation")
//                         }
//                       >
//                         <MenuItem value="Mr.">Mr.</MenuItem>
//                         <MenuItem value="Ms.">Ms.</MenuItem>
//                         <MenuItem value="Mrs.">Mrs.</MenuItem>
//                         <MenuItem value="Dr.">Dr.</MenuItem>
//                       </Select>
//                     </FormControl>
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       variant="outlined"
//                       size="small"
//                       value={row.firstName}
//                       onChange={(e) =>
//                         handleContactPersonChange(e, row.id, "firstName")
//                       }
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       variant="outlined"
//                       size="small"
//                       value={row.middleName}
//                       onChange={(e) =>
//                         handleContactPersonChange(e, row.id, "middleName")
//                       }
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       variant="outlined"
//                       size="small"
//                       value={row.lastName}
//                       onChange={(e) =>
//                         handleContactPersonChange(e, row.id, "lastName")
//                       }
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       variant="outlined"
//                       size="small"
//                       value={row.email}
//                       onChange={(e) =>
//                         handleContactPersonChange(e, row.id, "email")
//                       }
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       variant="outlined"
//                       size="small"
//                       value={row.workPhone}
//                       onChange={(e) =>
//                         handleContactPersonChange(e, row.id, "workPhone")
//                       }
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       variant="outlined"
//                       size="small"
//                       value={row.mobile}
//                       onChange={(e) =>
//                         handleContactPersonChange(e, row.id, "mobile")
//                       }
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={() => handleDeleteRow(row.id)}
//                     >
//                       Delete
//                     </Button>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={handleAddRow}
//           sx={{ mt: "10px" }}
//           size="small"
//         >
//           Add Contact Person
//         </Button>

//         <Box>
//           <Typography variant="h6">Remarks</Typography>
//           <TextareaAutosize
//             placeholder="Enter your feedback here..."
//             minRows={4}
//             style={{ width: "600px", border: "1px solid grey" }}
//             value={customerData.remarks}
//             onChange={handleChange}
//           />
//         </Box>

//         <Box
//           sx={{ display: "flex", justifyContent: "flexj-start", mt: "20px" }}
//         >
//           <Button variant="contained" color="primary" type="submit">
//             Save
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default Customers;


import React from 'react'

const Customers = () => {
  return (
    <div style={{ textAlign: "center", marginLeft:"180px" }}>
      <h3 style={{ color: "skyblue" }}>Hey Welcome to Customers Page!!!</h3>

      <br />
      <h4 style={{ color: "red" }}>still working on this page</h4>
    </div>
  )
}

export default Customers;

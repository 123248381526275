import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  MenuItem,
  Grid,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const Dimensions = ({
  dimensionsData,
  handleDimensionsChange,
  handleQuantityDetailsChange,
  addQuantityDetail,
  removeQuantityDetail,
}) => {
  const { totalWeight, dimensionsUnit, quantityDetails, totalQuantity } = dimensionsData;

  const isLastQuantityDetailFilled =
    quantityDetails[quantityDetails.length - 1].quantity !== "" &&
    quantityDetails[quantityDetails.length - 1].length !== "" &&
    quantityDetails[quantityDetails.length - 1].height !== "" &&
    quantityDetails[quantityDetails.length - 1].width !== "";

  const getTotalQuantityInDimensions = () => {
    return quantityDetails.reduce(
      (total, detail) => total + Number(detail.quantity || 0),
      0
    );
  };

  const totalQuantityInDimensions = getTotalQuantityInDimensions();

  const message =
    totalQuantityInDimensions < totalQuantity
      ? `${totalQuantity - totalQuantityInDimensions} package(s) left`
      : totalQuantityInDimensions > totalQuantity
      ? "Exceeds number of packages"
      : "";

  const messageStyle =
    totalQuantityInDimensions < totalQuantity
      ? { color: "skyblue" }
      : totalQuantityInDimensions > totalQuantity
      ? { color: "red" }
      : {};

  const isAddMoreDisabled =
    totalQuantityInDimensions >= totalQuantity || !isLastQuantityDetailFilled;

  return (
    <>
      <Box
        sx={{
          p: 2,
          mt: "10px",
          mb: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            No Of Items
          </Typography>
          <TextField
            fullWidth
            name="totalQuantity"
            id="totalQuantity"
            variant="outlined"
            size="small"
            sx={{ width: "70%" }}
            value={totalQuantity}
            onChange={handleDimensionsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
            Weight
          </Typography>
          <TextField
            fullWidth
            name="totalWeight"
            id="totalWeight"
            size="small"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "70%" }}
            value={totalWeight}
            onChange={handleDimensionsChange}
          />
        </Grid>

        <Box>
          <Typography
            component="span"
            sx={{ color: "rgb(70, 70, 70)", ml: "30px" }}
          >
            Total no of boxes: <b>{totalQuantity}</b>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: 2,
          mt: "10px",
          mb: "10px",
        }}
      >
        <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
          Dimensions
          <span style={{ color: "rgb(110, 110, 110)" }}>*</span>
        </Typography>

        <Box sx={{ mt: "20px", mb: "20px" }}>
          <InputAdornment position="end">
            <TextField
              name="dimensionsUnit"
              select
              variant="standard"
              size="medium"
              value={dimensionsUnit}
              onChange={handleDimensionsChange}
            >
              <MenuItem value="Centimeter">Centimeter</MenuItem>
              <MenuItem value="Inch">Inch</MenuItem>
              <MenuItem value="Feet">Feet</MenuItem>
            </TextField>
          </InputAdornment>
        </Box>

        <Box sx={{ bgcolor: "#fff", p: 2 }}>
          {quantityDetails &&
            quantityDetails.length > 0 &&
            quantityDetails.map((quantityDetail, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  name="quantity"
                  label="Quantity"
                  variant="standard"
                  fullWidth
                  required
                  style={{ marginRight: "10px" }}
                  value={quantityDetail.quantity || ""}
                  onChange={(e) =>
                    handleQuantityDetailsChange(index, "quantity", e.target.value)
                  }
                />
                <TextField
                  name="length"
                  label="Length"
                  variant="standard"
                  fullWidth
                  required
                  style={{ marginRight: "10px" }}
                  value={quantityDetail.length || ""}
                  onChange={(e) =>
                    handleQuantityDetailsChange(index, "length", e.target.value)
                  }
                />
                <TextField
                  name="height"
                  label="Height"
                  variant="standard"
                  fullWidth
                  required
                  style={{ marginRight: "10px" }}
                  value={quantityDetail.height || ""}
                  onChange={(e) =>
                    handleQuantityDetailsChange(index, "height", e.target.value)
                  }
                />
                <TextField
                  name="width"
                  label="Width"
                  variant="standard"
                  fullWidth
                  required
                  style={{ marginRight: "10px" }}
                  value={quantityDetail.width || ""}
                  onChange={(e) =>
                    handleQuantityDetailsChange(index, "width", e.target.value)
                  }
                />
                <IconButton
                  aria-label="delete"
                  onClick={() => removeQuantityDetail(index)}
                  style={{
                    visibility: quantityDetails.length > 1 ? "visible" : "hidden",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          <Box>
            <Button
              onClick={addQuantityDetail}
              disabled={isAddMoreDisabled}
              style={{ marginBottom: "10px" }}
              variant="contained"
            >
              Add More
            </Button>
            <Typography variant="caption" style={messageStyle}>
              {message}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dimensions;

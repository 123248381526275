import React from "react";

const CancellationPolicy = () => {
  return (
    <div className="container" style={{ fontSize: "10px" }}>
      <h1 style={{ fontSize: "18px", marginBottom: "20px" }}>
        Cancellation and Refund Policy
      </h1>
      <p>
        <b style={{ fontSize: "13px", marginBottom: "20px" }}>Effective Date:</b> 24th August 2024
      </p>
      <p>
        <b>Shift Your Cargo</b> or Tech Avign Pvt Ltd ("we," "us," or "our") is committed to providing reliable and efficient cargo transportation services. This Cancellation and Refund Policy outlines the terms and conditions under which cancellations and refunds are handled.
      </p>
      <h1 style={{ fontSize: "15px", marginBottom: "5px" }}>1. Scope</h1>
      <p>
        This policy applies to all services provided by Shift Your Cargo or TAPL or Tech Avign Pvt Ltd, including but not limited to cargo transportation, storage, and related logistics services.
      </p>
      <h1 style={{ fontSize: "15px", marginBottom: "5px" }}>2. Cancellation Policy</h1>
      <h1 style={{ fontSize: "13px", marginBottom: "5px" }}>2.1. Cancellation by the Customer</h1>
      <p>
        <b>● Cancellation Requests:</b> Customers may request to cancel their service booking by contacting our customer service team via <a href="mailto:cancel@shiftyourcargo.com" className="text-blue-500">cancel@shiftyourcargo.com</a> or call at +91 9900 2525 85.
      </p>
      <p>
        <b>● Cancellation Timeframes:</b>
        <ul>
          <li><b>More than 48 Hours Before Pickup:</b> Cancellations made more than 48 hours prior to the scheduled pickup time are eligible for a full refund, minus any applicable service fees.</li>
          <li><b>Within 12 Hours Before Pickup:</b> Cancellations made within 12 hours of the scheduled pickup time may incur a cancellation fee of 10%.</li>
          <li><b>Upto 12 Hours of the Pickup:</b> Cancellations made upto 12 hours of the scheduled pickup time may incur a cancellation fee of 50%.</li>
          <li><b>After Service Initiation:</b> If the cancellation is requested after the service has commenced (e.g., cargo has been picked up or is in transit), a full or partial refund may not be available. Specific circumstances will be evaluated on a case-by-case basis.</li>
        </ul>
      </p>
      <p>
        <b>● Procedure:</b> To cancel a service, please provide the following information:
        <ul>
          <li>Your booking/reference number</li>
          <li>Date and time of the scheduled service</li>
          <li>Reason for cancellation</li>
        </ul>
      </p>
      <h1 style={{ fontSize: "13px", marginBottom: "5px" }}>2.2. Cancellation by Shift Your Cargo</h1>
      <p>
        We reserve the right to cancel or reschedule services under certain conditions, including but not limited to:
        <ul>
          <li><b>Unforeseen Circumstances:</b> Events beyond our control, such as natural disasters, strikes, or regulatory changes.</li>
          <li><b>Safety Concerns:</b> Situations where continuing the service poses a safety risk to personnel or cargo.</li>
          <li><b>Non-Compliance:</b> If the customer fails to comply with our terms and conditions or provides inaccurate information.</li>
        </ul>
      </p>
      <p>
        <b>● In the event of a cancellation by Shift Your Cargo:</b>
        <ul>
          <li>We will notify the customer as soon as possible.</li>
          <li>Customers will receive a full refund for any prepaid services that are cancelled.</li>
          <li>We will make reasonable efforts to reschedule the service at a convenient time for the customer.</li>
        </ul>
      </p>
      <h1 style={{ fontSize: "15px", marginBottom: "5px" }}>3. Refund Policy</h1>
      <h1 style={{ fontSize: "13px", marginBottom: "5px" }}>3.1. Eligibility for Refunds</h1>
      <p>
        Refunds are issued under the following circumstances:
        <ul>
          <li><b>Service Cancellation:</b> As outlined in the Cancellation Policy, eligible cancellations will result in a refund based on the timing and reasons for cancellation.</li>
          <li><b>Service Not Rendered:</b> If Shift Your Cargo is unable to provide the service as agreed without any fault on the part of the customer.</li>
          <li><b>Lost Cargo:</b> If cargo is lost due to negligence on our part, customers may be eligible for a refund or compensation, subject to the terms of our <a href="#">Insurance Policy/Terms of Service</a>.</li>
        </ul>
      </p>
      <h1 style={{ fontSize: "13px", marginBottom: "5px" }}>3.2. Non-Refundable Situations</h1>
      <p>
        Refunds are not available in the following cases:
        <ul>
          <li><b>Change of Mind:</b> Cancellations due to a change of mind after the service has been scheduled or initiated.</li>
          <li><b>Force Majeure:</b> Cancellations due to events beyond our control where we are unable to fulfill the service obligations.</li>
          <li><b>Non-Compliance by Customer:</b> If the customer fails to meet their obligations, such as providing necessary information or access, leading to the inability to perform the service.</li>
        </ul>
      </p>
      <h1 style={{ fontSize: "13px", marginBottom: "5px" }}>3.3. Refund Process</h1>
      <p>
        To request a refund, customers must:
        <ul>
          <li><b>Contact Us:</b> Reach out to our customer service team via <a href="mailto:cancel@shiftyourcargo.com" className="text-blue-500">cancel@shiftyourcargo.com</a> within 48 hours of the scheduled pickup of the service cancellation or issue.</li>
          <li><b>Provide Details:</b> Supply necessary information, including booking/reference number, date of service, and reason for the refund request.</li>
          <li><b>Review Process:</b> Our team will review the request and determine eligibility based on the policies outlined above.</li>
        </ul>
      </p>
      <h1 style={{ fontSize: "13px", marginBottom: "5px" }}>3.4. Refund Timeline</h1>
      <p>
        Once a refund is approved:
        <ul>
          <li><b>Processing Time:</b> Refunds will be processed within 7 days of the cancellation.</li>
          <li><b>Method of Refund:</b> Refunds will be issued using the same payment method used for the original transaction unless otherwise agreed upon.</li>
        </ul>
      </p>
      <h1 style={{ fontSize: "15px", marginBottom: "5px" }}>4. Amendments to This Policy</h1>
      <p>
        We may update this Cancellation and Refund Policy from time to time. Any changes will be posted on this page with an updated "Effective Date." We encourage customers to review this policy periodically to stay informed about our practices.
      </p>
      <h1 style={{ fontSize: "15px", marginBottom: "5px" }}>5. Contact Us</h1>
      <p>
        If you have any questions or concerns about this Cancellation and Refund Policy, please contact us at:
        <ul>
          <li><b>Email:</b> <a href="mailto:request@shiftyourcargo.com" className="text-blue-500">request@shiftyourcargo.com</a></li>
          <li><b>Phone:</b> +91 9900 2525 85</li>
          <li><b>Address:</b> Shift Your Cargo - No 59, 2nd Main, Rukmini Nagar, Nagasandra post, Bangalore - 560073</li>
        </ul>
      </p>
    </div>

  );
};

export default CancellationPolicy;

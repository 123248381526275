import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

function createData(name, calories, fat, carbs, protein, tat) {
  return { name, calories, fat, carbs, protein,tat };
}

const rows = [
  createData(1, 'Chennai', 'any except liquid', '17ft Canter', 15000, '1 to 2 days'),
  createData(2, 'Hyderabad', 'any except liquid', '17ft Canter', 25000, '1 to 2 days'),
  createData(3, 'New Delhi', 'any except liquid', '17ft Canter', 75000, '5 to 6 days'),
  createData(4, 'Mumbai', 'any except liquid', '17ft Canter', 35000, '3 to 4 days'),
  createData(5, 'Pune', 'any except liquid', '17ft Canter', 32000, '3 to 4 days'),
  createData(6, 'Patna', 'any except liquid', '17ft Canter', 110000, '6 to 7 days'),
  createData(7, 'Kolkata', 'any except liquid', '17ft Canter', 95000, '5 to 6 days'),
  createData(8, 'Lucknow', 'any except liquid', '17ft Canter', 85000, '5 to 6 days'),
  createData(9, 'Jaipur', 'any except liquid', '17ft Canter', 70000, '5 to 6 days'),
  createData(10, 'Ahmedabad', 'any except liquid', '17ft Canter', 60000, '4 to 5 days'),
];

export default function PriceList() {
  return (
    <>
    <Typography variant='h5' sx={{textAlign:"center", mb:"20px", textDecoration:"underline", fontWeight:"bold", color:"#6A43C7"}}>Price List</Typography>
     <TableContainer sx={{
      display: "flex", 
      justifyContent: "center"
    }} >
      <Table sx={{ maxWidth: 850, border:"1px solid #F9F9F9"
      }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Sl No</TableCell>
            <TableCell align="right">Destination</TableCell>
            <TableCell align="right">Cargo Type</TableCell>
            <TableCell align="right">Vehicle Type</TableCell>
            <TableCell align="right">Rate</TableCell>
            <TableCell align="right">TAT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
              <TableCell align="right">{row.tat}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer></>
   
  );
}

import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const PaymentC = ({ setPaymentCData }) => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const [riskCoverage, setRiskCoverage] = useState("");
  const [policyNo, setPolicyNo] = useState("");
  const [validity, setValidity] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [chequeDD, setChequeDD] = useState("");
  const [bankName, setBankName] = useState("");
  const [amount, setAmount] = useState("");

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleRiskCoverageChange = (event) => {
    setRiskCoverage(event.target.value);
  };

  const handlePolicyNoChange = (event) => {
    setPolicyNo(event.target.value);
  };

  const handleValidityChange = (event) => {
    setValidity(event.target.value);
  };

  const handleModeOfPaymentChange = (event) => {
    setModeOfPayment(event.target.value);
  };

  const handleChequeDDChange = (event) => {
    setChequeDD(event.target.value);
  };

  const handleBankNameChange = (event) => {
    setBankName(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  // Update parent component state with PaymentC data
  React.useEffect(() => {
    setPaymentCData({
      paymentMethod,
      riskCoverage,
      policyNo,
      validity,
      modeOfPayment,
      chequeDD,
      bankName,
      amount,
    });
  }, [paymentMethod, riskCoverage, policyNo, validity, modeOfPayment, chequeDD, bankName, amount, setPaymentCData]);

  return (
    <>
      <Box
        sx={{
          width: "40%",
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
          Payment Type
        </Typography>

        <RadioGroup aria-label="PaymentMethod" name="paymentMethod" row value={paymentMethod} onChange={handlePaymentMethodChange}>
          <FormControlLabel
            value="paid"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="Paid"
            labelPlacement="end"
            required
          />

          <FormControlLabel
            value="toPay"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="To Pay"
            labelPlacement="end"
            required
          />
          <FormControlLabel
            value="tbb"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="TBB"
            labelPlacement="end"
            required
          />
        </RadioGroup>
      </Box>

      <Box
        sx={{
          width: "40%",
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
          Risk Coverage
        </Typography>

        <RadioGroup aria-label="Risk Coverage" name="riskCoverage" row value={riskCoverage} onChange={handleRiskCoverageChange}>
          <FormControlLabel
            value="ownerRisk"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="Owner's Risk"
            labelPlacement="end"
            required
          />

          <FormControlLabel
            value="Carrier's Risk"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="carrerRisk"
            labelPlacement="end"
            required
          />
        </RadioGroup>
      </Box>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
              Policy No
            </Typography>
            <TextField
              fullWidth
              name="policyNo"
              id="policyNo"
              variant="outlined"
              size="small"
              sx={{ width: "70%" }}
              value={policyNo}
              onChange={handlePolicyNoChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
              Validity
            </Typography>
            <TextField
              fullWidth
              name="validity"
              id="validity"
              variant="outlined"
              size="small"
              sx={{ width: "70%" }}
              value={validity}
              onChange={handleValidityChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "60%",
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
          Mode Of Payment
        </Typography>

        <RadioGroup aria-label="Risk Coverage" name="riskCoverage" row value={modeOfPayment} onChange={handleModeOfPaymentChange}>
          <FormControlLabel
            value="cash"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="Cash"
            labelPlacement="end"
            required
          />

          <FormControlLabel
            value="cheque"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="Cheque"
            labelPlacement="end"
            required
          />
          <FormControlLabel
            value="dd"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="DD"
            labelPlacement="end"
            required
          />

          <FormControlLabel
            value="neft/rtgs"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="NEFT/RTGS"
            labelPlacement="end"
            required
          />
        </RadioGroup>
      </Box>

      <Box sx={{ width: "100%", mt: 2 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
              Cheque/DD NO
            </Typography>
            <TextField
              fullWidth
              name="chequeDD"
              id="chequeDD"
              variant="outlined"
              size="small"
              sx={{ width: "70%" }}
              value={chequeDD}
              onChange={handleChequeDDChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
              Bank Name
            </Typography>
            <TextField
              fullWidth
              name="bankName"
              id="bankname"
              size="small"
              variant="outlined"
              sx={{ width: "70%" }}
              value={bankName}
              onChange={handleBankNameChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
              Amount
            </Typography>
            <TextField
              fullWidth
              name="amount"
              id="amount"
              size="small"
              variant="outlined"
              sx={{ width: "70%" }}
              value={amount}
              onChange={handleAmountChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PaymentC;












// import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
// import React from "react";

// const PaymentC = () => {
//   return (
//     <>
//       <Box
//         sx={{
//           width: "40%",
//           mt: 2,
//           display: "flex",
//           justifyContent: "space-between",
//         }}
//       >
//         <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
//           Payment Type
//         </Typography>

//         <RadioGroup aria-label="PaymentMethod" name="paymentMethod" row>
//           <FormControlLabel
//             value="paid"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="Paid"
//             labelPlacement="end"
//             required
//           />

//           <FormControlLabel
//             value="toPay"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="To Pay"
//             labelPlacement="end"
//             required
//           />
//           <FormControlLabel
//             value=""
//             tbb
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="TBB"
//             labelPlacement="end"
//             required
//           />
//         </RadioGroup>
//       </Box>

//       <Box
//         sx={{
//           width: "40%",
//           mt: 2,
//           display: "flex",
//           justifyContent: "space-between",
//         }}
//       >
//         <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
//           Risk Coverage
//         </Typography>

//         <RadioGroup aria-label="Risk Coverage" name="riskCoverage" row>
//           <FormControlLabel
//             value="ownerRisk"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="Owner's Risk"
//             labelPlacement="end"
//             required
//           />

//           <FormControlLabel
//             value="Carrier's Risk"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="carrerRisk"
//             labelPlacement="end"
//             required
//           />
//         </RadioGroup>
//       </Box>
//       <Box sx={{ width: "100%", mt: 2 }}>
//         <Grid container spacing={2}>
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             sx={{ display: "flex", alignItems: "center" }}
//           >
//             <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//               Policy No
//             </Typography>
//             <TextField
//               fullWidth
//               name="policyNo"
//               id="policyNo"
//               variant="outlined"
//               size="small"
//               sx={{ width: "70%" }}
//             />
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             sx={{ display: "flex", alignItems: "center" }}
//           >
//             <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//               Validity
//             </Typography>
//             <TextField
//               fullWidth
//               name="validity"
//               id="validity"
//               variant="outlined"
//               size="small"
//               sx={{ width: "70%" }}
//             />
//           </Grid>
//         </Grid>
//       </Box>

//       <Box
//         sx={{
//           width: "60%",
//           mt: 2,
//           display: "flex",
//           justifyContent: "space-between",
//         }}
//       >
//         <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
//           Mode Of Payment
//         </Typography>

//         <RadioGroup aria-label="Risk Coverage" name="riskCoverage" row>
//           <FormControlLabel
//             value="cash"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="Cash"
//             labelPlacement="end"
//             required
//           />

//           <FormControlLabel
//             value="cheque"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="Cheque"
//             labelPlacement="end"
//             required
//           />
//           <FormControlLabel
//             value="dd"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="DD"
//             labelPlacement="end"
//             required
//           />

//           <FormControlLabel
//             value="neft/rtgs"
//             control={
//               <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
//             }
//             label="NEFT/RTGS"
//             labelPlacement="end"
//             required
//           />
//         </RadioGroup>
//       </Box>

//       <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
//         <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//           Cheque/DD NO
//         </Typography>
//         <TextField
//           fullWidth
//           name="chequeDD"
//           id="chequeDD"
//           variant="outlined"
//           size="small"
//           sx={{ width: "70%" }}
//         />
//         <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//           Bank Name
//         </Typography>
//         <TextField
//           fullWidth
//           name="bankName"
//           id="bankname"
//           size="small"
//           variant="outlined"
//           sx={{ width: "70%" }}
//         />
//         <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>
//           Amount
//         </Typography>
//         <TextField
//           fullWidth
//           name="amount"
//           id="amount"
//           size="small"
//           variant="outlined"
//           sx={{ width: "70%" }}
//         />
//       </Grid>
//     </>
//   );
// };

// export default PaymentC;

import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React from "react";

const PackageInfoGstProductType = ({packageInfo, setPackageInfo}) => {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPackageInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>GST No</Typography>
          <TextField
            fullWidth
            name="gstNo1"
            id="gstNo"
            variant="outlined"
            size="small"
            sx={{ width: "70%" }}
            value={packageInfo.gstNo1}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>GST No</Typography>
          <TextField
            fullWidth
            name="gstNo"
            id="gstNo"
            variant="outlined"
            size="small"
            sx={{ width: "70%" }}
            value={packageInfo.gstNo}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Typography variant="h6" gutterBottom>Package Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>Invoice No</Typography>
            <TextField
              fullWidth
              name="invoiceNo"
              id="invoiceNo"
              variant="outlined"
              size="small"
              sx={{ width: "70%" }}
              value={packageInfo.invoiceNo}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>Invoice Value</Typography>
            <TextField
              fullWidth
              name="invoiceValue"
              id="invoiceValue"
              variant="outlined"
              size="small"
              sx={{ width: "70%" }}
              value={packageInfo.invoiceValue}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>Invoice Date</Typography>
            <TextField
              fullWidth
              name="invoiceDate"
              id="invoiceDate"
              type="date"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: "70%" }}
              value={packageInfo.invoiceDate}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>E Way Bill No</Typography>
            <TextField
              fullWidth
              name="eWayBillNo"
              id="eWayBillNo"
              variant="outlined"
              size="small"
              sx={{ width: "70%" }}
              value={packageInfo.eWayBillNo}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom sx={{ width: "30%" }}>E Way Bill Validity</Typography>
            <TextField
              fullWidth
              name="eWayBillValidity"
              id="eWayBillValidity"
              size="small"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: "70%" }}
              value={packageInfo.eWayBillValidity}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: "50%",
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
          Product Type
        </Typography>
        <RadioGroup
          aria-label="productType"
          name="productType"
          row
          value={packageInfo.productType}
          onChange={handleInputChange}
        >
          <FormControlLabel
            value="commercial"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="Commercial"
            labelPlacement="end"
            required
          />
          <FormControlLabel
            value="nonCommercial"
            control={
              <Radio sx={{ "&.Mui-checked": { color: "rgb(240, 80, 60)" } }} />
            }
            label="Non Commercial"
            labelPlacement="end"
            required
          />
        </RadioGroup>
      </Box>
    </>
  );
};

export default PackageInfoGstProductType;

import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

//pages
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import ScrollToTop from "./components/scrollTop/ScrollToTop";
import HomePage from "./pages/homepage/Homepage";
import ServicePage from "./pages/servicesPage/ServicePage";
import ContactPage from "./pages/contactpage/ContactPage";
import TrackPage from "./pages/track/Track";
import User from "./pages/user/User";
import Footer from "./components/footer/Footer";
import TermsConditions from "./pages/terms-conditions/TermsConditions";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import CancellationPolicy from "./pages/cancellationpolicy/CancellationPolicy";
import PageNotFound from "./components/pageNotFound/PageNotFound";

import AllOrders from "./pages/admin/orders/allOrders/AllOrders";
import CreateNewOrder from "./pages/admin/orders/createNewOrder/CreateNewOrder";
import CreateBulkOrder from "./pages/admin/orders/createBulkOrder/CreateBulkOrders";
import Customers from "./pages/admin/accounts/customers/Customers";
import Invoices from "./pages/admin/accounts/invoices/Invoices";
import Expenses from "./pages/admin/accounts/expenses/Expenses";
import WareHouses from "./pages/admin/pickuplocations/WareHouses";
import OrderDetails from "./pages/admin/orders/orderDetails/OrderDetails";
import EditDetails from "./pages/admin/orders/editDetails/EditDetails";
import UpdateOrderStatus from "./pages/admin/orders/updateorderstatus/UpdateOrderStatus";
import PickUpRequest from "./pages/admin/pickuprequest/PickUpRequest"
import RaiseRequest from "./pages/raiseRequest/RaiseRequest";
import PdfFile from "./pages/admin/orders/orderDetails/PdfFile";

//Layouts
import Appbar from "./components/navbar/Appbar";
import AdminLayout from "./pages/admin/admin profile/AdminLayout";

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}



function AppContent() {
  const userRole = localStorage.getItem("role");
  const isAdmin = userRole === "ADMIN";
  //const isUser = userRole === "USER";

  const location = useLocation();
  const footerRoutes = ["/", "/Service", "/Track", "/Contact", "/RaiseRequest","/TermsandConditions", "/PrivacyPolicy","/cancellationPolicy"];
  const showFooter = footerRoutes.includes(location.pathname);

  return (
    <Box>
      <ScrollToTop />
      <Routes>
        <Route path="SignIn" element={<SignIn />} />
        <Route path="SignUp" element={<SignUp />} />
        <Route path="/" element={<Appbar />}>
          <Route index element={<HomePage />} />
          <Route path="Service" element={<ServicePage />} />
          <Route path="Contact" element={<ContactPage />} />
          <Route path="Track" element={<TrackPage />} />
          <Route path="Track/:trackingNumber" element={<TrackPage />} />
          <Route path="RaiseRequest" element={<RaiseRequest />} />
          <Route path="pdfFile" element={<PdfFile/>} />
          <Route path="User" element={<User />} />
          <Route path="TermsandConditions" element={<TermsConditions />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="cancellationPolicy" element={<CancellationPolicy />} />
          <Route path="*" element={<PageNotFound />} />
          {/* {isUser && (
            <>
              <Route path="RaiseRequest" element={<RaiseRequest />} />
            </>
          )} */}
          {isAdmin && (
            <>
             <Route path="Admin" element={<AdminLayout />}>
              <Route index element={<AllOrders />} />
              <Route path=":trackingNumber" element={<OrderDetails />} />
              <Route path=":trackingNumber/edit" element={<EditDetails />} />
              <Route path=":trackingNumber/update" element={<UpdateOrderStatus />} />
              <Route path="createNewOrder" element={<CreateNewOrder />} />
              <Route path="createBulkOrder" element={<CreateBulkOrder />} />
              <Route path="customers" element={<Customers />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="expenses" element={<Expenses />} />
              <Route path="wareHouses" element={<WareHouses />} />
              <Route path="PickUpRequest" element={<PickUpRequest />} />
              <Route path="pdfFile" element={<PdfFile/>} />
            </Route>
            </>
           
            
          )}
        </Route>
      </Routes>
      {showFooter && <Footer />}
    </Box>
  );
}


export default App;
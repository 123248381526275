import { Box, Typography } from "@mui/material";
import React from "react";

const Documents = ({ handleFileChange, invoiceDocument, supportDocument }) => {
  return (
    <>
      <Box sx={{ bgcolor: "#fff", mt: "10px", p: 2, display: "flex" }}>
        <Box>
          <Typography variant="h6">Upload Documents</Typography>
          <Box sx={{ mt: "20px", mb: "20px" }}>
            <Typography variant="body1" sx={{ mr: "100px" }}>
              1. Invoice (Required)
            </Typography>
            <input
              type="file"
              name="invoiceDocument"
              required
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.jpg,.png"
            />
          </Box>
        </Box>

        <Box>
          <Box sx={{ mt: 6 }}>
            <Typography variant="body1" sx={{ mr: "80px" }}>
              2. Supporting Documents (Optional)
            </Typography>
            <Box sx={{ display: "inline-block" }}>
              <input
                type="file"
                name="supportDocument"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.jpg,.png"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Documents;
